import {
	RavenNumberFormat,
	RavenTable,
	RavenTableRow,
} from '@ravenpay/raven-bank-ui';
import React, { useEffect, useState } from 'react';
import {
	capitalizeFirstWord,
	formatTypeFunction,
} from '../../../../../utils/helper/Helper';
import DateFragmentBox from '../../../../../components/common/DateFragmentBox';
import { FaRegEye } from 'react-icons/fa';
import { icons } from '../../../../../assets/icons';
import BadgeComponent from '../../../../../components/common/badge/BadgeComponent';
import ComplianceLayout from '../ComplianceLayout';
import Pagination from '../../../../../components/common/pagination';
import { debounce } from 'lodash';
import SmartFilter from '../../../../../components/common/smartFilter';
import ActionModal from '../../../../../components/common/modal/ActionModal';
import InlineDetails from '../../../../../components/common/inlineDetails';
import '../compliance-bvn-verification/styles/index.css';
import { useDispatch, useSelector } from 'react-redux';
import RootState, { BankBoxUser } from '../../../../../redux/types';
import { bankboxAPI } from '../../../../../redux/bankbox';
import ExportModal from '../../../../../components/common/exportModal';
import EmptyScreen from '../../../../../components/common/emptyScreen';
import { useNavigate } from 'react-router-dom';
import { ALL_ROUTES } from '../../../../../routes/dashboard/overview/AllPathNames';

const ComplianceBvnVerification = () => {
	const [selectedTab, setSelectedTab] =
		React.useState<string>('BVN Verification');

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [showExport, setShowExport] = useState<boolean>(false);
	const [currentPage, setCurrentPage] = useState(1);

	const { user_merchant, all_bvn, loading_bvn } = useSelector(
		(state: RootState) => state.bankbox
	);

	const [refreshTable, setRefreshTable] = useState(false);

	useEffect(() => {
		getBVN();
	}, [currentPage || refreshTable, refreshTable]);

	const getBVN = async () => {
		const data = await dispatch(
			bankboxAPI.getAllBVN({
				per_page: 200,
				page: currentPage,
				search: user_merchant?.user?.merchant_email,
			})
		);
	};

	const [showDrop, setShowDrop] = useState<{
		show: boolean;
		id: number | string;
	}>({
		show: false,
		id: '',
	});

	type ChiType = {
		id?: number;
		bvn?: string;
		email?: string;
		full_name?: string;
		phone?: string;
		address?: string;
		dob?: string;
		bvn_verified_at?: string;
		poseidon_email?: string;
		nin?: string;
		affiliate_app?: string;
		affiliate_app_id?: string;
		// Add any other required properties from BankBoxUser
	};


	const [view, setView] = useState<{ on: boolean; chi: ChiType | null }>({
		on: false,
		chi: null,
	});

	type showModalProp = {
		create: boolean;
		export: boolean;
		view_details: boolean;
		edit_details: boolean;
	};

	const [singleChild, setSingleChild] = useState<any>({});
	const [showModal, setShowModal] = useState<showModalProp>({
		create: false,
		export: false,
		view_details: false,
		edit_details: false,
	});

	function isDropped(idx: number | string) {
		return showDrop.id === idx && showDrop.show;
	}

	const rejectIcon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="22"
			fill="none"
			viewBox="0 0 22 22"
		>
			<path
				stroke="#FF0F00"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.2"
				d="m14 8-6 6m0-6 6 6m7-3c0 5.523-4.477 10-10 10S1 16.523 1 11 5.477 1 11 1s10 4.477 10 10"
			></path>
		</svg>
	);

	const verifyicon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="22"
			fill="none"
			viewBox="0 0 22 22"
		>
			<path
				stroke="#1ACE37"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.2"
				d="m6.5 11 3 3 6-6m5.5 3c0 5.523-4.477 10-10 10S1 16.523 1 11 5.477 1 11 1s10 4.477 10 10"
			></path>
		</svg>
	);

	const emptypicture = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="104"
			height="110"
			fill="none"
			viewBox="0 0 104 110"
		>
			<path
				fill="#E3E3E3"
				fillRule="evenodd"
				d="M53.377 12.696a.51.51 0 0 0-.468-.698H20c-9.941 0-18 8.059-18 18v60C2 99.94 10.059 108 20 108h60c9.941 0 18-8.06 18-18V57.565a.51.51 0 0 0-.692-.47 34 34 0 0 1-12.212 2.252c-18.834 0-34.102-15.268-34.102-34.102 0-4.431.845-8.665 2.383-12.55M27 46.998c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10m-.41 45.68 36.351-29.213a2.5 2.5 0 0 1 3.249-.153l19.845 15.436A2.5 2.5 0 0 1 87 80.72v6.277c0 5.523-4.477 10-10 10H28.304c-2.264 0-3.362-2.769-1.713-4.32M85.096 12.023c-3.828 0-6.475 2.957-6.475 6.048a5 5 0 0 1-10 0c0-8.775 7.287-16.048 16.475-16.048s16.474 7.273 16.474 16.048c0 3.108-1.484 5.638-2.924 7.396-1.474 1.8-3.309 3.295-4.934 4.44a42 42 0 0 1-3.8 2.367 5 5 0 0 1-9.816-1.348v-1.808a5 5 0 0 1 2.957-4.564c0-.17.007-.003.007-.003l-.007.003a28 28 0 0 0 1.487-.742c.957-.51 2.2-1.227 3.414-2.081 1.243-.876 2.277-1.772 2.955-2.6.577-.705.653-1.058.661-1.09-.017-3.08-2.66-6.018-6.474-6.018"
				clipRule="evenodd"
			></path>
			<path
				fill="#E3E3E3"
				fillRule="evenodd"
				d="M85.5 38.215a5 5 0 0 1 5 5v.251a5 5 0 1 1-10 0v-.25a5 5 0 0 1 5-5"
				clipRule="evenodd"
			></path>
			<path
				stroke="#E3E3E3"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="3"
				d="M83.053 24.554a5 5 0 0 0-2.957 4.564v1.808a5 5 0 0 0 9.816 1.348 42 42 0 0 0 3.8-2.367c1.625-1.145 3.46-2.64 4.934-4.44 1.44-1.758 2.924-4.288 2.924-7.396 0-8.775-7.286-16.048-16.474-16.048S68.62 9.296 68.62 18.071a5 5 0 0 0 10 0c0-3.091 2.647-6.048 6.475-6.048 3.815 0 6.457 2.938 6.474 6.018-.008.032-.084.385-.661 1.09-.678.828-1.712 1.724-2.955 2.6a33 33 0 0 1-3.413 2.08 33 33 0 0 1-1.488.743m0 0 .007-.003s-.007-.167-.007.003M20 11.998h32.909c.358 0 .6.365.468.698a34 34 0 0 0-2.383 12.549c0 18.834 15.268 34.102 34.102 34.102 4.303 0 8.42-.798 12.212-2.252a.51.51 0 0 1 .692.47V90c0 9.94-8.059 18-18 18H20c-9.941 0-18-8.06-18-18v-60c0-9.942 8.059-18 18-18m17 25c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10 10 4.477 10 10m25.941 26.467-36.35 29.213c-1.65 1.551-.551 4.32 1.713 4.32H77c5.523 0 10-4.477 10-10v-6.277a2.5 2.5 0 0 0-.965-1.974L66.19 63.313a2.5 2.5 0 0 0-3.249.153M90.5 43.215a5 5 0 1 0-10 0v.251a5 5 0 1 0 10 0z"
			></path>
		</svg>
	);

	const DropMore = ({ className, idx, chi }: any) => {
		const status = chi?.status; // This will now be "pending" or "verified"

		return (
			<div className={`more-wrap ${className}`}>
				{status === 'pending' && (
					<>
						<div
							style={{ opacity: 1, cursor: 'pointer' }}
							onClick={() => {
								setSingleChild(chi);
								setShowModal((prev) => ({
									...prev,
									view_fee: true,
								}));
							}}
							className="more_item"
						>
							<figure>{verifyicon}</figure>
							<p style={{ color: 'rgba(26, 206, 55, 1)' }}>Verify BVN</p>
						</div>
						<div
							style={{ opacity: 1, cursor: 'pointer' }}
							onClick={() => {
								setSingleChild(chi);
								setShowModal((prev) => ({
									...prev,
									edit_fee: true,
								}));
							}}
							className="more_item"
						>
							<figure className="img-box">{rejectIcon}</figure>
							<p style={{ color: 'rgba(255, 15, 0, 1)' }}>
								Reject BVN Verification
							</p>
						</div>
					</>
				)}

				{status === 'verified' && (
					<div
						style={{ opacity: 1, cursor: 'pointer' }}
						onClick={() => {
							setSingleChild(chi);
							setShowModal((prev) => ({
								...prev,
								revoke_fee: true,
							}));
						}}
						className="more_item"
					>
						<figure className="img-box">{rejectIcon}</figure>
						<p style={{ color: 'rgba(255, 15, 0, 1)' }}>
							Revoke BVN Verification
						</p>
					</div>
				)}
			</div>
		);
	};

	const getStatusText = (status: number) => {
		switch (status) {
			case 0:
				return 'pending';
			case 1:
				return 'verified';
			default:
				return 'unknown'; // Handle unexpected values
		}
	};

	const handleViewUser = (chi: BankBoxUser) => {
		// console.log(chi);

		localStorage?.setItem('merchant-email-user-preview', chi.poseidon_email);
		navigate(ALL_ROUTES?.SINGLE_MERCHANT);
		// navigate(
		// 	ALL_ROUTES?.BANKBOXUSER_PREVIEW
		// );
	};

	// Dummy data for all_fees
	return (
		<>
			{/* Table content start */}
			<ComplianceLayout>
				{/* dispute content start */}
				<div
					style={{ marginBottom: '1.5rem' }}
					className="global-search-pagination-filter-box"
				>
					{' '}
					<SmartFilter
						// hideExport
						searchTitle="Search By Email Address"
						defaultFilterBy="status"
						filters={[
							{
								label: 'Status',
								filterBy: 'status',
								options: [
									{
										label: 'Pending',
										value: '0',
									},
									{
										label: 'Review',
										value: '3',
									},
									{
										label: 'Discarded',
										value: '2',
									},
									{
										label: 'Resolved',
										value: '4',
									},
								],
							},
						]}
						onExport={() => setShowExport(true)}
						page="BVN"
						onSearchChange={debounce((e) => {
							dispatch(
								bankboxAPI.getAllBVN({
									per_page: 20,
									page: currentPage,
									// search: e,
									search: user_merchant?.user?.merchant_email,
								})
							);
						}, 500)}
					/>
					<div className="pagination-wrap">
						{' '}
						<Pagination
							className="top-bar__pagination"
							currentPage={currentPage}
							itemsPerPage={all_bvn?.per_page ?? 0}
							totalItems={all_bvn?.total ?? 0}
							onPageChange={function (page: number): void {
								setCurrentPage(page);
							}}
						/>
					</div>
				</div>
				{/* dispute content end */}
				<div
					style={{ minHeight: '45rem' }}
					className="transaction__table non-mobile"
				>
					{!all_bvn || !all_bvn?.data || all_bvn?.data?.length < 1 ? (
						<EmptyScreen
							loading={loading_bvn}
							loadingText={loading_bvn ? 'Searching...' : 'Loading BVN...'}
							title="No BVN Data Found"
							subTitle="You don't have any record yet, your BVN will be displayed here."
						/>
					) : (
						<RavenTable
							action={false}
							className="table__main"
							headerList={[
								'FULLNAME',
								'EMAIL ADDRESS',
								'MOBILE NUMBER',
								'BVN',
								'DATE LOGGED',
								'STATUS',
								'',
							]}
						>
							{all_bvn?.data?.map((chi: any, idx: any) => {
								console.log(all_bvn);
								const { name, moblie_number, email, BVN, Status } = chi || {};
								return (
									<RavenTableRow
										loading={loading_bvn}
										onRowClick={() => setView({ on: true, chi })}
										key={idx}
										one={
											capitalizeFirstWord(
												formatTypeFunction(chi?.full_name) as any
											) || '----'
										}
										two={chi?.email || '----'}
										three={chi?.phone ? `+234 ${chi?.phone}` : '----'}
										four={chi?.bvn || '----'}
										five={
											<DateFragmentBox date={chi?.bvn_verified_at || '----'} />
										}
										six={
											<>
												{/* Render badges based on the Status */}
												<BadgeComponent
													className={Status === 1 ? 'success' : 'warning'}
													text={Status === 1 ? 'verified' : 'pending'}
												/>
											</>
										}
										seven={
											<div
												style={{ position: 'relative' }}
												onClick={(e) => {
													e.stopPropagation();
													setShowDrop((prev) => ({
														...prev,
														id: idx,
														show: showDrop?.id === idx ? !showDrop.show : true,
													}));
												}}
											>
												<DropMore
													idx={idx}
													className={isDropped(idx) ? 'show-drop' : ''}
													chi={{ ...chi, status: getStatusText(chi.status) }}
												/>
												{icons.three_dots}
											</div>
										}
									/>
								);
							})}
						</RavenTable>
					)}
				</div>
			</ComplianceLayout>

			<ActionModal
				style={{
					paddingBottom: '0rem',
				}}
				className="padding-b"
				visible={view.on}
				onCancel={() => {
					setView({ chi: null, on: false });
				}}
				big={true}
				onClick={() => {
					if (view.chi) {
						const user: BankBoxUser = {
							poseidon_email: view.chi.email || '',
							nin: '',
							affiliate_app: '',
							affiliate_app_id: '',
							id: Number(view.chi.id) || 0, // Ensure it's a number
							fname: view.chi.full_name?.split(' ')[0] || '', // Extract first name
							lname: view.chi.full_name?.split(' ')[1] || '', // Extract last name
							lga: '',
							bvn: view.chi.bvn || '',
							phone: view.chi.phone || '',
							address: view.chi.address || '',
							dob: view.chi.dob || '',
							bvn_verified_at: view.chi.bvn_verified_at || '',

							// Previously missing properties
							poseidon_business_name: '',
							poseidon_business_address: '',
							poseidon_business_type: '',
							poseidon_business_description: '',
							poseidon_business_reg_number: '',
							poseidon_business_reg_date: '',
							poseidon_business_phone: '',
							poseidon_business_email: '',
							poseidon_business_website: '',

							// NEW missing properties
							poseidon_profile_reference: '',
							business_category: '',
							region: '',
							created_at: new Date().toISOString(), // Default to current timestamp
							updated_at: new Date().toISOString(),
						};

						handleViewUser(user);
					}
				}}
				cancelText="No, Cancel"
				actionText="View Merchant Profile"
				hideCancel
				body="Are you sure you want to cancel the verification process? You can always come back to perform the action.."
				title={`${selectedTab.split(' ')[0]} Details`}
			>
				{view.chi && typeof view.chi === 'object' && (
					<div className="compliance-results">
						<div className="compliance-results__image-preview">
							<div
								style={{
									paddingBottom: '5rem',
								}}
							>
								<figure>{emptypicture}</figure>
							</div>
							<div className="image-preview__pagination">
								<figure className="disabled">
									{icons.arrow_back_circular_inactive}
								</figure>

								<span>
									<p>1 of 2</p>
								</span>
								<figure>{icons.arrow_forward_circular}</figure>
							</div>
						</div>

						<div className="compliance-results__main">
							<InlineDetails
								direction={''}
								title={'Transaction Details'}
								simple
								amount={0}
								onClick={() => {}}
								content={[
									{
										label: selectedTab.split(' ')[0],
										value: String(view.chi?.bvn || '---'),
										copy: true,
									},
									{
										label: 'Fullname',
										value: view.chi?.full_name || '---',
									},

									{
										label: 'Mobile Number',
										value: view.chi?.phone
											? `+234 ${view.chi?.phone}`
											: '----' || '---',
									},

									{
										label: 'Address',
										value: '---',
									},

									{
										label: 'Date of Birth',
										value: '---',
									},

									{
										label: 'Date Authorized',
										value: view.chi?.bvn_verified_at || '----',
									},
								]}
							/>
						</div>
					</div>
				)}
			</ActionModal>

			{/* view detail modal end */}
			<ExportModal
				onClose={() => setShowExport(false)}
				visible={showExport}
				page="BVN"
				onFinishExport={(param) => {
					// console.log(param);
					window.open(param, '_blank')?.focus();
				}}
			/>
		</>
	);
};

export default ComplianceBvnVerification;
