import React, { useContext, useEffect, useState } from 'react';
import DashboardLayout from '../../../layout/dashboard/dashboardLayout';
import './styles/index.css';
import { RavenButton, RavenModal, toast } from '@ravenpay/raven-bank-ui';
import EmptyBankBoxOverview from './components/overview/EmptyOverview';
import BankBoxMainOverview from './components/overview';
import { getCookie, mapHotkey, setCookie } from '../../../utils/helper/Helper';
import SetupBankbox from './components/modals/SetupBankbox';
import RootState, { ActivateBankBoxPayload } from '../../../redux/types';
import { useDispatch, useSelector } from 'react-redux';
import ActionContext from '../../../context/ActionContext';
import { bankboxAPI } from '../../../redux/bankbox';
// import { getSettingsConfig } from '../../../redux/settings';

const BankBoxOverview = () => {
	const { profile } = useSelector((state: RootState) => state.settings);
	const dispatch = useDispatch();
	const formInitialState = {
		merchant_email: profile?.businessInfo?.business_email,
		fname: '',
		lname: '',
		bvn: '',
		nin: '',
		webhook_url: '',
		app_name: '',
		brand_color_code: '#0B8376',
		device_price: '',
		cap_fee_percentage: '',
		cap_amount: '',
		website: '',
		phone: '',
		suppport_email: '',
		webhook_secret: '',
		operating_region: '',
		address: '',
	};
	const [empty, setEmpty] = React.useState<boolean>(true);
	const [activateFormData, setActivateFormData] =
		useState<ActivateBankBoxPayload>(formInitialState);
	const [setupModal, setSetupModal] = React.useState<boolean>(false);

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		const obj = { ...activateFormData, [name]: value };
		setActivateFormData(obj);
	};

	React.useEffect(() => {
		setActivateFormData({
			...activateFormData,
			merchant_email: profile?.businessInfo?.business_email,
		});
	}, [profile]);

	const [step, setStep] = React.useState<{
		id: string;
		completed: string[];
		label: string;
	}>({
		id: 'basic',
		completed: [],
		label: 'Basic Details',
	});

	React.useEffect(() => {
		mapHotkey('m', () => setEmpty(!empty));
	}, [empty]);

	const appid = getCookie('x-pos-key');
	const [check, setCheck] = useState(false);
	React.useEffect(() => {
		if (!getCookie('x-pos-key')) {
			if (appid) {
				setCookie('x-pos-key', appid, 3000);
			}
		}
	}, [appid]);

	// console.log(appid, "-----------------------dfrfre----------");
	// const isAppkeyDenounced = getCookie('denounce-bankbox') === 'true';

	// var checkCookie = (setState) => {
	// 	const checkPoint = () => {
	// 	var lastCookie = document.cookie;
	// 	return () => {
	// 		var currentCookie = document.cookie;
	// 		if (currentCookie !== lastCookie) {
	// 			setState(Date.now())
	// 			lastCookie = currentCookie;
	// 		}
	// 	};
	// 	}
	// 	setInterval(checkPoint, 100);
	// };

	// checkCookie(())

	// const [cookieValue, setCookieValue] = useState(getCookie('x-pos-key'))
	// const { profile } = useSelector((state: RootState) => state.wallet);
	const actionCtx = useContext(ActionContext);
	// useEffect(() => {

	// 	if (profile?.merchantInfo?.bankbox_key) {
	// 		setCheck(true);
	// 		// console.log("----nbdg-----");

	// 		// actionCtx.setIsProfileCheck(true);
	// 	} else {
	// 		setCheck(false);
	// 		// actionCtx.setIsProfileCheck(false);
	// 	}
	// }, [profile]);

	// console.log(isAppkeyDenounced);
	// useEffect(() => {
	// 	dispatch(getSettingsConfig());
	// 	// getSettingsConfig
	// }, []);
	return (
		<>
			{!actionCtx?.isProfileCheck && !profile?.merchantInfo?.bankbox_key ? (
				<DashboardLayout>
					<EmptyBankBoxOverview  onClick={() => setSetupModal(true)} />
				</DashboardLayout>
			) : (
				<BankBoxMainOverview />
			)}
			{/* {appid && } */}

			<RavenModal
				visble={setupModal && !profile?.merchantInfo?.bankbox_key}
				// visble={true}
				onClose={() => {
					setSetupModal(false);
					setStep({ id: 'basic', completed: [], label: 'Basic Details' });
					setActivateFormData(formInitialState);
				}}
				className={'bankbox-create-modal bankbox-create-setup-modal'}
				onBtnClick={function (e?: any): void {
					throw new Error('Function not implemented.');
				}}
			>
				<SetupBankbox
					onComplete={() => {
						setSetupModal(false);
						setStep({ id: 'basic', completed: [], label: 'Basic Details' });
					}}
					onChange={handleChange}
					setBrandColor={function (e: any) {
						setActivateFormData({
							...activateFormData,
							brand_color_code: e,
						});
					}}
					setStep={function (e: {
						id: string;
						completed: string[];
						label: string;
					}): void {
						setStep(e);
					}}
					step={step}
					formData={activateFormData}
				/>
			</RavenModal>
		</>
	);
};

export default BankBoxOverview;
