import BankBoxOverview from '../../../pages/dashboard/bankbox';
import BankBoxUsers from '../../../pages/dashboard/bankbox/users';
import BankboxRequest from '../../../pages/dashboard/bankbox/components/request';
import BankboxTerminals from '../../../pages/dashboard/bankbox/terminals';
import BankBoxTransactions from '../../../pages/dashboard/bankbox/transactions';
import BankboxSettings from '../../../pages/dashboard/bankbox/settings';
import SingleViewBankbox from '../../../pages/dashboard/bankbox/terminals/component/SingleViewBankbox';
import Onboarding from '../../../pages/dashboard/onboarding';
import DisputeIndexPage from '../../../pages/dashboard/bankbox/disputes';
import FeeDetailsIndexPage from '../../../pages/dashboard/bankbox/fee-details';
import BankBoxUserPreview from '../../../pages/dashboard/bankbox/users/component/UserPreview';
import SingleBankBoxTransactions from '../../../pages/dashboard/bankbox/transactions/SingleTerminalTransactions';
import TeamSettingsIndex from '../../../pages/dashboard/bankbox/settings/teams/TeamSettingsIndex';
import RolesPermissionIndex from '../../../pages/dashboard/bankbox/settings/role-and-permission/RolesPermissionIndex';
import SingleRoleIndex from '../../../pages/dashboard/bankbox/settings/role-and-permission/SingleRoleIndex';
import Settlement from '../../../pages/dashboard/bankbox/settlements';
import Bills from '../../../pages/dashboard/bankbox/bills';
import AirtimeBill from '../../../pages/dashboard/bankbox/bills/airtime';
import DataBill from '../../../pages/dashboard/bankbox/bills/dataBill';
import ElectricityBill from '../../../pages/dashboard/bankbox/bills/electricityBill';
import CableTvBill from '../../../pages/dashboard/bankbox/bills/cabletvBill';
import BettingBill from '../../../pages/dashboard/bankbox/bills/bettingBill';
// import Compliance from '../../../pages/dashboard/bankbox/compliance';
import ComplianceBvnVerification from '../../../pages/dashboard/bankbox/compliance/compliance-bvn-verification';
import ComplianceNinVerification from '../../../pages/dashboard/bankbox/compliance/compliance-nin-verification';
import ComplianceAddressVerification from '../../../pages/dashboard/bankbox/compliance/compliance-address-verification';
import ComplianceCacVerification from '../../../pages/dashboard/bankbox/compliance/compliance-cac-verification';
// import complianceLivelinessCheck from '../../../pages/dashboard/bankbox/compliance/compliance-liveliness-check';
import ComplianceLivelinessCheck from '../../../pages/dashboard/bankbox/compliance/compliance-liveliness-check';
// import Settlement from '../../../pages/dashboard/bankbox/settlements';
import SingleUserLayout from '../../../pages/dashboard/bankbox/users/newsingleuser/SingleUserLayout';
// import Transaction from '../../../pages/dashboard/bankbox/users/newsingleuser/components/Transaction';

import UserTerminals from '../../../pages/dashboard/bankbox/users/newsingleuser/components/terminals/TerminalsIndex';
import paymentLink from '../../../pages/dashboard/bankbox/users/newsingleuser/components/paymentLinks/paymentLink';
import Compliance from '../../../pages/dashboard/bankbox/users/newsingleuser/components/compliance/complianceIndex';
import LinkTable from '../../../pages/dashboard/bankbox/users/newsingleuser/components/paymentLinks/linkTable';
import PaylinkLayoutTable from '../../../pages/dashboard/bankbox/users/newsingleuser/components/paymentLinks/linkDetails';

import DeviceRequest from '../../../pages/dashboard/bankbox/users/newsingleuser/components/DeviceRequest';
import Settlements from '../../../pages/dashboard/bankbox/users/newsingleuser/components/Settlement';
import Dispute from '../../../pages/dashboard/bankbox/users/newsingleuser/components/Dispute';

import ComplianceCardList from '../../../pages/dashboard/bankbox/users/newsingleuser/components/ComplianceCardList';
import { ALL_ROUTES } from './AllPathNames';
import NewSingleMerchant from '../../../pages/dashboard/bankbox/users/newsingleuser/components/NewSingleMerchant';
import UserAirtime from '../../../pages/dashboard/bankbox/users/newsingleuser/components/bills/singleUserBillsAirtime';
import UserData from '../../../pages/dashboard/bankbox/users/newsingleuser/components/bills/singleUserBillsData';
import UserCableTv from '../../../pages/dashboard/bankbox/users/newsingleuser/components/bills/singleUserBillsCable';
import Transaction from '../../../pages/dashboard/bankbox/users/newsingleuser/components/transaction/Transaction';
import SingleElectricity from '../../../pages/dashboard/bankbox/users/newsingleuser/components/bills/SingleElectricity';
import SingleBetting from '../../../pages/dashboard/bankbox/users/newsingleuser/components/bills/SingleBetting';

export const overview_routes_group = [
	{
		path: ALL_ROUTES?.OVERVIEW,
		element: BankBoxOverview,
	},
	{
		path: ALL_ROUTES?.SINGLE_MERCHANT,
		element: NewSingleMerchant,
	},
	{
		path: ALL_ROUTES?.BANKBOX_USER,
		element: BankBoxUsers,
	},
	{
		path: ALL_ROUTES?.SINGLE_MERCHANT_TRANSACTION,
		element: Transaction,
	},
	{
		path: ALL_ROUTES?.SINGLE_USER_TERMINAL,
		element: UserTerminals,
	},
	{
		path: ALL_ROUTES?.SINGLE_MERCHANT_BILLS_AIRTIME,
		element: UserAirtime,
	},
	{
		path: ALL_ROUTES?.SINGLE_MERCHANT_BILLS_DATA,
		element: UserData,
	},
	{
		path: ALL_ROUTES?.SINGLE_MERCHANT_BILLS_CABLE,
		element: UserCableTv,
	},
	{
		path: ALL_ROUTES?.SINGLE_MERCHANT_PAYMENT_LINK,
		element: paymentLink,
	},

	{
		path: ALL_ROUTES?.SINGLE_USER_LINK_TABLE,
		element: LinkTable,
	},

	{
		path: ALL_ROUTES.SINGLE_MERCHANT_BILLS_LINK_DETAILS,
		element: PaylinkLayoutTable,
	},
	{
		path: ALL_ROUTES?.SINGLE_MERCHANT_BANKBOX_TRANSACTIONS,
		element: Transaction,
	},
	{
		path: ALL_ROUTES?.SINGLE_MERCHANT_DEVICE_REQUEST,
		element: DeviceRequest,
	},
	{
		path: ALL_ROUTES?.SINGLE_MERCHANT_SETTLEMENTS,
		element: Settlements,
	},
	{
		path: ALL_ROUTES?.SINGLE_MERCHANT_DISPUTE,
		element: Dispute,
	},
	{
		path: ALL_ROUTES?.SINGLE_MERCHANT_ElECTRICITY,
		element: SingleElectricity,
	},
	{
		path: ALL_ROUTES?.SINGLE_MERCHANT_BETTING,
		element: SingleBetting,
	},
	{
		path: ALL_ROUTES?.COMPLIANCE_CARDLIST,
		element: ComplianceCardList,
	},
	{
		path: ALL_ROUTES?.BANKBOXUSER_PREVIEW,
		element: BankBoxUserPreview,
	},
	{
		path: ALL_ROUTES?.ONBOARDING,
		element: Onboarding,
	},
	{
		path: ALL_ROUTES?.BANKBOX_REQUEST,
		element: BankboxRequest,
	},
	{
		path: ALL_ROUTES?.BANKBOX_TERMINALS,
		element: BankboxTerminals,
	},
	{
		path: ALL_ROUTES?.SINGLE_MERCHANT_VIEW_BANKBOX,
		element: SingleViewBankbox,
	},
	{
		path: ALL_ROUTES?.BANKBOX_TRANSACTIONS,
		element: BankBoxTransactions,
	},
	{
		path: ALL_ROUTES?.SINGLE_MERCHANT_BANKBOX_TRANSACTIONS,
		element: SingleBankBoxTransactions,
	},
	{
		path: ALL_ROUTES?.COMPLIANCE_BVN_VERIFICATION,
		element: ComplianceBvnVerification,
	},
	{
		path: ALL_ROUTES?.COMPLIANCE_NIN_VERIFICATION,
		element: ComplianceNinVerification,
	},
	{
		path: ALL_ROUTES?.COMPLIANCE_ADDRESS_VERIFICATION,
		element: ComplianceAddressVerification,
	},
	{
		path: ALL_ROUTES?.COMPLIANCE_CAC_VERIFICATION,
		element: ComplianceCacVerification,
	},

	// {
	// 	path: ALL_ROUTES?.COMPLIANCE_LIVELINESS_CHECK,
	// 	element: ComplianceLivelinessCheck,
	// },
	{
		path: ALL_ROUTES?.BANBOX_SETTINGS,
		element: BankboxSettings,
	},
	{
		path: ALL_ROUTES?.TEAM_SETTINGS_INDEX,
		element: TeamSettingsIndex,
	},
	{
		path: '/dashboard-settings-roles-and-permission',
		element: RolesPermissionIndex,
	},
	{
		path: '/dashboard-settings-roles-single',
		element: SingleRoleIndex,
	},
	{
		path: '/dashboard-disputes',
		element: DisputeIndexPage,
	},
	{
		path: '/dashboard-fee-details',
		element: FeeDetailsIndexPage,
	},
	{
		path: '/dashboard-settlement',
		element: Settlement,
	},

	{
		path: '/dashboard-bills-airtime',
		element: AirtimeBill,
	},
	{
		path: '/dashboard-bills-data',
		element: DataBill,
	},
	{
		path: '/dashboard-bills-electricity',
		element: ElectricityBill,
	},
	{
		path: '/dashboard-bills-cable-tv',
		element: CableTvBill,
	},
	{
		path: '/dashboard-bills-betting',
		element: BettingBill,
	},
];
