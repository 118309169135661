import React, { useEffect, useState } from 'react';
import SingleUserLayout from '../SingleUserLayout';
import Pagination from '../../../../../../components/common/pagination';
import { debounce } from 'lodash';
import SmartFilter from '../../../../../../components/common/smartFilter';
import BadgeComponent from '../../../../../../components/common/badge/BadgeComponent';
import DateFragmentBox from '../../../../../../components/common/DateFragmentBox';
import {
	capitalizeFirstWord,
	formatTypeFunction,
} from '../../../../../../utils/helper/Helper';
import { RavenNumberFormat, RavenTable, RavenTableRow } from '@ravenpay/raven-bank-ui';
import TwoDetailView from '../../../../../../components/common/TwoDetailView';
import { icons } from '../../../../../../assets/icons';
import { FaRegEye } from 'react-icons/fa';
import SettoApproved from './modal/SettoApproved';
import Settodispatch from './modal/Settodispatch';
import DeviceRequestModal from './modal/ViewDetails';
import EmptyScreen from '../../../../../../components/common/emptyScreen';
import useDebounce from '../../../../../../hooks/UseDebounce';
import { omniSearch } from '../../../../../../redux/transaction';
import { getTransfers } from '../../../../../../redux/wallet';
import RootState from '../../../../../../redux/types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { bankboxAPI } from '../../../../../../redux/bankbox';
import { Console } from 'console';

const DeviceRequest = () => {
	const [showDrop, setShowDrop] = useState<{
		show: boolean;
		id: number | string;
	}>({
		show: false,
		id: '',
	});

	type showModalProp = {
		create: boolean;
		export: boolean;
		Set_to_In_Approved: boolean;
		Set_to_Dispatched: boolean;
		View_Details: boolean;
	};
	const [singleChild, setSingleChild] = useState<any>({});
	const [showModal, setShowModal] = useState<showModalProp>({
		create: false,
		export: false,
		Set_to_In_Approved: false,
		Set_to_Dispatched: false,
		View_Details: false,
	});

	const [modalData, setModalData] = useState<any | null>(null);

	const { searchLoading } = useSelector(
		(state: RootState) => state.transaction
	);

	const { transfers } = useSelector((state: RootState) => state.wallet);

	const { loading_request, user_merchant, get_single_all_bankbox_request } =
		useSelector((state: RootState) => state.bankbox);

	function formatStatus(status: string | number) {
		let s;
		// 		0 = pending, 1 = approved, 2 = dispatched, 3 = delivered
		// [3:17 PM] 4 = declined
		if (status === 0 || status === 'pending') {
			s = 'pending';
		}
		if (status === 1) {
			s = 'approved';
		}
		if (status === 2) {
			s = 'dispatched';
		}
		if (status === 3) {
			s = 'delivered';
		}
		if (status === 4) {
			s = 'declined';
		}
		return (
			<div className={`transaction-status ${s && s?.toLowerCase()}`}>
				<p style={{ textTransform: 'capitalize' }}>{s}</p>
			</div>
		);
	}
	const [dateFilter, setDateFilter] = useState<{
		to: Date | any;
		from: Date | any;
	}>({
		from: '',
		to: '',
	});
	const [activeFilter, setActiveFilter] = useState<string>('');

	useEffect(() => {
		if (typeof activeFilter !== undefined) {
			setActiveFilter('');
		}
	}, [dateFilter.to || dateFilter.from]);

	const [refreshTable, setRefreshTable] = useState(false);
	const [showExport, setShowExport] = useState<boolean>(false);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [currentPage, setCurrentPage] = useState(1);
	const [searchQuery, setSearchQuery] = useState('');

	const [noDataFound, setNoDataFound] = useState<boolean>(true);
	React.useEffect(() => {
		getUserRequest();
	}, [currentPage, refreshTable, user_merchant]);

	const getUserRequest = async () => {
		const data = await dispatch(
			bankboxAPI.getAllSingleBankBoxRequest({
				page: currentPage,
				per_page: 20,
				search: user_merchant?.user?.merchant_email,
			})
		);
		if (data?.payload?.status === 'success') {
			// console.log(data);

			const checkLength = data?.payload?.data?.data?.data;
			if (checkLength?.length > 0) {
				setNoDataFound(false);
				// console.log('ooo');
			} else {
				setNoDataFound(true);
			}
		}
	};

	const handleRowClick = (rowData: any) => {
		setModalData(rowData);
		setShowModal((prev) => ({
			...prev,
			showdetails: true, // Ensure this is set to true
		}));
	};

	const handleFinish = () => {
		setRefreshTable(!refreshTable);
		setShowModal((prev) => {
			return { ...prev, export: false, status: false };
		});
	};
	const debounceSearchTerm = useDebounce(searchQuery, 1000);
	useEffect(() => {
		let isMount = true;

		if (isMount && debounceSearchTerm?.length > 2) {
			dispatch(omniSearch(searchQuery));
		}

		// if (isMount && debounceSearchTerm?.length < 1) {
		// 	dispatch(
		// 		getTransfers({
		// 			page: currentPage,
		// 			limit: 20,
		// 		})
		// 	);
		// }

		return () => {
			isMount = false;
		};
	}, [debounceSearchTerm]);

	// const req = all_single_bankbox_request?.data;

	function isDropped(idx: number | string) {
		return showDrop.id === idx && showDrop.show;
	}

	const Dispatchedicon = (
		<svg
			className="img"
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="18"
			fill="none"
			viewBox="0 0 22 18"
		>
			<path
				stroke="#020202"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.2"
				d="M13 4h2.337c.245 0 .367 0 .482.028a1 1 0 0 1 .29.12c.1.061.187.148.36.32l4.062 4.063c.173.173.26.26.322.36q.082.136.12.29c.027.115.027.237.027.482V12.5c0 .466 0 .699-.076.883a1 1 0 0 1-.541.54c-.184.077-.417.077-.883.077m-5 0H13m0 0V4.2c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C11.48 1 10.92 1 9.8 1H4.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C1 2.52 1 3.08 1 4.2V12a2 2 0 0 0 2 2m10 0H9m0 0a3 3 0 1 1-6 0m6 0a3 3 0 1 0-6 0m16.5.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0"
			></path>
		</svg>
	);

	const approvedicon = (
		<svg
			className="img"
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="22"
			fill="none"
			viewBox="0 0 22 22"
		>
			<path
				stroke="#1ACE37"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.2"
				d="m6.5 11 3 3 6-6m5.5 3c0 5.523-4.477 10-10 10S1 16.523 1 11 5.477 1 11 1s10 4.477 10 10"
			></path>
		</svg>
	);

	const ViewDetailsicon = (
		<svg
			className="img"
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="22"
			fill="none"
			viewBox="0 0 22 22"
		>
			<path
				stroke="#333"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.2"
				d="m11 15 4-4m0 0-4-4m4 4H7m14 0c0 5.523-4.477 10-10 10S1 16.523 1 11 5.477 1 11 1s10 4.477 10 10"
			></path>
		</svg>
	);

	// Function to mask mobile numbers
	const maskMobileNumber = (number: string | null): string =>
		number ? `${number.slice(0, 7)}***${number.slice(-2)}` : '----';

	const DropMore = ({ className, idx, chi }: any) => {
		return (
			<div className={`more-wrap ${className}`}>
				<div
					style={{
						opacity: '1',
						cursor: 'pointer',
					}}
					onClick={() => {
						setSingleChild(chi);
						setShowModal((prev) => ({
							...prev,
							Set_to_In_Approved: true,
						}));
					}}
					className="more_item"
				>
					<figure className="img-box">{approvedicon}</figure>
					<p>Set to In Approved</p>
				</div>
				<div
					style={{
						opacity: '1',
						cursor: 'pointer',
					}}
					onClick={() => {
						setSingleChild(chi);
						setShowModal((prev) => ({
							...prev,
							Set_to_Dispatched: true,
						}));
					}}
					className="more_item"
				>
					<figure className="img-box">{Dispatchedicon}</figure>
					<p>Set to Dispatched</p>
				</div>
				<div
					style={{
						opacity: '1',
						cursor: 'pointer',
					}}
					onClick={() => {
						setSingleChild(chi);
						setShowModal((prev) => ({
							...prev,
							View_Details: true,
						}));
					}}
					className="more_item"
				>
					<figure className="img-box">{ViewDetailsicon}</figure>
					<p>View Details</p>
				</div>
			</div>
		);
	};

	return (
		<>
			{/* dispute content start */}
			<div
				onClick={() => {
					setShowDrop({
						show: false,
						id: '',
					});
				}}
				style={{ marginBottom: '1.5rem' }}
				className="global-search-pagination-filter-box"
			>
				{' '}
				<SmartFilter
					// hideExport
					searchTitle="Search By Email Address"
					defaultFilterBy="status"
					onSearchChange={debounce((e) => {
						// console.log(e);
						dispatch(
							bankboxAPI.getAllSingleBankBoxRequest({
								per_page: 20,
								page: currentPage,
								search: user_merchant?.user?.merchant_email,
							})
						);
					}, 500)}
					filters={[
						{
							label: 'Status',
							filterBy: 'status',
							options: [
								{
									label: 'Declined',
									value: '4',
								},
								{
									label: 'Approved',
									value: '1',
								},
								{
									label: 'Dispatched',
									value: '2',
								},
								{
									label: 'Delivered',
									value: '3',
								},
								{
									label: 'Pending',
									value: '0',
								},
								{
									label: 'All',
									value: ' ',
								},
							],
						},
					]}
					hideExport
					onExport={() => setShowExport(true)}
					page="bankbox_requests"
				/>
				<div className="pagination-wrap">
					{' '}
					<Pagination
						className="top-bar__pagination"
						currentPage={currentPage}
						itemsPerPage={get_single_all_bankbox_request?.perPage}
						totalItems={get_single_all_bankbox_request?.total}
						onPageChange={function (page: number): void {
							setCurrentPage(page);
						}}
					/>
				</div>
			</div>
			{/* dispute content end */}
			<div
				style={{ minHeight: '45rem' }}
				className="transaction__table non-mobile"
			>
				{get_single_all_bankbox_request?.data.length < 1 ? (
					<EmptyScreen
						loading={loading_request}
						loadingText={
							loading_request ? 'Searching...' : 'Loading Requests...'
						}
						title="No Requests Found"
						subTitle="You don't have any bankbox requests yet, place a new request and have them displayed here."
					/>
				) : (
					<RavenTable
						action={false}
						className="table__main"
						headerList={['QUANTITY', 'AMOUNT', 'DATE ', 'STATUS', 'ACTION']}
					>
						{get_single_all_bankbox_request?.data.map((chi: any, idx: any) => {
							console.log(get_single_all_bankbox_request);
							const {
								name,
								moblie_number,
								businessname,
								amount,
								BVN,
								stan,
								Status,
								rate,
							} = chi || {};

							return (
								<RavenTableRow
									loading={loading_request}
									key={idx}
									one={chi?.quantity}
									two={
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												gap: '5px',
											}}
										>
											{/* Display Amount */}
											<div>
												{chi?.amount !== undefined
													? RavenNumberFormat(chi.amount)
													: 'Amount not available'}
											</div>

											{/* Display Rate or fallback */}
											<div
												style={{
													fontWeight: '400',
													color: 'rgba(103, 103, 103, 1)',
												}}
											>
												{chi?.rate ? `at ${chi?.rate}` : 'Rate not available'}
											</div>
										</div>
									}
									three={<DateFragmentBox date={chi?.created_at} />}
									four={
										<>
											{/* Render badges based on the Status */}
											<BadgeComponent
												className={Status === 1 ? 'success' : 'warning'}
												text={Status === 1 ? 'verified' : 'pending'}
											/>
										</>
									}
									five={
										<div
											style={{ position: 'relative' }}
											onClick={(e) => {
												e.stopPropagation();
												setShowDrop((prev) => ({
													...prev,
													id: idx,
													show: showDrop?.id === idx ? !showDrop.show : true,
												}));
											}}
										>
											<DropMore
												idx={idx}
												className={isDropped(idx) ? 'show-drop' : ''}
												chi={chi}
											/>
											{icons.three_dots}
										</div>
									}
								/>
							);
						})}
					</RavenTable>
				)}
				{/* )} */}
			</div>

			{/* RavenModal for View Liveliness */}
			<SettoApproved
				onClose={() => {
					setShowModal((prev) => ({ ...prev, Set_to_In_Approved: false }));
				}}
				visible={showModal?.Set_to_In_Approved}
				singleChild={singleChild}
			/>
			<Settodispatch
				onClose={() => {
					setShowModal((prev) => ({ ...prev, Set_to_Dispatched: false }));
				}}
				visible={showModal?.Set_to_Dispatched}
				singleChild={singleChild}
			/>
			<DeviceRequestModal
				onClose={() => {
					setShowModal((prev) => ({ ...prev, View_Details: false })); // Close modal
				}}
				visible={showModal?.View_Details} // Bind to visibility state
				singleChild={singleChild}
			/>
			{/* Table content end */}
		</>
	);
};

export default DeviceRequest;
