import React from 'react';
import SingleUserLayout from '../../SingleUserLayout';
import {
	RavenNumberFormat,
	RavenTable,
	RavenTableRow,
} from '@ravenpay/raven-bank-ui';
import TwoDetailView from '../../../../../../../components/common/TwoDetailView';
import ImageFragment from '../../../../../../../components/common/ImageFragment';
import {
	convertToLowercase,
	trimLongString,
} from '../../../../../../../utils/helper/Helper';
import BadgeComponent from '../../../../../../../components/common/badge/BadgeComponent';
import DateFragmentBox from '../../../../../../../components/common/DateFragmentBox';
import { cableData } from '../../../../bills/cabletvBill/cableData';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../../../../../../redux/types';
import { getSingleCableRecords } from '../../../../../../../redux/bills';
import { debounce } from 'lodash';
import Pagination from '../../../../../../../components/common/pagination';
import SmartFilter from '../../../../../../../components/common/smartFilter';
import EmptyScreen from '../../../../../../../components/common/emptyScreen';
import { bankboxAPI } from '../../../../../../../redux/bankbox';

const UserCableTv = () => {
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = useState(1);

	type showModalProp = {
		bills_modal: boolean;
	};
	const { cableRecords, loadingFetch } = useSelector(
		(state: RootState) => state.bills
	);
	const [showModal, setShowModal] = useState<showModalProp>({
		bills_modal: false,
	});

	const cableIcon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
			className="img"
		>
			<path
				stroke="black"
				style={{ stroke: 'black', strokeOpacity: 1 }}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M12 17.5h.01M8.2 22h7.6c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874C19 20.48 19 19.92 19 18.8V5.2c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C17.48 2 16.92 2 15.8 2H8.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C5 3.52 5 4.08 5 5.2v13.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C6.52 22 7.08 22 8.2 22m4.3-4.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0"
			></path>
		</svg>
	);

	useEffect(() => {
		fetchAllCable();
	}, [currentPage]);

	const fetchAllCable = async () => {
		const obj = {
			currentPage: currentPage,
			per_page: 20,
		};
		dispatch(getSingleCableRecords(obj) as any);
	};

	return (
		<>
			{/* dispute content start */}
			<div
				style={{ marginBottom: '1.5rem' }}
				className="global-search-pagination-filter-box"
			>
				{' '}
				<SmartFilter
					// hideExport
					searchTitle="Search By Email Address"
					defaultFilterBy="status"
					// filters={[
					//  {
					//    label: 'Status',
					//    filterBy: 'status',
					//    options: [
					//      {
					//        label: 'Pending',
					//        value: 'pending',
					//      },
					//      {
					//        label: 'Failed',
					//        value: 'failed',
					//      },
					//      {
					//        label: 'Successful',
					//        value: 'succesful',
					//      },
					//    ],
					//  },
					// ]}
					onExport={() => {
						//  setShowModal((prev) => {
						//    return { ...prev, export: true };
						//  });
					}}
					page="bankbox_transactions"
					onSearchChange={debounce((e) => {
						dispatch(
							bankboxAPI.getAllBankBoxRequest({
								per_page: 20,
								page: currentPage,
								search: e,
							})
						);
					}, 500)}
				/>
				<div className="pagination-wrap">
					{' '}
					<Pagination
						className="top-bar__pagination"
						currentPage={currentPage}
						itemsPerPage={cableRecords?.pagination?.perPage}
						totalItems={cableRecords?.pagination?.total}
						onPageChange={function (page: number): void {
							setCurrentPage(page);
						}}
					/>
				</div>
			</div>
			{/* dispute content end */}
			<div
				style={{ minHeight: '45rem' }}
				className="transaction__table non-mobile"
			>
				{cableRecords?.data?.length < 1 ? (
					<EmptyScreen
						loading={loadingFetch}
						loadingText={loadingFetch ? 'Searching...' : 'Loading Dispute...'}
						title="No Dispute Found"
						subTitle="You don't have any record yet,your new bankbox transactions will be displayed here."
					/>
				) : (
					<div
						style={{ minHeight: '45rem' }}
						className="transaction__table non-mobile"
					>
						<RavenTable
							action={false}
							className="table__main"
							headerList={[
								'TRANSACTION ID',
								'CABLE TV',
								'CABLE PLAN',
								'AMOUNT',
								'CARD NUMBER',
								'DATE',
								'STATUS',
							]}
						>
							{cableRecords?.data?.map((chi: any, idx: any) => (
								<RavenTableRow
									loading={loadingFetch}
									// 	onRowClick={() => {
									// 		setShowModal({ bills_modal: true });
									// 	}}
									one={trimLongString(chi?.tid, 15) || '---'}
									two={
										<div className="provider_image_and_name">
											<ImageFragment
												className={`img-val-box  img-box border-theme`}
												url={`https://personalbanking.getraventest.com/static/media/mobile/${convertToLowercase(
													chi.provider
												)}.png`}
											>
												{' '}
												<figure className="img-box border-theme img-box-default">
													{' '}
													{cableIcon}
												</figure>
											</ImageFragment>

											<p className="name">{chi.provider}</p>
										</div>
									}
									five={trimLongString(chi.smart_card_number, 20) || '---'}
									three={chi?.cablePlan || '---'}
									four={RavenNumberFormat(chi?.amount || 0)}
									seven={
										<div className="status_and_modal_holder">
											<BadgeComponent
												text={(() => {
													if (String(chi?.status === '0')) {
														return 'Disabled';
													} else {
														return 'Success';
													}
												})()}
												className={(() => {
													if (String(chi?.status === '1')) {
														return 'failed';
													} else {
														return 'success';
													}
												})()}
											/>
										</div>
									}
									six={<DateFragmentBox date={chi?.created_at} />}
								/>
							))}
						</RavenTable>
					</div>
				)}
			</div>
			{/* table content start */}
		</>
	);
};

export default UserCableTv;
