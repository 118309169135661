import React, { useEffect, useState } from 'react';
import SingleUserLayout from '../SingleUserLayout';
import Pagination from '../../../../../../components/common/pagination';
import { debounce } from 'lodash';
import SmartFilter from '../../../../../../components/common/smartFilter';
import BadgeComponent from '../../../../../../components/common/badge/BadgeComponent';
import DateFragmentBox from '../../../../../../components/common/DateFragmentBox';
import {
	capitalizeFirstLetter,
	capitalizeFirstWord,
	convertToLowercase,
	downloadCsvFile,
	formatDateHelper,
	formatMetaData,
	formatTypeFunction,
	trimLongString,
} from '../../../../../../utils/helper/Helper';
import {
	RavenNumberFormat,
	RavenTable,
	RavenTableRow,
} from '@ravenpay/raven-bank-ui';
import TwoDetailView from '../../../../../../components/common/TwoDetailView';
import SettlementModal from './modal/settlementModal';
import { useDispatch, useSelector } from 'react-redux';
import { bankboxAPI } from '../../../../../../redux/bankbox';
import RootState from '../../../../../../redux/types';
import ImageFragment from '../../../../../../components/common/ImageFragment';
import PageLayout from '../../../../../../layout/dashboard/tableLayout';
import EmptyScreen from '../../../../../../components/common/emptyScreen';
import ExportModal from '../../../../../../components/common/exportModal';
import CheckUrlFragment from '../../../../../../components/common/CheckUrlCodeFragment';
import '../styles/Index.css';

const Settlements = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalData, setModalData] = useState<any | null>(null);

	type showModalProp = {
		create: boolean;
		export: boolean;
		showdetails: boolean;
	};

	const { all_merchant_bankbox_settlement, loading_merchant, user_merchant } =
		useSelector((state: RootState) => state.bankbox);
	const [singleChild, setSingleChild] = useState<any>({});
	const [currentPage, setCurrentPage] = useState(1);
	const [showModal, setShowModal] = useState<showModalProp>({
		create: false,
		export: false,
		showdetails: false,
	});
	const dispatch = useDispatch();
	const [showExport, setShowExport] = useState<boolean>(false);

	useEffect(() => {
		if (
			Object?.keys(user_merchant)?.length > 0 &&
			user_merchant?.user?.poseidon_email
		) {
			getMerchantAllSettlement();
		}
	}, [currentPage, user_merchant]);

	const getMerchantAllSettlement = async () => {
		dispatch(
			bankboxAPI.getMerchantAllSettlement({
				page: currentPage,
				per_page: 20,
				search: user_merchant?.user?.poseidon_email,
			})
		);
	};

	const csvHeader = [
		{ label: 'S/N', key: 'sn' },
		{ label: 'Business Name', key: 'merchant_name' },
		{ label: 'Business Email', key: 'merchant_email' },
		{ label: 'Type', key: 'type' },
		{ label: 'Amount', key: 'amount' },
		{ label: 'Account Name', key: 'account_name' },
		{ label: 'Account Number', key: 'account_number' },
		{ label: 'Bank', key: 'bank' },
		{ label: 'Transaction Reference', key: 'tranx_reference' },
		{ label: 'Transaction Details', key: 'transaction_details' },
		{ label: 'Date', key: 'created_at' },
		{ label: 'Status', key: 'status' },
	];
	const formatExportData = (param: any) => {
		if (param?.length > 0) {
			const newData = param?.map((chi: any, idx: any) => {
				return {
					sn: idx + 1 < 10 ? `0${idx + 1}` : idx + 1,
					merchant_name: chi?.business_profile?.business_name
						? capitalizeFirstLetter(chi?.business_profile?.business_name)
						: 'NIL',
					merchant_email: chi?.business_profile?.business_email || 'NIL',
					type: chi?.type ? formatTypeFunction(chi?.type) : 'NIL',
					amount: RavenNumberFormat(chi?.amount) || 'NIL',
					account_name: chi?.account_details?.account_name || 'NIL',
					account_number: chi?.account_details?.account_number || 'NIL',
					bank: chi?.account_details?.bank || 'NIL',
					tranx_reference: chi?.trx_ref || 'NIL',
					created_at: chi?.created_at
						? formatDateHelper(chi?.created_at)?.replace('—', '•')
						: 'NIL',
					status:
						String(chi?.status) === '0'
							? `Pending`
							: String(chi?.status) === '1'
							? `Pending`
							: String(chi?.status) === '2'
							? `Failed`
							: String(chi?.status) === '3'
							? `Successful`
							: `Failed`,
				};
			});

			downloadCsvFile(csvHeader, newData, 'settlment_data.csv');
		}
	};

	const maskMobileNumber = (number: string | null): string =>
		number ? `${number.slice(0, 7)}***${number.slice(-2)}` : '----';

	const handleRowClick = (rowData: any) => {
		setModalData(rowData);
		setShowModal((prev) => ({
			...prev,
			showdetails: true,
		}));
	};

	return (
		<>
			{/* dispute content start */}
			<div
				style={{ marginBottom: '1.5rem' }}
				className="global-search-pagination-filter-box"
			>
				{' '}
				<SmartFilter
					// hideExport
					searchTitle="Search By Email Address"
					defaultFilterBy="status"
					onSearchChange={debounce((e) => {
						dispatch(
							bankboxAPI.getAllSettlementSearch({
								per_page: 20,
								page: currentPage,
								search: user_merchant?.user?.poseidon_email,
							})
						);
					}, 500)}
					filters={[
						{
							label: 'Status',
							filterBy: 'status',
							options: [
								{
									label: 'Pending',
									value: '1',
								},
								{
									label: 'Failed',
									value: '2',
								},
								{
									label: 'Successful',
									value: '3',
								},
								{
									label: 'All Status',
									value: 'reset',
								},
							],
						},
					]}
					onExport={() => {
						setShowExport(true);
					}}
					page="bankbox_settlements"
				/>
				<div className="pagination-wrap">
					{' '}
					<Pagination
						className="top-bar__pagination"
						currentPage={currentPage}
						itemsPerPage={all_merchant_bankbox_settlement?.perPage ?? 0}
						totalItems={all_merchant_bankbox_settlement?.total ?? 0}
						onPageChange={function (page: number): void {
							setCurrentPage(page);
						}}
					/>
				</div>
			</div>
			{/* dispute content end */}
			<div
				style={{ minHeight: '45rem' }}
				className="transaction__table non-mobile"
			>
				{loading_merchant &&
				all_merchant_bankbox_settlement?.data?.length < 1 ? (
					<EmptyScreen
						loading={loading_merchant}
						loadingText={
							loading_merchant ? 'Searching...' : 'Loading ...ettlement'
						}
						title="No settlement Found"
						subTitle="Your settlements will be displayed here once available."
					/>
				) : (
					<div>
						<div
							style={{ minHeight: '45rem' }}
							className="transaction__table non-mobile"
						>
							<RavenTable
								action={false}
								className="table__main"
								headerList={[
									'ACCOUNT NAME',
									'BANK',
									'TYPE',
									'AMOUNT',
									'DATE',
									'STATUS',
								]}
							>
								<>
									{''}
									{all_merchant_bankbox_settlement?.data?.map(
										(chi: any, idx: number) => {
											return (
												<RavenTableRow
													loading={loading_merchant}
													onRowClick={() => {
														handleRowClick(chi);
														// console.log(formatMetaData(chi?.meta_data));
														// onView({
														// 	show: true,
														// 	content: {
														// 		...chi,
														// 		meta_data: meta,
														// 		pdon_meta: pdon,
														// 	},
														// });
													}}
													key={idx}
													one={
														capitalizeFirstWord(
															formatTypeFunction(
																chi?.account_details.account_name
															) as any
														) || '----'
													}
													two={
														<>
															<div className="flex_bank_code">
																<ImageFragment
																	className="icon-wrap card-bg border-theme"
																	url={`https://businessapi.getraventest.com/static/media/banks/${
																		formatMetaData(chi?.meta_data)?.bank_code
																	}.png `}
																>
																	{/* <figure className="img-box">{bankIcon}</figure> */}
																</ImageFragment>
																<TwoDetailView
																	title={
																		capitalizeFirstWord(
																			trimLongString(
																				chi?.account_details?.bank,
																				25
																			) as any
																		) || '---'
																	}
																	text_one={
																		chi?.account_details?.account_number
																	}
																/>
															</div>
														</>
													}
													three={trimLongString(chi?.type, 25 || '---')}
													four={RavenNumberFormat(chi?.amount || '---')}
													five={<DateFragmentBox date={chi?.created_at} />}
													six={
														<>
															{/* Render badges based on the Status */}
															<BadgeComponent
																text={`${
																	String(chi?.status) === '1'
																		? 'Pending'
																		: String(chi?.status) === '2'
																		? 'Failed'
																		: String(chi?.status) === '3'
																		? 'Success'
																		: 'Pending'
																}`}
																className={`${
																	String(chi?.status) === '1'
																		? 'pending'
																		: String(chi?.status) === '2'
																		? 'failed'
																		: String(chi?.status) === '3'
																		? 'success'
																		: 'pending'
																}`}
															/>
														</>
													}
												/>
											);
										}
									)}
								</>
							</RavenTable>
						</div>
					</div>
				)}
			</div>

			{/* Modal */}
			<SettlementModal
				amount={modalData?.amount}
				account_name={modalData?.account_details?.account_name}
				accountNumber={modalData?.account_details?.account_number}
				bank={modalData?.account_details.bank}
				type={modalData?.type}
				status={modalData?.Status === 1 ? 'Successful' : 'Pending'}
				date={new Date(modalData?.created_at)}
				onClose={() => {
					setShowModal((prev) => ({
						...prev,
						showdetails: false,
					}));
				}}
				visible={showModal?.showdetails}
				singleChild={singleChild}
			/>

			<ExportModal
				onClose={() => setShowExport(false)}
				visible={showExport}
				page="bankbox_settlements"
				onFinishExport={(param) => {
					formatExportData(param);
					window.open(param, '_blank')?.focus();
				}}
				// loading={loading_fee || loading_roles}
			/>
		</>
	);
};

export default Settlements;
