import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utils/banbox_axios';
import { toast } from '@ravenpay/raven-bank-ui';
import {
	AirtimePayload,
	BetPaymentPayload,
	BillsState,
	CableSubscribePayload,
	DataPlanPayload,
	DataPlanResponse,
	ElectricityPaymentPayload,
	ValidateBetAccountPayload,
} from './types';
import { logger } from '../services/logger';
import { formatSearch } from './transaction';

// Betting Slices

export const getBetTypes = createAsyncThunk(
	'/bet-types',
	async (payload, thunkAPI) => {
		try {
			const data = await axios.get(`/web/bet_types`);

			logger.log(data, 'BET TYPES');
			if (data?.data?.status === 'fail') {
				if (typeof data.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setBetTypes(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const validateBetAccount = createAsyncThunk(
	'/web/bet_account/validate',
	async (payload: ValidateBetAccountPayload, thunkAPI) => {
		try {
			const data = await axios.post(`/web/bet_account/validate`, payload);

			// if (data?.data?.status === 'fail') {
			// 	if (typeof data?.data?.data === 'string') {
			// 		toast.error(data?.data.data, {
			// 			position: 'top-right',
			// 		});
			// 	} else {
			// 		toast.error(data?.data.message, {
			// 			position: 'top-right',
			// 		});
			// 	}

			// 	return thunkAPI.rejectWithValue(data);
			// }
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });

				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const payForBetting = createAsyncThunk(
	'/web/bet_account/pay',
	async (payload: BetPaymentPayload, thunkAPI) => {
		try {
			const data = await axios.post(`/web/bet_account/pay`, payload);

			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				toast.success(data?.data.message, {
					position: 'top-right',
				});

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getBettingRecords = createAsyncThunk(
	'/bet-records',
	async (
		payload: { currentPage: string | number; per_page: number },
		thunkAPI
	) => {
		try {
			const data = await axios.get(
				`/atlas/pdon/bill/bet?page=${payload.currentPage || 1}&perPage=${
					payload?.per_page || 20
				}`
			);

			if (data?.data?.status === 'fail') {
				// if (typeof data?.data?.data === 'string') {
				// 	toast.error(data?.data?.data);
				// } else {
				// 	toast.error(data?.data.message, {
				// 		position: 'top-right',
				// 	});
				// }

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setBettingRecords(data?.data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getSingleBettingRecords = createAsyncThunk(
	'/Single-bet-records',
	async (
		payload: { currentPage: string | number; per_page: number },
		thunkAPI
	) => {
		try {
			const data = await axios.get(
				`/atlas/pdon/bill/bet?page=${payload.currentPage || 1}&perPage=${
					payload?.per_page || 20
				} `
			);

			if (data?.data?.status === 'fail') {
				// if (typeof data?.data?.data === 'string') {
				// 	toast.error(data?.data?.data);
				// } else {
				// 	toast.error(data?.data.message, {
				// 		position: 'top-right',
				// 	});
				// }

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setSingleBettingRecords(data?.data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getBetRecord = createAsyncThunk(
	'/bet-record',
	async (payload: { currentPage: string; limit: string }, thunkAPI) => {
		try {
			const data = await axios.get(
				`web/bet_records?page=${payload.currentPage}&per_page=${payload?.limit}`
			);

			if (data?.data?.status === 'fail') {
				toast.error(data?.data.message, {
					position: 'top-right',
				});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setBetRecord(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

// Airtime Slices

export const getAirtimeRecords = createAsyncThunk(
	'/airtime-record',
	async (payload: { currentPage: number; per_page: number }, thunkAPI) => {
		try {
			const data = await axios.get(
				`/atlas/pdon/bill/airtime?page=${payload.currentPage || 1}&perPage=${
					payload?.per_page || 20
				}`
			);
			// console.log(data, '-----------------------------');

			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else {
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// console.log(data?.data?.data?.data);

				thunkAPI.dispatch(setAirtimeRecords(data?.data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

// Airtime Slices

export const getSingleAirtimeRecords = createAsyncThunk(
	'/single-airtime-record',
	async (payload: { currentPage: number; per_page: number }, thunkAPI) => {
		try {
			const data = await axios.get(
				`/atlas/pdon/bill/airtime?page=${payload.currentPage || 1}&perPage=${
					payload?.per_page || 20
				}`
			);
			// console.log(data, '-----------------------------');

			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else {
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// console.log(data?.data?.data?.data);

				thunkAPI.dispatch(setSingleAirtimeRecords(data?.data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getAirtimeRecord = createAsyncThunk(
	'/airtime-record-types',
	async (payload: { phone: string | number }, thunkAPI) => {
		try {
			const { data } = await axios.get(
				`airtime/record?phone_number=${payload.phone}`
			);

			if (data?.data?.status === 'fail') {
				toast.error(data?.data.message, {
					position: 'top-right',
				});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setAirtimeRecord(data?.data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const buyAirtime = createAsyncThunk(
	'/web/airtime/recharge',
	async (payload: AirtimePayload, thunkAPI) => {
		try {
			const { data } = await axios.post(`/web/airtime/recharge`, payload);

			if (data?.status === 'fail') {
				if (typeof data?.data === 'string') {
					toast.error(data?.data);
				} else {
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.status === 'success') {
				toast.success(data?.data.message, {
					position: 'top-right',
				});

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

// Data Plan Slices

export const getDataRecords = createAsyncThunk(
	'/data-record',
	async (payload: { currentPage: number; per_page: number }, thunkAPI) => {
		try {
			const data = await axios.get(
				`/atlas/pdon/bill/data?page=${payload.currentPage || 1}&per_page=${
					payload?.per_page || 20
				}`
			);
			logger.log(data?.data, 'data record');
			logger.log(data?.data, 'data record');

			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					// toast.error(data?.data.data, {
					// 	position: 'top-right',
					// });
				} else {
					// toast.error(data?.data.message, {
					// 	position: 'top-right',
					// });
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setDataRecords(data?.data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getSingleDataRecords = createAsyncThunk(
	'/single-data-record',
	async (payload: { currentPage: number; per_page: number }, thunkAPI) => {
		try {
			const data = await axios.get(
				`/atlas/pdon/bill/data?page=${payload.currentPage || 1}&perPage=${
					payload?.per_page || 20
				}`
			);
			// console.log(data);
			

			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					// toast.error(data?.data.data, {
					// 	position: 'top-right',
					// });
				} else {
					// toast.error(data?.data.message, {
					// 	position: 'top-right',
					// });
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setSingleDataRecords(data?.data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getDataRecord = createAsyncThunk(
	'/bet-types',
	async (payload: { phone: string | number }, thunkAPI) => {
		try {
			const data = await axios.get(`data/record?phone_number=${payload.phone}`);

			if (data?.data?.status === 'fail') {
				toast.error(data?.data.message, {
					position: 'top-right',
				});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setDataRecord(data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const purchaseData = createAsyncThunk(
	'/web/data/recharge',
	async (payload: DataPlanPayload, thunkAPI) => {
		try {
			const data = await axios.post(`/web/data_plans/recharge`, payload);

			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data.data?.data);
				} else {
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				}
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				toast.success(data?.data.data.message, {
					position: 'top-right',
				});

				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getDataPlans = createAsyncThunk(
	'/data-plans',
	async (payload, thunkAPI) => {
		try {
			const data = await axios.get(`web/data_plans`);
			logger.log(data, 'd');
			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data.data?.data);
				} else {
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				}

				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setDataPlans(data?.data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

// Electricity Slices

export const validateMeterNumber = createAsyncThunk(
	'/web/electricity/validate',
	async (
		payload: {
			provider: string;
			meter_no: string | number;
			meter_type: string;
		},
		thunkAPI
	) => {
		try {
			const data = await axios.post(`/web/electricity/validate_meter`, payload);

			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });

				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getElectricityDiscos = createAsyncThunk(
	'/airtime-record',
	async (payload, thunkAPI) => {
		try {
			const data = await axios.get(`/web/electricity/discos`);

			if (data?.data?.status === 'fail') {
				toast.error(data?.data.message, {
					position: 'top-right',
				});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setElectricityDiscos(data?.data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getElectricityRecords = createAsyncThunk(
	'/electricity-record',
	async (payload: { currentPage: number; per_page: number }, thunkAPI) => {
		try {
			const data = await axios.get(
				`atlas/pdon/bill/electricity?page=${payload.currentPage || 1}&perPage=${
					payload?.per_page || 20
				}`
			);

			console.log(data);

			if (data?.data?.status === 'fail') {
				// if (typeof data?.data?.data === 'string') {
				// 	toast.error(data.data?.data);
				// } else {
				// 	toast.error(data?.data.message, {
				// 		position: 'top-right',
				// 	});
				// }
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setElectricityRecords(data?.data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getSingleElectricityRecords = createAsyncThunk(
	'/single_electricity-record',
	async (payload: { currentPage: number; per_page: number }, thunkAPI) => {
		try {
			const data = await axios.get(
				`atlas/pdon/bill/electricity?page=${payload.currentPage || 1}&perPage=${
					payload?.per_page || 20
				}`
			);

			console.log(data);

			if (data?.data?.status === 'fail') {
				// if (typeof data?.data?.data === 'string') {
				// 	toast.error(data.data?.data);
				// } else {
				// 	toast.error(data?.data.message, {
				// 		position: 'top-right',
				// 	});
				// }
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setSingleElectricityRecords(data?.data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

// 	'/all_merchant_eletricity',
// 	async (payload: { currentPage: number; per_page: number }, thunkAPI) => {
// 		try {
// 			const data = await axios.get(
// 				`atlas/pdon/bill/electricity?page=${payload.currentPage || 1}&perPage=${
// 					payload?.per_page || 20
// 				}`
// 			);

// 			// console.log(data);

// 			if (data?.data?.status === 'fail') {
// 				// if (typeof data?.data?.data === 'string') {
// 				// 	toast.error(data.data?.data);
// 				// } else {
// 				// 	toast.error(data?.data.message, {
// 				// 		position: 'top-right',
// 				// 	});
// 				// }
// 				return thunkAPI.rejectWithValue(data);
// 			}
// 			if (data?.data?.status === 'success') {
// 				// toast.success(data?.data.message, {
// 				// 	position: 'top-right',
// 				// });
// 				thunkAPI.dispatch(getMerchantAllElectricity(data?.data?.data?.data));
// 				return data;
// 			}
// 		} catch (error: any) {
// 			if (error.message === 'Network Error') {
// 				toast.error(error.message, {
// 					position: 'top-right',
// 				});
// 			}
// 			if (
// 				error.response.data.status === 'fail' &&
// 				error.response.status !== 401
// 			) {
// 				return thunkAPI.rejectWithValue(error);
// 			}
// 		}
// 	}
// );

export const payForElectricity = createAsyncThunk(
	'/web/electricity/pay',
	async (payload: ElectricityPaymentPayload, thunkAPI) => {
		try {
			const data = await axios.post(`/web/electricity/pay`, payload);

			// logger.log(data, 'pau');
			if (data?.data?.status === 'fail') {
				if (typeof data.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				toast.success(data?.data?.data?.message, {
					position: 'top-right',
				});

				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

// Cable Slices

export const validateCableAccount = createAsyncThunk(
	'/web/cable/validate_account',
	async (
		payload: {
			provider: string;
			smart_card_number: string | number;
		},
		thunkAPI
	) => {
		try {
			const data = await axios.post(`/web/cable/validate_account`, payload);

			if (data?.data?.status === 'fail') {
				if (typeof data.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
					return thunkAPI.rejectWithValue(data);
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });

				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getCablePlans = createAsyncThunk(
	'web/cable/providers',
	async (payload: { provider: string }, thunkAPI) => {
		try {
			const data = await axios.get(
				`/web/cable/providers?provider=${payload.provider}`
			);
			logger.log(data, 'd');

			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data?.data);
				} else {
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				}
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setCablePlans(data?.data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getCableRecords = createAsyncThunk(
	'/cable-records',
	async (payload: { currentPage: number; per_page: number }, thunkAPI) => {
		try {
			const data = await axios.get(
				`/atlas/pdon/bill/cable?page=${payload.currentPage || 1}&per_page=${
					payload?.per_page || 10
				}`
			);
			console.log(data);

			// if (data?.data?.status === 'fail') {
			// 	if (typeof data?.data?.data === 'string') {
			// 		toast.error(data?.data.data, {
			// 			position: 'top-right',
			// 		});
			// 	} else
			// 		toast.error(data?.data.message, {
			// 			position: 'top-right',
			// 		});
			// 	return thunkAPI.rejectWithValue(data);
			// }
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.data?.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setCableRecords(data?.data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getSingleCableRecords = createAsyncThunk(
	'/single-cable-records',
	async (payload: { currentPage: number; per_page: number }, thunkAPI) => {
		try {
			const data = await axios.get(
				`/atlas/pdon/bill/cable?page=${payload.currentPage || 1}&perPage=${
					payload?.per_page || 10
				}`
			);
			console.log(data);

			// if (data?.data?.status === 'fail') {
			// 	if (typeof data?.data?.data === 'string') {
			// 		toast.error(data?.data.data, {
			// 			position: 'top-right',
			// 		});
			// 	} else
			// 		toast.error(data?.data.message, {
			// 			position: 'top-right',
			// 		});
			// 	return thunkAPI.rejectWithValue(data);
			// }
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.data?.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setSingleCableRecords(data?.data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const subscribeCable = createAsyncThunk(
	'/web/cable/subscribe',
	async (payload: CableSubscribePayload, thunkAPI) => {
		try {
			const data = await axios.post(`/web/cable/subscribe`, payload);

			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });

				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const searchBills = createAsyncThunk(
	'/search bills/q',
	async (payload: { q: string; type: string }, thunkAPI) => {
		try {
			const data = await axios.get(
				`/web/bills/search?type=${payload?.type}&search=${payload?.q}`
			);

			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });

				if (payload.type) {
					thunkAPI.dispatch(
						setCableRecords(
							formatSearch(data?.data?.data?.data, payload.type + '_records')
						)
					);
					thunkAPI.dispatch(
						setSingleCableRecords(
							formatSearch(data?.data?.data?.data, payload.type + '_records')
						)
					);
					thunkAPI.dispatch(
						setDataRecords(
							formatSearch(data?.data?.data?.data, payload.type + '_records')
						)
					);
					thunkAPI.dispatch(
						setSingleDataRecords(
							formatSearch(data?.data?.data?.data, payload.type + '_records')
						)
					);
					thunkAPI.dispatch(
						setAirtimeRecords(
							formatSearch(data?.data?.data?.data, payload.type + '_records')
						)
					);
					thunkAPI.dispatch(
						setSingleAirtimeRecords(
							formatSearch(data?.data?.data?.data, payload.type + '_records')
						)
					);
					thunkAPI.dispatch(
						setBettingRecords(
							formatSearch(data?.data?.data?.data, payload.type + '_records')
						)
					);
					thunkAPI.dispatch(
						setSingleBettingRecords(
							formatSearch(data?.data?.data?.data, payload.type + '_records')
						)
					);
					thunkAPI.dispatch(
						setElectricityRecords(
							formatSearch(data?.data?.data?.data, payload.type + '_records')
						)
					);
					thunkAPI.dispatch(
						setSingleElectricityRecords(
							formatSearch(data?.data?.data?.data, payload.type + '_records')
						)
					);
				}
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

const dataRecordInitialState = {
	id: 0,
	merchant_email: '',
	phone_number: '',
	reference: '',
	status: '',
	refunded: 0,
	network: '',
	request_id: null,
	amount: 0,
	amount_charged: 0,
	currency: '',
	provider: '',
	data_plan: '',
	merchant_reference: '',
	created_at: '',
};
const electricityRecordInitialState = {
	id: 0,
	merchant_email: '',
	phone_number: '',
	reference: '',
	meter_number: '',
	status: '',
	refunded: 0,
	network: '',
	request_id: '',
	amount: 0,
	amount_charged: 0,
	currency: '',
	provider: '',
	merchant_reference: '',
	created_at: '',
};

const airtimeRecordInitialState = {
	id: 0,
	merchant_email: '',
	phone_number: '',
	reference: '',
	status: '',
	refunded: 0,
	network: '',
	request_id: '',
	amount: 0,
	amount_charged: 0,
	currency: '',
	provider: '',
	merchant_reference: '',
	created_at: '',
};
const initialPaginationState = {
	perPage: 0,
	currentPage: 0,
	nextPage: null,
	prevPage: null,
	totalPages: 0,
	total: 0,
};
const initialState: BillsState = {
	loading: false,
	loadingFetch: false,
	isAuth: false,
	bettingRecords: {
		data: [],
		pagination: initialPaginationState,
	},
	betRecord: {},
	betTypes: {},
	airtimeRecord: airtimeRecordInitialState,
	airtimeRecords: {
		data: [],
		pagination: initialPaginationState,
	},
	dataRecord: dataRecordInitialState,
	dataRecords: {
		data: [],
		pagination: initialPaginationState,
	},
	cableRecords: {
		data: [],
		pagination: initialPaginationState,
	},
	dataPlans: [],
	electricityDiscos: [],
	electricityRecord: electricityRecordInitialState,
	electricityRecords: {
		data: [],
		pagination: initialPaginationState,
	},
	cablePlans: [],
	// initialize other state properties
};

export const billsSlice = createSlice({
	name: 'auth',
	initialState,

	reducers: {
		setBetTypes: (state, action) => {
			state.betTypes = action.payload;
			state.isAuth = true;
		},
		setBettingRecords: (state, action) => {
			state.bettingRecords = action.payload;
			state.isAuth = true;
		},
		setSingleBettingRecords: (state, action) => {
			state.bettingRecords = action.payload;
			// state.isAuth = true;
		},
		setBetRecord: (state, action) => {
			state.betRecord = action.payload;
			state.isAuth = true;
		},
		setAirtimeRecord: (state, action) => {
			state.airtimeRecord = action.payload;
			state.isAuth = true;
		},
		setAirtimeRecords: (state, action) => {
			state.airtimeRecords = action.payload;
			// state.isAuth = true;
		},
		setSingleAirtimeRecords: (state, action) => {
			state.airtimeRecords = action.payload;
			// state.isAuth = true;
		},

		setDataRecord: (state, action) => {
			state.dataRecord = action.payload;
			state.isAuth = true;
		},
		setCableRecords: (state, action) => {
			state.cableRecords = action.payload;
			// state.isAuth = true;
		},

		setSingleCableRecords: (state, action) => {
			state.cableRecords = action.payload;
			// state.isAuth = true;
		},

		setDataRecords: (state, action) => {
			state.dataRecords = action.payload;
			// state.isAuth = true;
			// state.isAuth = true;
		},
		setSingleDataRecords: (state, action) => {
			state.dataRecords = action.payload;
			// state.isAuth = true;
			// state.isAuth = true;
		},

		setDataPlans: (state, action) => {
			state.dataPlans = action.payload;
			state.isAuth = true;
		},

		setCablePlans: (state, action) => {
			state.cablePlans = action.payload;
			state.isAuth = true;
		},

		setElectricityDiscos: (state, action) => {
			state.electricityDiscos = action.payload;
			state.isAuth = true;
		},
		setElectricityRecords: (state, action) => {
			state.electricityRecords = action.payload;
			// state.isAuth = true;
		},
		setSingleElectricityRecords: (state, action) => {
			state.electricityRecords = action.payload;
			// state.isAuth = true;
		},
	},

	extraReducers: (builder) => {
		builder
			// Handle pending actions
			.addCase(getBetTypes.pending, (state) => {
				state.loading = true;
			})
			.addCase(buyAirtime.pending, (state) => {
				state.loading = true;
			})
			.addCase(getDataPlans.pending, (state) => {
				state.loading = true;
			})
			.addCase(getAirtimeRecords.pending, (state) => {
				state.loadingFetch = true;
			})

			.addCase(getSingleAirtimeRecords.pending, (state) => {
				state.loadingFetch = true;
			})
			.addCase(getSingleDataRecords.pending, (state) => {
				state.loadingFetch = true;
			})

			.addCase(getDataRecords.pending, (state) => {
				state.loadingFetch = true;
			})
			.addCase(getCableRecords.pending, (state) => {
				state.loadingFetch = true;
			})

			.addCase(getSingleCableRecords.pending, (state) => {
				state.loadingFetch = true;
			})

			.addCase(payForElectricity.pending, (state) => {
				state.loading = true;
			})
			.addCase(getElectricityRecords.pending, (state) => {
				state.loadingFetch = true;
			})
			.addCase(getSingleElectricityRecords.pending, (state) => {
				state.loadingFetch = true;
			})
			.addCase(getBettingRecords.pending, (state) => {
				state.loadingFetch = true;
			})
			.addCase(getSingleBettingRecords.pending, (state) => {
				state.loadingFetch = true;
			})
			.addCase(purchaseData.pending, (state) => {
				state.loading = true;
			})
			.addCase(payForBetting.pending, (state) => {
				state.loading = true;
			})
			.addCase(subscribeCable.pending, (state) => {
				state.loading = true;
			})
			.addCase(searchBills.pending, (state) => {
				state.loading = true;
			})

			// Handle fulfilled actions
			.addCase(getBetTypes.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(buyAirtime.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(getDataPlans.fulfilled, (state) => {
				state.loading = false;
			})

			.addCase(getDataRecords.fulfilled, (state) => {
				state.loadingFetch = false;
			})
			.addCase(getSingleDataRecords.fulfilled, (state) => {
				state.loadingFetch = false;
			})

			.addCase(getAirtimeRecords.fulfilled, (state) => {
				state.loadingFetch = false;
			})
			.addCase(getSingleAirtimeRecords.fulfilled, (state) => {
				state.loadingFetch = false;
			})

			.addCase(getElectricityRecords.fulfilled, (state) => {
				state.loadingFetch = false;
			})
			.addCase(getSingleElectricityRecords.fulfilled, (state) => {
				state.loadingFetch = false;
			})
			.addCase(getBettingRecords.fulfilled, (state) => {
				state.loadingFetch = false;
			})
			.addCase(getSingleCableRecords.fulfilled, (state) => {
				state.loadingFetch = false;
			})

			.addCase(getSingleBettingRecords.fulfilled, (state) => {
				state.loadingFetch = false;
			})
			.addCase(getCableRecords.fulfilled, (state) => {
				state.loadingFetch = false;
			})
			.addCase(payForElectricity.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(purchaseData.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(payForBetting.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(subscribeCable.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(searchBills.fulfilled, (state) => {
				state.loading = false;
			})

			// Handle rejected actions
			.addCase(getBetTypes.rejected, (state) => {
				return initialState;
			})
			.addCase(buyAirtime.rejected, (state) => {
				return initialState;
			})
			.addCase(getDataPlans.rejected, (state) => {
				return initialState;
			})
			.addCase(getAirtimeRecords.rejected, (state) => {
				state.loadingFetch = false;
				return initialState;
			})
			.addCase(getSingleAirtimeRecords.rejected, (state) => {
				state.loadingFetch = false;
				return initialState;
			})
			.addCase(getDataRecords.rejected, (state) => {
				state.loadingFetch = false;
			})
			.addCase(getSingleDataRecords.rejected, (state) => {
				state.loadingFetch = false;
			})
			.addCase(getElectricityRecords.rejected, (state) => {
				state.loadingFetch = false;
				return initialState;
			})
			.addCase(getSingleElectricityRecords.rejected, (state) => {
				state.loadingFetch = false;
				return initialState;
			})
			.addCase(getBettingRecords.rejected, (state) => {
				state.loadingFetch = false;
				return initialState;
			})
			.addCase(getSingleBettingRecords.rejected, (state) => {
				state.loadingFetch = false;
				return initialState;
			})
			.addCase(payForElectricity.rejected, (state) => {
				return initialState;
			})
			.addCase(payForBetting.rejected, (state) => {
				state.loading = false;
			})
			.addCase(purchaseData.rejected, (state) => {
				return initialState;
			})
			.addCase(subscribeCable.rejected, (state) => {
				return initialState;
			})
			.addCase(getCableRecords.rejected, (state) => {
				state.loadingFetch = false;
			})
			.addCase(getSingleCableRecords.rejected, (state) => {
				state.loadingFetch = false;
			})

			.addCase(searchBills.rejected, (state) => {
				return initialState;
			});
	},
});

// Action creators are generated for each case reducer function
export const {
	setBetTypes,
	setBettingRecords,
	setSingleBettingRecords,
	setBetRecord,
	setDataPlans,
	setAirtimeRecord,
	setAirtimeRecords,
	setSingleAirtimeRecords,
	setDataRecord,
	setDataRecords,
	setSingleDataRecords,
	setElectricityDiscos,
	setCablePlans,
	setElectricityRecords,
	setSingleElectricityRecords,
	setCableRecords,
	setSingleCableRecords,
} = billsSlice.actions;

export default billsSlice.reducer;
