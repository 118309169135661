import React, { Fragment, useContext, useEffect } from 'react';
import {
	darkTheme,
	GlobalStyles,
	lightTheme,
} from '../../../utils/themes/theme';

import { useDarkMode } from '../../../hooks/useDarkMode';
import { ThemeProvider } from 'styled-components';
import './style/index.css';
import ravenLoader from '../../../assets/images/raven-loader.gif';
import HeaderNavComponent from '../headerNav';
import SideMenuComponent from '../sideMenu';
import GetUser from '../../../components/common/getUser/index';
// import { RavenActionBar } from '@ravenpay/raven-bank-ui';
import RootState from '../../../redux/types';
import { useDispatch, useSelector } from 'react-redux';
// import { logger } from '../../../services/logger';
import { useNavigate } from 'react-router-dom';
import { toggleMenu } from '../../../redux/actions/actions';
// import { getCookie } from '../../../utils/helper/Helper';
import SessionEndModal from './SessionExpired';
import { bankboxAPI } from '../../../redux/bankbox';
import ActionContext from '../../../context/ActionContext';
import ConfirmLogoutModal from '../ConfirmLogoutModal';
import { getCookie } from '../../../utils/helper/Helper';

interface MyComponentProps {
	children?: React.ReactNode;
	loading?: boolean;
	back?: boolean;
	onBack?: () => void;
}

type ToastType = 'none' | 'warning' | 'info' | 'error' | undefined;

const DashboardLayout: React.FC<MyComponentProps> = ({
	children,
	loading,
	back,
	onBack,
}: MyComponentProps) => {
	const { isMenuOpen } = useSelector((state: RootState) => state.general);

	const { profile } = useSelector((state: RootState) => state.wallet);
	const { partner_info, banks } = useSelector(
		(state: RootState) => state.bankbox
	);
	const [theme, toggleTheme] = useDarkMode(localStorage.getItem('theme'));
	const themeMode = theme === 'light' ? lightTheme : darkTheme;

	const { bvn, verified } = profile?.merchantInfo ?? { bvn: 0, verified: 0 };

	const {
		address_status,
		// document_verification_status,
		// cac_verified,
		// registration_number,
		// transaction_range,
		// industry,
		// business_address,
	} = profile?.businessInfo ?? {
		address_status: 0,
		document_verification_status: 0,
		cac_verified: 0,
		registration_number: null,
		transaction_range: null,
		industry: null,
		business_address: null,
	};

	const navigate = useNavigate();
	const dispatch = useDispatch();

	function toggleNav() {
		dispatch(toggleMenu(false));
	}

	// const xposKey = profile?.merchantInfo?.bankbox_key;
	// const cachdKey = getCookie('x-pos-key');

	// function toastMsg() {
	// 	if (bvn !== 3) {
	// 		return {
	// 			actionText: 'Goto BVN Verification',
	// 			action: () => navigate('/verification?kyc-stage=bvn'),
	// 			type: 'warning',
	// 			msg: 'You are yet to verify your BVN, kindly complete it to remove restrictions on your account.',
	// 		};
	// 	} else if (document_verification_status !== 1) {
	// 		return {
	// 			actionText: 'Goto ID Verification',
	// 			type: 'warning',
	// 			action: () => navigate('/verification?kyc-stage=id'),
	// 			msg: 'You are yet to verify your ID, kindly complete it to remove restrictions on your account.',
	// 		};
	// 	} else if (cac_verified !== 1) {
	// 		if (registration_number?.length > 1) {
	// 			return {
	// 				// actionText: 'Goto CAC Verification',
	// 				type: 'info',
	// 				// action: () => navigate('/verification?kyc-stage=cac'),
	// 				msg: 'We are currently reviewing your submitted CAC details. we will update you once the review has been completed. ',
	// 			};
	// 		} else
	// 			return {
	// 				actionText: 'Goto CAC Verification',
	// 				type: 'warning',
	// 				action: () => navigate('/verification?kyc-stage=cac'),
	// 				msg: 'You are yet to verify your Business CAC Details, kindly complete it to remove restrictions on your account.',
	// 			};
	// 	} else if (industry.length > 1 && transaction_range.length > 1) {
	// 		return {
	// 			actionText: 'Goto Business Verification',
	// 			type: 'warning',
	// 			action: () => navigate('/verification?kyc-stage=business-info'),
	// 			msg: 'You are yet to verify your Business Informations, kindly complete it to remove restrictions on your account.',
	// 		};
	// 	} else if (address_status !== 1) {
	// 		if (business_address.length > 1) {
	// 			return {
	// 				actionText: 'Goto Address Verification',
	// 				type: 'info',
	// 				action: () => navigate('/verification?kyc-stage=address'),
	// 				msg: 'We are currently reviewing your submitted Address Information. we will update you once the review has been completed. ',
	// 			};
	// 		} else
	// 			return {
	// 				actionText: 'Goto Address Verification',
	// 				type: 'warning',
	// 				action: () => navigate('/verification?kyc-stage=address'),
	// 				msg: 'You are yet to verify your Address Information, kindly complete it to remove restrictions on your account.',
	// 			};
	// 	}
	// }

	const showToast = bvn !== 3 || verified !== 1 || address_status !== 1;
	// logger.log(showToast, 'profile');
	const [showBar, setShowBar] = React.useState(showToast);

	useEffect(() => {
		// console.log(partner_info?.partner_info?.status);

		if (Object?.keys(partner_info)?.length < 1) {
			dispatch(bankboxAPI.getBankboxPartner({} as any) as any);
		}
	}, []);
	const bankbox_token = getCookie('x-pos-key') as string;
	const actionCtx = useContext(ActionContext);

	useEffect(() => {
		const statusVal = partner_info?.partner_info?.status;
		if (banks?.length < 1 && String(statusVal) === '1') {
			dispatch(bankboxAPI.getBankboxBanks({}));
		}
		// if (profile?.merchantInfo?.bankbox_key) {
		if (bankbox_token && String(statusVal) === '1') {
			// setCheck(true);
			// console.log("----nbdg-----");
			// setCookie('x-pos-key', profile?.merchantInfo?.bankbox_key, 3000);

			actionCtx.setIsProfileCheck(true);
		} else {
			// setCheck(false);
			actionCtx.setIsProfileCheck(false);
		}
	}, [profile]);

	// setThemeColor -----------
	useEffect(() => {
		if (!profile?.partnerDetails?.color_code) {
			const color = '#014345';
			actionCtx?.setThemeColor(color);
		} else {
			const color = 
				`#${profile?.partnerDetails?.color_code}` ||
				localStorage?.getItem('theme-color') ||
				'var(--primary-base-color)';
			actionCtx?.setThemeColor(color);
		}
	}, [profile]);

	return (
		<Fragment>
			{/* {Object?.keys(profile)?.length < 1 ? (
				<></>
			) : ( */}
			<ThemeProvider theme={themeMode}>
				<GlobalStyles />

				<div className="dashboard-layout-wrap">
					{/* sidenav wrap start */}

					<div
						className={`side-nav-wrap mobile-nav-thread  ${
							isMenuOpen && 'tablet-collapsed  show-mobile-nav '
						}`}
						style={
							{
								// background:  `var(--primary-base-color)`,
							}
						}
					>
						<SideMenuComponent theme={theme} />
					</div>
					{loading && (
						<figure className="loader-animation">
							<img src={ravenLoader} alt="" />
						</figure>
					)}
					{/* sidenav wrap end */}
					{/* main body wrap start */}
					<div
						onClick={() => {
							console.log(actionCtx?.themeColor);
						}}
						className={`main-body-wrap ${isMenuOpen ? 'expanded' : ''} `}
					>
						{/* header wrap start */}

						<div
							className={`header-wrap white-base-black-bg ${
								location.pathname.includes('dashboard-onboarding-') &&
								'onboarding-header'
							}`}
						>
							<HeaderNavComponent
								onBack={onBack}
								back={back}
								theme={theme}
								toggleTheme={toggleTheme as any}
							/>
						</div>

						{isMenuOpen && (
							<div onClick={toggleNav} className="mobile-invisible-close"></div>
						)}

						{/* {xposKey && cachdKey &&		<RavenActionBar
							actionText={toastMsg()?.actionText}
							className="overview__actionbar non-mobile"
							msg={toastMsg()?.msg}
							onAction={toastMsg()?.action}
							onCancel={() => setShowBar(false)}
							type={toastMsg()?.type as ToastType}
							visible={profile?.clearance === 'admin' ? showBar : false}
						/>} */}
						{/* header wrap end */}
						{/* main wrap start */}

						<div className="main-wrap main-wrap-bg">{children}</div>
						{/* main wrap end */}
					</div>
					{/* main body wrap end */}
				</div>
				<ConfirmLogoutModal />
				<SessionEndModal />
			</ThemeProvider>
			{/* )} */}
		</Fragment>
	);
};

export default DashboardLayout;
