import {
	RavenButton,
	RavenModal,
	RavenNumberFormat,
} from '@ravenpay/raven-bank-ui';
import '../../styles/singlesettlement.css';
import BadgeComponent from '../../../../../../../components/common/badge/BadgeComponent';

interface ComponentProps {
	amount?: number;
	account_name: string;
	accountNumber?: string;
	bank?: string;
	type?: string;
	status?: string;
	date?: Date;
	visible?: boolean;
	onClose?: () => void;
	singleChild?: any;
}

const SettlementModal: React.FC<ComponentProps> = ({
	amount,
	account_name,
	accountNumber,
	bank,
	type,
	status,
	date,
	onClose,
	visible,
	singleChild,
}) => {
	return (
		<RavenModal
			btnColor="deep-green-light"
			btnLabel="Pay Settlement"
			effect="fadeInRight"
			visble={visible}
			onClose={
				() => {
					onClose && onClose();
				}
				// setShowModal((prev) => ({ ...prev, Set_to_In_Approved: false }))
			}
			onBtnClick={() => {
				console.log('Button inside modal clicked');
			}}
			className=""
		>
			<div className="settlement_modal_general_wrapper">
				<p className="settlement_details_modal_header">Settlement Details</p>
				<div className="settlement_details_modal_contents_box">
					<div className="settlement_details_modal_contents_content_holder settle">
						<p className="settlement_details_modal_contents_content_head">
							Amount
						</p>
						<p className="settlement_details_modal_contents_content_details">
							{RavenNumberFormat(amount)}
						</p>
					</div>
					<div className="settlement_details_modal_contents_content_holder settle">
						<p className="settlement_details_modal_contents_content_head">
							Account Name
						</p>
						<p className="settlement_details_modal_contents_content_details">
							{account_name}
						</p>
					</div>
					<div className="settlement_details_modal_contents_content_holder settle">
						<p className="settlement_details_modal_contents_content_head">
							Account No.
						</p>
						<p>{accountNumber}</p>
					</div>
					<div className="settlement_details_modal_contents_content_holder settle">
						<p className="settlement_details_modal_contents_content_head">
							Bank
						</p>
						<p>{bank}</p>
					</div>
					<div className="settlement_details_modal_contents_content_holder settle">
						<p className="settlement_details_modal_contents_content_head">
							Type
						</p>
						<p className="settlement_details_modal_contents_content_details">
							{type}
						</p>
					</div>
					<div className="settlement_details_modal_contents_content_holder settle">
						<p className="settlement_details_modal_contents_content_head">
							Status
						</p>
						<p className="settlement_details_modal_contents_content_details">
							<BadgeComponent
								className={
									status === 'Successful'
										? 'success'
										: status === 'Pending'
										? 'warning'
										: 'error'
								}
								text={
									status === 'Successful'
										? 'successful'
										: status === 'Pending'
										? 'Pending'
										: 'Failed'
								}
							/>
						</p>
					</div>
					<div className="settlement_details_modal_contents_content_holder settle">
						<p className="settlement_details_modal_contents_content_head">
							Date
						</p>
						<p className="settlement_details_modal_contents_content_details">
							{date ? date.toLocaleDateString() : 'N/A'}
						</p>
					</div>
				</div>
			</div>

			{/* <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
				<RavenButton
					style={{ width: '100%' }}
					color="deep-green-light"
					label="Yes, Continue"
					loading={false}
					size="big"
					onClick={() => {
						console.log('Item deleted!');
					}}
				/>
			</div> */}
		</RavenModal>
	);
};

export default SettlementModal;
