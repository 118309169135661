import React, { useEffect, useState } from 'react';
import SingleUserLayout from '../SingleUserLayout';
import { useLocation } from 'react-router-dom';
// import Transaction from '.././Transaction';
import DeviceRequest from './DeviceRequest';
import { ALL_ROUTES } from '../../../../../../routes/dashboard/overview/AllPathNames';
// import Settlements from './Settlement';
import Dispute from './Dispute';
import ComplianceCardList from './ComplianceCardList';
import {
	RavenInputField,
	RavenModal,
	RavenToggleSlide,
	RavenToolTip,
	toast,
} from '@ravenpay/raven-bank-ui';
import RootState, {
	AssignBankBoxPayload,
	BankBoxUser,
} from '../../../../../../redux/types';
import { bankboxAPI } from '../../../../../../redux/bankbox';
import { validateStringForTerminalId } from '../../../../../../utils/helper/Helper';
import { useDispatch, useSelector } from 'react-redux';
import CameraAssignBox from '../../cameraAssign/CameraAssignBox';
import { icons } from '../../../../../../assets/icons';
import Transaction from './transaction/Transaction';

import UserTerminals from './terminals/TerminalsIndex';
import LinkTable from './paymentLinks/linkTable';
import PaymentLink from './paymentLinks/paymentLink';
import UserAirtime from './bills/singleUserBillsAirtime';
import UserData from './bills/singleUserBillsData';
import UserCableTv from './bills/singleUserBillsCable';
import Settlements from './Settlement';
import SingleElectricity from './bills/SingleElectricity';
import SingleBetting from './bills/SingleBetting';

const NewSingleMerchant = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const { all_fees, users, loading } = useSelector(
		(state: RootState) => state.bankbox
	);

	const infoIcon = (
		<svg
			className="img"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_164_20412)">
				<path
					d="M7.99967 10.6668V8.00016M7.99967 5.3335H8.00634M14.6663 8.00016C14.6663 11.6821 11.6816 14.6668 7.99967 14.6668C4.31778 14.6668 1.33301 11.6821 1.33301 8.00016C1.33301 4.31826 4.31778 1.3335 7.99967 1.3335C11.6816 1.3335 14.6663 4.31826 14.6663 8.00016Z"
					stroke="black"
					strokeWidth="1.2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_164_20412">
					<rect width="16" height="16" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
	const cameraIcon = (
		<svg
			className="img"
			width="18"
			height="19"
			viewBox="0 0 18 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_16594_47752)">
				<path
					d="M5.97578 16.0249V5.64153C5.97578 4.9518 5.97578 4.60694 6.05209 4.32399C6.25917 3.55616 6.86282 2.95642 7.63564 2.75068C7.92043 2.67487 8.26754 2.67487 8.96176 2.67487C9.65597 2.67487 10.0031 2.67487 10.2879 2.75068C11.0607 2.95642 11.6643 3.55616 11.8714 4.32399C11.9477 4.60694 11.9477 4.9518 11.9477 5.64153V10.4624M11.9477 13.429V16.0249M5.07999 16.0249H12.8435C14.0977 16.0249 14.7249 16.0249 15.2039 15.7824C15.6253 15.569 15.9679 15.2287 16.1826 14.81C16.4267 14.334 16.4267 13.711 16.4267 12.4649V9.20153C16.4267 7.95542 16.4267 7.33236 16.1826 6.8564C15.9679 6.43774 15.6253 6.09736 15.2039 5.88404C14.7249 5.64153 14.0977 5.64153 12.8435 5.64153H5.07999C3.82577 5.64153 3.19865 5.64153 2.7196 5.88404C2.29822 6.09736 1.95562 6.43774 1.74091 6.8564C1.49683 7.33236 1.49683 7.95542 1.49683 9.20153V12.4649C1.49683 13.711 1.49683 14.334 1.74091 14.81C1.95562 15.2287 2.29822 15.569 2.7196 15.7824C3.19865 16.0249 3.82577 16.0249 5.07999 16.0249ZM9.55895 13.429H13.2168C13.4258 13.429 13.5303 13.429 13.6102 13.3886C13.6804 13.3531 13.7375 13.2963 13.7733 13.2266C13.814 13.1472 13.814 13.0434 13.814 12.8357V11.0557C13.814 10.848 13.814 10.7442 13.7733 10.6648C13.7375 10.5951 13.6804 10.5383 13.6102 10.5028C13.5303 10.4624 13.4258 10.4624 13.2168 10.4624H9.55895C9.34991 10.4624 9.24539 10.4624 9.16555 10.5028C9.09532 10.5383 9.03822 10.5951 9.00244 10.6648C8.96176 10.7442 8.96176 10.848 8.96176 11.0557V12.8357C8.96176 13.0434 8.96176 13.1472 9.00244 13.2266C9.03822 13.2963 9.09532 13.3531 9.16555 13.3886C9.24539 13.429 9.34991 13.429 9.55895 13.429Z"
					stroke="black"
					strokeWidth="1.335"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_16594_47752">
					<rect
						width="17.9158"
						height="17.8"
						fill="white"
						transform="translate(0.00390625 0.44986)"
					/>
				</clipPath>
			</defs>
		</svg>
	);

	const [activeTabIndex, setActiveTabIndex] = useState<string>(
		ALL_ROUTES?.SINGLE_MERCHANT_TRANSACTION
	);

	const [assignFormData, setAssignFormData] = useState<AssignBankBoxPayload>({
		// affiliate_app_id: '',
		poseidon_business_email: '',
		serial_number: '',
		label: '',
		poseidon_business_name: '',
		exist_nibbs: true,
		// term_id: '',
		// auto_gen: true,
		auto_generate: true,
		is_nibss: true,
		terminal_id: '',
		device_type: '',
	});
	const [currentPage, setCurrentPage] = useState(1);
	const [uploadViaCamera, setUploadViaCamera] = useState(false);
	const [terminalDetails, setTerminalDetails] = useState<any>({});
	const [selectedType, setSelectedType] = useState('Yes, It is');
	const selectTypeList = ['Yes, It is', 'No, it’s not'];
	const [assignBankbox, setAssignBankbox] = useState<{
		on: boolean;
		chi: BankBoxUser;
	}>({
		on: false,
		chi: {
			id: 0,
			fname: '',
			lname: '',
			poseidon_email: '',
			bvn: '',
			nin: '',
			phone: '',
			affiliate_app: '',
			affiliate_app_id: '',
			poseidon_business_name: '',
			poseidon_business_address: '',
			poseidon_business_type: null,
			poseidon_business_description: '',
			poseidon_profile_reference: '',
			business_category: '',
			region: '',
			created_at: '',
			updated_at: '',
			lga: '',
		},
	});

	const [showTerminalError, setShowTerminalError] = useState(false);
	const [autoAssign, setAutoAssign] = useState(true);

	useEffect(() => {
		getFirstFetch();
	}, []);

	const getFirstFetch = async () => {
		dispatch(
			bankboxAPI.getAllFeesWithoutPagination({
				per_page: 20,
				page: currentPage,
				// affiliate_app_id:
			})
		);
	};

	async function handleAssign() {
		if (
			!validateStringForTerminalId(assignFormData?.terminal_id) &&
			!autoAssign
		) {
			toast.error(
				'Terminal ID must be exactly 8 characters, all capitalized and alphanumeric.'
			);
			setShowTerminalError(true);
			return;
		}
		const obj = {
			...assignFormData,
			auto_generate: autoAssign,
			is_nibss: autoAssign ? false : assignFormData?.exist_nibbs,
			terminal_id: assignFormData?.terminal_id,
		};
		// console.log(obj);

		const resp = await dispatch(bankboxAPI.assignBankbox(obj));

		if (resp.payload?.status === 'success') {
			setAssignBankbox({
				...assignBankbox,
				on: false,
			});
			getFirstFetch();
			setAssignFormData({
				exist_nibbs: true,
				// affiliate_app_id: '',
				poseidon_business_email: '',
				serial_number: '',
				label: '',
				poseidon_business_name: '',
				auto_generate: false,
				is_nibss: true,
				terminal_id: '',
				device_type: '',
			});
		}
	}

	return (
		<>
			<SingleUserLayout
				activeTabIndex={activeTabIndex}
				setActiveTabIndex={setActiveTabIndex}
			>
				{/* transaction start */}
				{activeTabIndex === ALL_ROUTES?.SINGLE_MERCHANT_TRANSACTION && (
					<Transaction />
				)}
				{/* transaction end here */}

				{/* airtime start here */}
				{activeTabIndex === ALL_ROUTES?.SINGLE_MERCHANT_BILLS_AIRTIME && (
					<UserAirtime />
				)}
				{/* airtime ends here */}

				{/* data start here */}
				{activeTabIndex === ALL_ROUTES?.SINGLE_MERCHANT_BILLS_DATA && (
					<UserData />
				)}
				{/* data ends here */}

				{/* cable start here */}
				{activeTabIndex === ALL_ROUTES?.SINGLE_MERCHANT_BILLS_CABLE && (
					<UserCableTv />
				)}
				{/* cable ends here */}

				{/* Device-request start */}
				{activeTabIndex === ALL_ROUTES?.SINGLE_MERCHANT_DEVICE_REQUEST && (
					<DeviceRequest />
				)}
				{/* Device-request end here */}

				{/* payment link starts here */}
				{activeTabIndex === ALL_ROUTES?.SINGLE_MERCHANT_PAYMENT_LINK && (
					<PaymentLink />
				)}
				{/* payment link ends here */}

				{/* Settlement start here */}
				{activeTabIndex === ALL_ROUTES?.SINGLE_MERCHANT_SETTLEMENTS && (
					<Settlements />
				)}
				{/* Settlement end here */}

				{/* Diputes start here */}
				{activeTabIndex === ALL_ROUTES?.SINGLE_MERCHANT_DISPUTE && <Dispute />}
				{/* Diputes end here */}

				{/* electricity start here */}
				{activeTabIndex === ALL_ROUTES?.SINGLE_MERCHANT_ElECTRICITY && (
					<SingleElectricity />
				)}
				{/* electricity  end here */}

				{/* betting start here */}
				{activeTabIndex === ALL_ROUTES?.SINGLE_MERCHANT_BETTING && (
					<SingleBetting />
				)}
				{/* betting end here */}

				{/* compliance start here */}
				{activeTabIndex === ALL_ROUTES?.COMPLIANCE_CARDLIST && (
					<ComplianceCardList />
				)}
				{/* compliance  end here */}

				{/* terminal start here */}
				{activeTabIndex === ALL_ROUTES?.SINGLE_USER_TERMINAL && (
					<UserTerminals />
				)}
				{/* terminal  end here */}

				{/* single user link table start here */}
				{activeTabIndex === ALL_ROUTES?.SINGLE_USER_LINK_TABLE && <LinkTable />}
				{/* single user link table end here */}
			</SingleUserLayout>
		</>
	);
};

export default NewSingleMerchant;
