import React from 'react';
import { RavenModal, RavenButton } from '@ravenpay/raven-bank-ui';
import '../modal/styles/ViewDetails.css';

interface ModalProps {
	visible: boolean;
	onClose: () => void;
	singleChild: any;
}

const DeviceRequestModal = ({ visible, onClose, singleChild }: ModalProps) => {
	// Define the rows of the table dynamically
	const details = [
		{
			label: 'Business Name',
			value: singleChild.businessName || 'Hajjiffy Ventures',
		},
		{
			label: 'Business Email',
			value: singleChild.businessEmail || 'emmanuel.adeyemi@gmail.com',
		},
		{ label: 'Reason', value: singleChild.reason || 'Agency Banking' },
		{
			label: 'Address',
			value: singleChild.address || '61, Olodo Street, Sabo Shagamu, Lagos',
		},
		{ label: 'POS Type', value: singleChild.posType || 'Bankbox' },
		{ label: 'Quantity', value: singleChild.quantity || '12 Pieces' },
		{
			label: 'Status',
			value: singleChild.status || 'Pending',
			className: 'status-pending',
		},
		{
			label: 'Date Requested',
			value: singleChild.dateRequested || '5 Sept, 2023 • 5:48PM',
		},
	];

	return (
		<RavenModal
			btnColor="deep-green-light"
			btnLabel="Pay Settlement"
			visble={visible}
			onClose={onClose}
			onBtnClick={() => {
				console.log('Button inside modal clicked');
			}}
			className="device-request-modal"
		>
			<div className="device-request-container">
				{/* Modal Header */}
				<div className="modal-header">
					<h2>Device Request</h2>
				</div>

				{/* Modal Body */}
				<div className="modal-body">
					<div className="modal-image">
						<div className="image-placeholder">Device Image</div>
					</div>

					<div className="modal-details">
						<table>
							<tbody>
								{details.map((detail, index) => (
									<tr key={index}>
										<td>{detail.label}:</td>
										<td className={detail.className || ''}>{detail.value}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>

				{/* Modal Footer */}
			</div>
		</RavenModal>
	);
};

export default DeviceRequestModal;
