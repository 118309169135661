export const inviteIcon = {
	logo: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="148"
			height="30"
			fill="none"
			viewBox="0 0 148 30"
			className="img"
		>
			<path
				fill="url(#paint0_linear_3138_33946)"
				d="M42.825 11.352c.018.02.052.086.106.128 2.56 1.97 3.104 4.87 1.336 7.58-1.497 2.291-3.64 3.542-6.41 3.591-1.893.034-3.786.028-5.678-.002-.7-.01-1.267.142-1.82.613-1.025.876-2.252 1.388-3.73 1.727.104-.376.158-.66.26-.927.965-2.517 1.94-5.03 2.905-7.548.132-.345.228-.703.341-1.055l-.209-.121c-.155.19-.34.364-.46.573-.734 1.276-1.437 2.567-2.183 3.836a34.402 34.402 0 01-1.526 2.389c-2.097 2.975-5.018 4.6-8.575 5.158-.415.065-.83.13-1.246.182-.099.011-.203-.029-.467-.07 1.84-2.968 3.722-5.819 5.561-8.696 1.817-2.844 3.638-5.684 5.457-8.526a19.56 19.56 0 00-.227-.174c-.232.274-.477.539-.692.826-1.704 2.27-3.388 4.555-5.107 6.814-2.018 2.653-4.157 5.202-6.684 7.398-2.356 2.047-4.948 3.67-8.014 4.43-1.788.444-4.318.475-5.763.06.05-.47.458-.675.748-.931 1.656-1.46 3.342-2.886 5-4.342 6.62-5.806 12.984-11.873 18.772-18.52a98.882 98.882 0 003.159-3.799c.439-.557.949-.777 1.625-.77 3.502.042 7.006.067 10.508.12.66.01 1.335.06 1.974.21 3.843.913 5.188 4.804 2.744 7.925-.515.658-1.11 1.254-1.706 1.92h.001zM32.04 19.533c.258.082.388.16.517.16 1.501-.01 3.003-.002 4.503-.057 1.053-.038 1.838-.617 2.428-1.453.539-.764.777-1.628.76-2.567-.025-1.375-.892-2.502-2.24-2.59-1.63-.105-3.274-.025-5.057-.025l-.911 6.531v.001zm1.388-9.623c1.662 0 3.24.131 4.784-.038 1.505-.164 2.588-1.955 2.404-3.585-.157-1.41-1.083-2.088-2.882-2.094-.91-.003-1.82.005-2.73.036-.74.026-.835.118-.944.84-.157 1.037-.3 2.077-.439 3.116-.072.542-.123 1.087-.193 1.725z"
			></path>
			<path
				fill="url(#paint1_linear_3138_33946)"
				d="M64.164 6.401l-2.079 14.826h-4.683l.388-2.855c-1.274 2.079-2.937 3.132-4.96 3.132-2.799 0-6.374-1.968-5.598-7.648.776-5.792 5.07-7.731 7.954-7.731 1.69 0 3.02.665 4.045 1.967l.25-1.69h4.683zm-5.875 8.424l.499-3.464c-.72-1.746-1.663-2.633-2.8-2.633-.97 0-1.828.471-2.604 1.44-.748.942-1.247 2.19-1.441 3.713-.194 1.413-.11 2.605.277 3.575.416.97 1.053 1.441 1.967 1.441 1.413 0 2.772-1.357 4.102-4.073v.001z"
			></path>
			<path
				fill="url(#paint2_linear_3138_33946)"
				d="M80.957 11.778l-1.33 9.45h-4.684l1.303-9.284c.11-1.469.139-3.242-1.413-3.242-1.386 0-2.743 1.413-4.046 4.212l-1.164 8.314h-4.684l2.079-14.826h4.683l-.388 2.799c1.303-2.05 2.993-3.076 5.07-3.076 3.242 0 5.017 2.3 4.573 5.653z"
			></path>
			<path
				fill="url(#paint3_linear_3138_33946)"
				d="M92.872 12.195l4.516 9.033h-5.293l-2.604-6.069-1.663 1.496-.638 4.572h-4.71L85.445.168h4.683l-1.746 12.498 7.039-6.263h3.769l-6.318 5.792z"
			></path>
			<path
				fill="url(#paint4_linear_3138_33946)"
				d="M115.233 15.601c-.25 1.608-.997 2.938-2.244 4.019-1.248 1.08-2.772 1.607-4.517 1.607h-8.978l2.799-19.786h8.119c3.742 0 5.792 2.079 5.348 5.182-.25 1.773-1.219 3.16-2.937 4.13 1.773.802 2.715 2.604 2.41 4.848zm-3.02 0c.471-3.048-1.718-3.796-2.91-3.796h-5.487l-1.081 7.593h5.515c1.164 0 3.574-.804 3.963-3.796zm-8.147-5.625h5.099c1.136 0 3.215-.581 3.603-3.353.388-2.826-1.524-3.353-2.716-3.353h-5.043l-.942 6.706h-.001z"
			></path>
			<path
				fill="url(#paint5_linear_3138_33946)"
				d="M118.919 19.315c-1.247-1.469-1.69-3.298-1.386-5.515.305-2.189 1.275-4.018 2.91-5.486 1.663-1.469 3.575-2.189 5.737-2.189 2.161 0 3.851.72 5.07 2.189 1.248 1.469 1.691 3.297 1.386 5.486-.305 2.217-1.274 4.046-2.91 5.515-1.635 1.469-3.547 2.189-5.709 2.189-2.161 0-3.852-.72-5.099-2.19h.001zm8.84-1.303c1.053-1.191 1.69-2.577 1.94-4.212.221-1.607-.029-2.993-.776-4.156-.72-1.192-1.719-1.773-2.993-1.773-1.275 0-2.439.581-3.492 1.773-1.053 1.164-1.69 2.549-1.94 4.156-.221 1.635.028 3.02.749 4.212.748 1.164 1.745 1.746 3.02 1.746s2.439-.582 3.492-1.746z"
			></path>
			<path
				fill="url(#paint6_linear_3138_33946)"
				d="M138.344 14.023l-4.295-7.62h3.547l3.049 5.347 4.933-5.348h2.217l-6.318 6.734 4.406 8.092h-3.519l-3.159-5.792-5.209 5.792h-2.189l6.54-7.205h-.003z"
			></path>
			<defs>
				<linearGradient
					id="paint0_linear_3138_33946"
					x1="22.88"
					x2="22.88"
					y1="1.176"
					y2="29.831"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="var(--primary-base-color)"></stop>
					<stop offset="1" stopColor="#0B8376"></stop>
				</linearGradient>
				<linearGradient
					id="paint1_linear_3138_33946"
					x1="55.644"
					x2="55.644"
					y1="6.125"
					y2="21.504"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="var(--primary-base-color)"></stop>
					<stop offset="1" stopColor="#0B8376"></stop>
				</linearGradient>
				<linearGradient
					id="paint2_linear_3138_33946"
					x1="72.981"
					x2="72.981"
					y1="6.125"
					y2="21.228"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="var(--primary-base-color)"></stop>
					<stop offset="1" stopColor="#0B8376"></stop>
				</linearGradient>
				<linearGradient
					id="paint3_linear_3138_33946"
					x1="90.835"
					x2="90.835"
					y1="0.168"
					y2="21.228"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="var(--primary-base-color)"></stop>
					<stop offset="1" stopColor="#0B8376"></stop>
				</linearGradient>
				<linearGradient
					id="paint4_linear_3138_33946"
					x1="107.657"
					x2="107.657"
					y1="1.441"
					y2="21.227"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="var(--primary-base-color)"></stop>
					<stop offset="1" stopColor="#0B8376"></stop>
				</linearGradient>
				<linearGradient
					id="paint5_linear_3138_33946"
					x1="125.085"
					x2="125.085"
					y1="6.125"
					y2="21.504"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="var(--primary-base-color)"></stop>
					<stop offset="1" stopColor="#0B8376"></stop>
				</linearGradient>
				<linearGradient
					id="paint6_linear_3138_33946"
					x1="139.801"
					x2="139.801"
					y1="6.402"
					y2="21.228"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="var(--primary-base-color)"></stop>
					<stop offset="1" stopColor="#0B8376"></stop>
				</linearGradient>
			</defs>
		</svg>
	),
	poweredBy: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="19"
			fill="none"
			viewBox="0 0 18 19"
			className="img"
		>
			<g clipPath="url(#clip0_3138_33923)">
				<path
					stroke="var(--primary-base-color)"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="M5.326 8.332H5.25v-1.5a3.75 3.75 0 117.5 0v1.5h-.076m-3.674 3v1.5m5.25-.75a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z"
				></path>
			</g>
			<defs>
				<clipPath id="clip0_3138_33923">
					<path
						fill="#fff"
						d="M0 0H18V18H0z"
						transform="translate(0 .832)"
					></path>
				</clipPath>
			</defs>
		</svg>
	),
};
