import React, { useEffect } from 'react';
import { RavenTable, RavenTableRow } from '@ravenpay/raven-bank-ui';
import { useState } from 'react';
import {
	capitalizeFirstWord,
	formatTypeFunction,
} from '../../../../../utils/helper/Helper';
import DateFragmentBox from '../../../../../components/common/DateFragmentBox';
import { FaRegEye } from 'react-icons/fa';
import { icons } from '../../../../../assets/icons';
import BadgeComponent from '../../../../../components/common/badge/BadgeComponent';
import TwoDetailView from '../../../../../components/common/TwoDetailView';
import ImageFragment from '../../../../../components/common/ImageFragment';
import '../style/ComplianceCacVerification.css';
import ComplianceLayout from '../ComplianceLayout';
import Pagination from '../../../../../components/common/pagination';
import { debounce } from 'lodash';
import SmartFilter from '../../../../../components/common/smartFilter';
import InlineDetails from '../../../../../components/common/inlineDetails';
import ActionModal from '../../../../../components/common/modal/ActionModal';
import '../compliance-cac-verification/styles/index.css';
import { useDispatch, useSelector } from 'react-redux';
import RootState, { BankBoxUser } from '../../../../../redux/types';
import { bankboxAPI } from '../../../../../redux/bankbox';
import EmptyScreen from '../../../../../components/common/emptyScreen';
import ExportModal from '../../../../../components/common/exportModal';
import { useNavigate } from 'react-router-dom';
import { ALL_ROUTES } from '../../../../../routes/dashboard/overview/AllPathNames';

const ComplianceCacVerification = () => {
	const [selectedTab, setSelectedTab] =
		React.useState<string>('CAC Verification');

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [showExport, setShowExport] = useState<boolean>(false);
	const [currentPage, setCurrentPage] = useState(1);

	const { user_merchant, all_cac, loading_cac } = useSelector(
		(state: RootState) => state.bankbox
	);

	const [refreshTable, setRefreshTable] = useState(false);

	useEffect(() => {
		getCAC();
	}, [currentPage || refreshTable, refreshTable]);

	const getCAC = async () => {
		const data = await dispatch(
			bankboxAPI.getAllCAC({
				per_page: 200,
				page: currentPage,
				search: user_merchant?.user?.merchant_email,
			})
		);
	};

	const [activeTab, setActiveTab] = useState('CAC Details');

	const [showDrop, setShowDrop] = useState<{
		show: boolean;
		id: number | string;
	}>({
		show: false,
		id: '',
	});

	type ChiType = {
		id?: number;
		bvn?: string;
		type?: string;
		email?: string;
		rc_number?: string;
		full_name?: string;
		phone?: string;
		address?: string;
		business_name?: string;
		dob?: string;
		cac_document?: string;
		cac_verified_at?: string;
		bvn_verified_at?: string;
		poseidon_email?: string;
		nin?: string;
		affiliate_app?: string;
		affiliate_app_id?: string;
		status?: number;
		business_address?: string;
	};

	const [view, setView] = useState<{ on: boolean; chi: ChiType | null }>({
		on: false,
		chi: null,
	});

	type showModalProp = {
		create: boolean;
		export: boolean;
		view_fee: boolean;
		edit_fee: boolean;
	};

	const [singleChild, setSingleChild] = useState<any>({});
	const [showModal, setShowModal] = useState<showModalProp>({
		create: false,
		export: false,
		view_fee: false,
		edit_fee: false,
	});

	function isDropped(idx: number | string) {
		return showDrop.id === idx && showDrop.show;
	}

	const rejectIcon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="22"
			fill="none"
			viewBox="0 0 22 22"
		>
			<path
				stroke="#FF0F00"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.2"
				d="m14 8-6 6m0-6 6 6m7-3c0 5.523-4.477 10-10 10S1 16.523 1 11 5.477 1 11 1s10 4.477 10 10"
			></path>
		</svg>
	);

	const verifyicon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="22"
			fill="none"
			viewBox="0 0 22 22"
		>
			<path
				stroke="#1ACE37"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.2"
				d="m6.5 11 3 3 6-6m5.5 3c0 5.523-4.477 10-10 10S1 16.523 1 11 5.477 1 11 1s10 4.477 10 10"
			></path>
		</svg>
	);

	const cameraIcon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
		>
			<path
				stroke="#020202"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.4"
				d="M11.15 14.75H4.199c-.455 0-.682 0-.787-.09a.38.38 0 0 1-.13-.314c.01-.138.171-.299.492-.62l6.378-6.377c.297-.297.445-.446.616-.502a.75.75 0 0 1 .464 0c.171.056.32.205.617.502l2.901 2.901v.9m-3.6 3.6c1.26 0 1.89 0 2.371-.245.424-.216.768-.56.984-.984.245-.48.245-1.11.245-2.371m-3.6 3.6h-6.3c-1.26 0-1.89 0-2.371-.245a2.25 2.25 0 0 1-.984-.984c-.245-.48-.245-1.11-.245-2.371v-6.3c0-1.26 0-1.89.245-2.371a2.25 2.25 0 0 1 .984-.984c.48-.245 1.11-.245 2.371-.245h6.3c1.26 0 1.89 0 2.371.245.424.216.768.56.984.984.245.48.245 1.11.245 2.371v6.3M6.875 5.375a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"
			></path>
		</svg>
	);
	const downloadIcon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
		>
			<path
				stroke="#1B1B1B"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.4"
				d="M14.75 10.25v.9c0 1.26 0 1.89-.245 2.371-.216.424-.56.768-.984.984-.48.245-1.11.245-2.371.245h-6.3c-1.26 0-1.89 0-2.371-.245a2.25 2.25 0 0 1-.984-.984c-.245-.48-.245-1.11-.245-2.371v-.9m10.5-3.75L8 10.25m0 0L4.25 6.5M8 10.25v-9"
			></path>
		</svg>
	);

	const emptypicture = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="86"
			height="106"
			fill="none"
			viewBox="0 0 86 106"
		>
			<path
				fill="#E3E3E3"
				d="M3 27c0-8.4 0-12.601 1.635-15.81a15 15 0 0 1 6.555-6.555C14.4 3 18.6 3 27 3h26l30 30v46c0 8.4 0 12.601-1.635 15.81a15 15 0 0 1-6.555 6.555C71.6 103 67.4 103 59 103H27c-8.4 0-12.601 0-15.81-1.635a15 15 0 0 1-6.555-6.555C3 91.6 3 87.4 3 79zM33 38H23z"
			></path>
			<path
				stroke="#F7F7F7"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="6"
				d="M53 4.348V25c0 2.8 0 4.2.545 5.27a5 5 0 0 0 2.185 2.185C56.8 33 58.2 33 61 33h20.653M63 58H23m40 20H23m10-40H23M53 3H27c-8.4 0-12.601 0-15.81 1.635a15 15 0 0 0-6.555 6.555C3 14.4 3 18.6 3 27v52c0 8.4 0 12.601 1.635 15.81a15 15 0 0 0 6.555 6.555C14.4 103 18.6 103 27 103h32c8.4 0 12.601 0 15.81-1.635a15 15 0 0 0 6.555-6.555C83 91.6 83 87.4 83 79V33z"
			></path>
		</svg>
	);

	const emptydirector = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="104"
			height="98"
			fill="none"
			viewBox="0 0 104 98"
		>
			<path
				fill="#D1D1D1"
				d="M52 0a6.5 6.5 0 0 0-6.5 6.5V13a6.5 6.5 0 1 0 13 0V6.5A6.5 6.5 0 0 0 52 0"
			></path>
			<path
				fill="#D1D1D1"
				fillRule="evenodd"
				d="M13 13h19.5c0 10.77 8.73 19.5 19.5 19.5S71.5 23.77 71.5 13H91c7.18 0 13 5.82 13 13v58.5c0 7.18-5.82 13-13 13H13c-7.18 0-13-5.82-13-13V26c0-7.18 5.82-13 13-13m16.25 45.5c5.385 0 9.75-4.365 9.75-9.75S34.635 39 29.25 39s-9.75 4.365-9.75 9.75 4.365 9.75 9.75 9.75m15.928 26c.213-1.05.325-2.137.325-3.25 0-8.975-7.275-16.25-16.25-16.25s-16.25 7.275-16.25 16.25c0 1.113.112 2.2.325 3.25zM65 45.5a6.5 6.5 0 0 0 0 13h19.5a6.5 6.5 0 0 0 0-13zm-6.5 26A6.5 6.5 0 0 1 65 65h13a6.5 6.5 0 0 1 0 13H65a6.5 6.5 0 0 1-6.5-6.5"
				clipRule="evenodd"
			></path>
		</svg>
	);

	const DropMore = ({ className, idx, chi }: any) => {
		const status = chi?.status; // This will now be "pending" or "verified"

		return (
			<div className={`more-wrap ${className}`}>
				{status === 'pending' && (
					<>
						<div
							style={{ opacity: 1, cursor: 'pointer' }}
							onClick={() => {
								setSingleChild(chi);
								setShowModal((prev) => ({
									...prev,
									view_fee: true,
								}));
							}}
							className="more_item"
						>
							<figure>{verifyicon}</figure>
							<p style={{ color: 'rgba(26, 206, 55, 1)' }}>Verify BVN</p>
						</div>
						<div
							style={{ opacity: 1, cursor: 'pointer' }}
							onClick={() => {
								setSingleChild(chi);
								setShowModal((prev) => ({
									...prev,
									edit_fee: true,
								}));
							}}
							className="more_item"
						>
							<figure className="img-box">{rejectIcon}</figure>
							<p style={{ color: 'rgba(255, 15, 0, 1)' }}>
								Reject BVN Verification
							</p>
						</div>
					</>
				)}

				{status === 'verified' && (
					<div
						style={{ opacity: 1, cursor: 'pointer' }}
						onClick={() => {
							setSingleChild(chi);
							setShowModal((prev) => ({
								...prev,
								revoke_fee: true,
							}));
						}}
						className="more_item"
					>
						<figure className="img-box">{rejectIcon}</figure>
						<p style={{ color: 'rgba(255, 15, 0, 1)' }}>
							Revoke BVN Verification
						</p>
					</div>
				)}
			</div>
		);
	};

	const getStatusText = (status: number) => {
		switch (status) {
			case 0:
				return 'pending';
			case 1:
				return 'verified';
			default:
				return 'unknown'; // Handle unexpected values
		}
	};

	const handleViewUser = (chi: BankBoxUser) => {
		// console.log(chi);

		localStorage?.setItem('merchant-email-user-preview', chi.poseidon_email);
		navigate(ALL_ROUTES?.SINGLE_MERCHANT);
		// navigate(
		// 	ALL_ROUTES?.BANKBOXUSER_PREVIEW
		// );
	};

	// Dummy data for all_fees
	const all_fees = {
		data: [
			{
				BUSINESSTYPE: 'Corporation',
				name: 'Folasayo Ogunnaike',
				email: 'emmy4sure.web@gmail.com',
				RCNUMBER: '545736',
				Status: 1,
				created_at: '2024-11-01T00:00:00',
			},
			{
				BUSINESSTYPE: 'Corporation',
				name: 'Yussuf Ahmed',
				email: 'emmy4sure.web@gmail.com',
				RCNUMBER: '545736',
				Status: 1,
				created_at: '2024-11-10T00:00:00',
			},
			{
				BUSINESSTYPE: 'Non Profit Organisation',
				name: 'Josh Michael',
				email: 'emmy4sure.web@gmail.com',
				RCNUMBER: '545736',
				Status: 1,
				created_at: '2024-11-15T00:00:00',
			},
			{
				BUSINESSTYPE: 'Corporation',
				name: 'Hannah Pedro',
				email: 'emmy4sure.web@gmail.com',
				RCNUMBER: '545736',
				Status: 1,
				created_at: '2024-11-15T00:00:00',
			},
			{
				BUSINESSTYPE: 'Non Profit Organisation',
				name: 'Esther Joel',
				email: 'emmy4sure.web@gmail.com',
				RCNUMBER: '545736',
				Status: 0,
				created_at: '2024-11-15T00:00:00',
			},
			{
				BUSINESSTYPE: 'Non Profit Organisation',
				name: 'Ebubechukwu Agnes',
				email: 'emmy4sure.web@gmail.com',
				RCNUMBER: '545736',
				Status: 1,
				created_at: '2024-11-15T00:00:00',
			},
		],
	};

	const getInitials = (name: string) => {
		if (!name) return '';
		const nameParts = name.trim().split(' ');
		const firstInitial = nameParts[0]?.[0]?.toUpperCase() || '';
		const lastInitial =
			nameParts.length > 1
				? nameParts[nameParts.length - 1]?.[0]?.toUpperCase()
				: '';
		return `${firstInitial}${lastInitial}`;
	};

	const trimText = (text: string, maxLength: number) => {
		return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
	};

	return (
		<>
			{/* Table content start */}
			<ComplianceLayout>
				{/* dispute content start */}
				<div
					style={{ marginBottom: '1.5rem' }}
					className="global-search-pagination-filter-box"
				>
					{' '}
					<SmartFilter
						// hideExport
						searchTitle="Search By Email Address"
						defaultFilterBy="status"
						filters={[
							{
								label: 'Status',
								filterBy: 'status',
								options: [
									{
										label: 'Pending',
										value: '0',
									},
									{
										label: 'Review',
										value: '3',
									},
									{
										label: 'Discarded',
										value: '2',
									},
									{
										label: 'Resolved',
										value: '4',
									},
								],
							},
						]}
						onExport={() => setShowExport(true)}
						page="CAC"
						onSearchChange={debounce((e) => {
							dispatch(
								bankboxAPI.getAllCAC({
									per_page: 20,
									page: currentPage,
									// search: e,
									search: user_merchant?.user?.merchant_email,
								})
							);
						}, 500)}
					/>
					<div className="pagination-wrap">
						{' '}
						<Pagination
							className="top-bar__pagination"
							currentPage={currentPage}
							itemsPerPage={all_cac?.per_page ?? 0}
							totalItems={all_cac?.total ?? 0}
							onPageChange={function (page: number): void {
								setCurrentPage(page);
							}}
						/>
					</div>
				</div>
				{/* dispute content end */}
				<div
					style={{ minHeight: '45rem' }}
					className="transaction__table non-mobile"
				>
					{!all_cac || !all_cac?.data || all_cac?.data?.length < 1 ? (
						<EmptyScreen
							loading={loading_cac}
							loadingText={loading_cac ? 'Searching...' : 'Loading CAC...'}
							title="No CAC Data Found"
							subTitle="You don't have any record yet, your CAC will be displayed here."
						/>
					) : (
						<RavenTable
							action={false}
							className="table__main"
							headerList={[
								'MERCHANT DETAILS',
								'BUSINESS TYPE',
								'RC NUMBER',
								'IMAGE',
								'DATE',
								'STATUS',
								'',
							]}
						>
							{all_cac?.data?.map((chi: any, idx: any) => {
								console.log(all_cac);
								const { name, BUSINESSTYPE, email, RCNUMBER, Status } =
									chi || {};
								return (
									<RavenTableRow
										onRowClick={() => setView({ on: true, chi })}
										key={idx}
										one={
											<>
												<div className="flex-con">
													<ImageFragment
														url={chi?.imageUrl || ''}
														className="profile-avatar"
														styles={{
															width: '4rem',
															height: '4rem',
															borderRadius: '50%',
															// background: '#f0f0f0',
														}}
														loadSize={30}
													>
														<div
															style={{
																display: 'flex',
																alignItems: 'center',
																justifyContent: 'center',
																width: '4rem',
																height: '4rem',
																borderRadius: '50%',
																background: 'rgba(247, 247, 247, 1)',
																fontSize: '1.4rem',
																fontWeight: 'bold',
																color: 'rgba(2, 2, 2, 1)',
															}}
														>
															{getInitials(chi?.full_name || 'NA')}
														</div>
													</ImageFragment>
													<TwoDetailView
														title={
															capitalizeFirstWord(
																formatTypeFunction(chi?.full_name) as any
															) || '----'
														}
														text_one={chi?.email}
													/>
												</div>
											</>
										}
										two={chi?.type || '---'}
										three={trimText(chi?.rc_number || '---', 50)}
										four={
											<>
												<div className="flex-img">
													<div>
														<ImageFragment
															className="profile-avatar"
															styles={{
																width: '4rem',
																height: '4rem',
																borderRadius: '50%',
																// background: '#f0f0f0',
															}}
															loadSize={30}
														>
															<div
																style={{
																	display: 'flex',
																	alignItems: 'center',
																	justifyContent: 'center',
																	width: '4rem',
																	height: '4rem',
																	borderRadius: '50%',
																	background: 'rgba(247, 247, 247, 1)',
																	fontSize: '1.4rem',
																	fontWeight: 'bold',
																	color: 'rgba(2, 2, 2, 1)',
																	position: 'relative',
																}}
															>
																{/* Show initials */}
																{/* {getInitials(name || 'NA')} */}

																{/* Add camera icon */}
																<span
																	style={{
																		position: 'absolute',
																		bottom: '0.4rem',
																		// right: '0.4rem',
																		// background: '#fff',
																		borderRadius: '50%',
																		padding: '0.2rem',
																		// boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
																	}}
																>
																	{cameraIcon}
																</span>
															</div>
														</ImageFragment>
													</div>
													<div>
														<ImageFragment
															className="profile-avatar"
															styles={{
																width: '4rem',
																height: '4rem',
																borderRadius: '50%',
																// background: '#f0f0f0',
															}}
															loadSize={30}
														>
															<div
																style={{
																	display: 'flex',
																	alignItems: 'center',
																	justifyContent: 'center',
																	width: '4rem',
																	height: '4rem',
																	borderRadius: '50%',
																	background: 'rgba(247, 247, 247, 1)',
																	fontSize: '1.4rem',
																	fontWeight: 'bold',
																	color: 'rgba(2, 2, 2, 1)',
																	position: 'relative',
																}}
															>
																{/* Show initials */}
																{/* {getInitials(name || 'NA')} */}

																{/* Add camera icon */}
																<span
																	style={{
																		position: 'absolute',
																		bottom: '0.4rem',
																		// right: '0.4rem',
																		// background: '#fff',
																		borderRadius: '50%',
																		padding: '0.2rem',
																		// boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
																	}}
																>
																	{downloadIcon}
																</span>
															</div>
														</ImageFragment>
													</div>
												</div>
											</>
										}
										five={
											<DateFragmentBox date={chi?.cac_verified_at || '---'} />
										}
										six={
											<>
												{/* Render badges based on the Status */}
												<BadgeComponent
													className={Status === 1 ? 'success' : 'warning'}
													text={Status === 1 ? 'verified' : 'pending'}
												/>
											</>
										}
										seven={
											<div
												style={{ position: 'relative' }}
												onClick={(e) => {
													e.stopPropagation();
													setShowDrop((prev) => ({
														...prev,
														id: idx,
														show: showDrop?.id === idx ? !showDrop.show : true,
													}));
												}}
											>
												<DropMore
													idx={idx}
													className={isDropped(idx) ? 'show-drop' : ''}
													chi={{ ...chi, status: getStatusText(chi.status) }}
												/>
												{icons.three_dots}
											</div>
										}
									/>
								);
							})}
						</RavenTable>
					)}
				</div>
			</ComplianceLayout>
			{/* Table content end */}
			<ActionModal
				style={{
					paddingBottom: '0rem',
				}}
				className="padding-b"
				visible={view.on}
				onCancel={() => {
					setView({ chi: null, on: false });
				}}
				big={true}
				onClick={() => {
					if (view.chi) {
						const user: BankBoxUser = {
							poseidon_email: view.chi.email || '',
							nin: '',
							affiliate_app: '',
							affiliate_app_id: '',
							id: Number(view.chi.id) || 0, // Ensure it's a number
							fname: view.chi.full_name?.split(' ')[0] || '', // Extract first name
							lname: view.chi.full_name?.split(' ')[1] || '', // Extract last name
							lga: '',
							bvn: view.chi.bvn || '',
							phone: view.chi.phone || '',
							address: view.chi.address || '',
							dob: view.chi.dob || '',
							bvn_verified_at: view.chi.bvn_verified_at || '',

							// Previously missing properties
							poseidon_business_name: '',
							poseidon_business_address: '',
							poseidon_business_type: '',
							poseidon_business_description: '',
							poseidon_business_reg_number: '',
							poseidon_business_reg_date: '',
							poseidon_business_phone: '',
							poseidon_business_email: '',
							poseidon_business_website: '',

							// NEW missing properties
							poseidon_profile_reference: '',
							business_category: '',
							region: '',
							created_at: new Date().toISOString(), // Default to current timestamp
							updated_at: new Date().toISOString(),
						};

						handleViewUser(user);
					}
				}}
				cancelText="No, Cancel"
				actionText="View Merchant Profile"
				hideCancel
				body="Are you sure you want to cancel the verification process? You can always come back to perform the action.."
				title={`${selectedTab.split(' ')[0]} Verification`}
			>
				{/* Tabs Navigation */}
				<div className="tabs">
					{['CAC Details', 'Director Details'].map((tab, idx) => (
						<div
							className={`item-row ${
								activeTab === tab ? 'item-row-active' : ''
							}`}
							key={idx}
							onClick={() => {
								if (activeTab !== tab) {
									setActiveTab(tab);
								}
							}}
						>
							<p className="name grey-white-color">{tab}</p>
						</div>
					))}
				</div>

				{/* Conditional Rendering for Tabs */}
				{view.chi && typeof view.chi === 'object' && (
					<>
						{activeTab === 'CAC Details' ? (
							<div className="compliance-results" style={{ marginTop: '4rem' }}>
								<div className="compliance-results__image-preview">
									<div
										style={{
											backgroundColor: 'rgba(238, 238, 238, 1)',
											color: 'rgba(100, 100, 100, 1)',
											padding: '8px 14px 8px',
											borderRadius: '16px',
											fontSize: '12px',
											fontWeight: '500',
											display: 'inline-block',
											textAlign: 'center',
											margin: '5rem',
										}}
									>
										CAC Document
									</div>
									<div
										style={{
											paddingBottom: '5rem',
										}}
									>
										<figure>{emptypicture}</figure>
									</div>
									<div className="image-preview__pagination">
										<figure className="disabled">
											{icons.arrow_back_circular_inactive}
										</figure>

										<span>
											<p>1 of 2</p>
										</span>
										<figure>{icons.arrow_forward_circular}</figure>
									</div>
								</div>
								<div className="compliance-results__main">
									<InlineDetails
										direction={''}
										title={'Transaction Details'}
										simple
										amount={0}
										onClick={() => {}}
										content={[
											{
												label: 'Business name',
												value: view.chi?.business_name || '---',
												copy: true,
												
											},
											{
												label: 'Business Email',
												value: view.chi?.email || '---',
											},

											{
												label: 'Business Type',
												value: view.chi?.type || '---',
											},

											{
												label: 'RC Number',
												value: view.chi?.rc_number || '---',
											},

											{
												label: 'Document Link',
												value: view.chi?.cac_document || '---',
											},

											{
												label: 'Date Authorized',
												value: view.chi?.cac_verified_at || '----',
											},
										]}
									/>
								</div>
							</div>
						) : (
							<div className="compliance-results" style={{ marginTop: '4rem' }}>
								<div className="compliance-results__image-preview">
									<div
										style={{
											backgroundColor: 'rgba(238, 238, 238, 1)',
											color: 'rgba(100, 100, 100, 1)',
											padding: '8px 14px 8px',
											borderRadius: '16px',
											fontSize: '12px',
											fontWeight: '500',
											display: 'inline-block',
											textAlign: 'center',
											margin: '5rem',
										}}
									>
										Director’s ID
									</div>
									<div
										style={{
											paddingBottom: '5rem',
										}}
									>
										<figure>{emptydirector}</figure>
									</div>
									<div className="image-preview__pagination">
										<figure className="disabled">
											{icons.arrow_back_circular_inactive}
										</figure>

										<span>
											<p>1 of 3 Directors</p>
										</span>
										<figure>{icons.arrow_forward_circular}</figure>
									</div>
								</div>
								<div className="compliance-results__main">
									<InlineDetails
										direction={''}
										title={'Transaction Details'}
										simple
										amount={0}
										onClick={() => {}}
										content={[
											{
												label: 'Fullname',
												value: view.chi?.full_name || '---',
												copy: true,
											},
											{
												label: 'Director’s Email',
												value: view.chi?.email || '---',
											},

											{
												label: 'Mobile Number',
												value: view.chi?.phone
													? `+234 ${view.chi?.phone}`
													:  '---',
											},

											{
												label: 'BVN',
												value: view.chi?.bvn || '---',
											},

											{
												label: 'NIN',
												value: view.chi?.nin || '---',
											},

											{
												label: 'Date Authorized',
												value: view.chi?.cac_verified_at || '----',
											},
											{
												label: 'Verification status',
												value: (
													<BadgeComponent
														className={
															view.chi?.status === 1 ? 'success' : 'warning'
														}
														text={
															view.chi?.status === 1 ? 'verified' : 'pending'
														}
													/>
												),
											},
											{
												label: 'Full Address',
												value: view.chi?.business_address || '----',
											},
										]}
									/>
								</div>
							</div>
						)}
					</>
				)}
			</ActionModal>

			{/* view detail modal end */}
			<ExportModal
				onClose={() => setShowExport(false)}
				visible={showExport}
				page="CAC"
				onFinishExport={(param) => {
					// console.log(param);
					window.open(param, '_blank')?.focus();
				}}
			/>
		</>
	);
};

export default ComplianceCacVerification;
