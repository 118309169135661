import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../../layout/dashboard/dashboardLayout';
import PageLayout from '../../../../../layout/dashboard/tableLayout';
import {
	RavenButton,
	RavenInputField,
	RavenModal,
	RavenTable,
	RavenTableRow,
} from '@ravenpay/raven-bank-ui';
import {
	capitalizeAllLetter,
	formatDate,
	formatDateTime,
	formatNumWithComma,
	formatNumWithCommaNaira,
	formatTypeFunction,
	getActionNamesByPolicyName,
	mapHotkey,
	symbol,
} from '../../../../../utils/helper/Helper';
import { faker } from '@faker-js/faker';
import { icons } from '../../../../../assets/icons';
import Pagination from '../../../../../components/common/pagination';
import EmptyScreen from '../../../../../components/common/emptyScreen';
import './style/index.css';
import { generateRandomColor } from '../../../../../utils/helper/color';
import { useNavigate } from 'react-router-dom';
import { getTransfers } from '../../../../../redux/wallet';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../redux/store';
import Skeleton from 'react-loading-skeleton';
import { logger } from '../../../../../services/logger';
import useDebounce from '../../../../../hooks/UseDebounce';
import { OmniSearch } from '../../..redux/transaction';
import Copy from '../../../../../components/common/copyCheck';
import SmartFilter from '../../../../../components/common/smartFilter';
import ExportModal from '../../../../../components/common/exportModal';
import TransactionModal from '../../../../../components/common/transactionModal';
import MobileTableCard from '../../../../../components/common/table/mobile/MobileTable';
import { omniSearch } from '../../../../../redux/transaction';
import { bankboxAPI } from '../../../../../redux/bankbox';
import './style/index.css';
import { debounce } from 'lodash';
import { FaRegEye } from 'react-icons/fa';
import BadgeComponent from '../../../../../components/common/badge/BadgeComponent';
import ChangeRequestStatusModal from './ChangeRequestStatusModal';
import DateFragmentBox from '../../../../../components/common/DateFragmentBox';

const BankboxRequest = () => {
	const { profile } = useSelector((state: RootState) => state.settings);
	const [demo, setDemo] = useState(true);
	const [showExport, setShowExport] = useState<boolean>(false);
	const [activeCurrency, setActiveCurrency] = useState('ngn');
	const [currentPage, setCurrentPage] = useState(1);
	const [activeFilter, setActiveFilter] = useState<string>('');
	const [showConfiguration, setShowConfiguration] = useState<boolean>(false);
	type showModalProp = {
		status: boolean;
		export: boolean;
	};
	const [showModal, setShowModal] = useState<showModalProp>({
		status: false,
		export: false,
	});
	const [view, onView] = useState<any>({
		show: false,
		view: '',
	});
	const [searchQuery, setSearchQuery] = useState('');

	useState<string>('business-account');
	const [boot, onBoot] = useState(false);
	const [dateFilter, setDateFilter] = useState<{
		to: Date | any;
		from: Date | any;
	}>({
		from: '',
		to: '',
	});

	const [showDrop, setShowDrop] = useState<{
		show: boolean;
		id: number | string;
	}>({
		show: false,
		id: '',
	});

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { transfers } = useSelector((state: RootState) => state.wallet);

	const { searchLoading } = useSelector(
		(state: RootState) => state.transaction
	);

	const { loading, all_bankbox_request } = useSelector(
		(state: RootState) => state.bankbox
	);

	useEffect(() => {
		if (
			typeof dateFilter.from === 'object' ||
			typeof dateFilter.to === 'object'
		) {
			setDateFilter({
				to: '',
				from: '',
			});
		}
	}, [activeFilter]);

	useEffect(() => {
		if (typeof activeFilter !== undefined) {
			setActiveFilter('');
		}
	}, [dateFilter.to || dateFilter.from]);

	function formatStatus(status: string | number) {
		let s;
		// 		0 = pending, 1 = approved, 2 = dispatched, 3 = delivered
		// [3:17 PM] 4 = declined
		if (status === 0 || status === 'pending') {
			s = 'pending';
		}
		if (status === 1) {
			s = 'approved';
		}
		if (status === 2) {
			s = 'dispatched';
		}
		if (status === 3) {
			s = 'delivered';
		}
		if (status === 4) {
			s = 'declined';
		}
		return (
			<div className={`transaction-status ${s && s?.toLowerCase()}`}>
				<p style={{ textTransform: 'capitalize' }}>{s}</p>
			</div>
		);
	}
	const [refreshTable, setRefreshTable] = useState(false);
	//fetch transfers

	const [noDataFound, setNoDataFound] = useState<boolean>(true);
	React.useEffect(() => {
		getAllUserFirst();
	}, [currentPage, refreshTable]);

	const getAllUserFirst = async () => {
		const data = await dispatch(
			bankboxAPI.getAllBankBoxRequest({
				page: currentPage,
				per_page: 20,
			})
		);
		if (data?.payload?.status === 'success') {
			// console.log(data);

			const checkLength = data?.payload?.data?.data?.data;
			if (checkLength?.length > 0) {
				setNoDataFound(false);
				// console.log('ooo');
			} else {
				setNoDataFound(true);
			}
		}
	};

	const handleFinish = () => {
		setRefreshTable(!refreshTable);
		setShowModal((prev) => {
			return { ...prev, export: false, status: false };
		});
	};
	const debounceSearchTerm = useDebounce(searchQuery, 1000);
	useEffect(() => {
		let isMount = true;
		// logger.log('searching');

		if (isMount && debounceSearchTerm?.length > 2) {
			dispatch(omniSearch(searchQuery));
		}

		if (isMount && debounceSearchTerm?.length < 1) {
			dispatch(
				getTransfers({
					page: currentPage,
					limit: 20,
				})
			);
		}

		return () => {
			isMount = false;
		};
	}, [debounceSearchTerm]);
	const req = all_bankbox_request?.data;

	function isDropped(idx: number | string) {
		if (showDrop.id === idx && showDrop.show) return true;
		else return false;
	}

	const [singleChild, setSingleChild] = useState<any>({});

	function DropMore({ className, idx, chi }: any) {
		return (
			<div className={`more-wrap ${className}`}>
				{getActionNamesByPolicyName(
					profile?.rolePolicies?.policies,
					'bankbox requests'
				)?.includes('approve_bankbox_request') ||
				getActionNamesByPolicyName(
					profile?.rolePolicies?.policies,
					'bankbox requests'
				)?.includes('deny_bankbox_request') ? (
					<div
						style={{
							opacity: '1',
							cursor: 'pointer',
						}}
						onClick={() => {
							setSingleChild(chi);
							setShowModal((prev) => {
								return { ...prev, status: true };
							});
						}}
						className="more_item"
					>
						<figure>
							{
								<svg
									className="img"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<circle cx="12" cy="12" r="12" fill="#F9F9F9" />
									<path
										d="M13.5253 10.5H8.75C8.05964 10.5 7.5 9.94036 7.5 9.25C7.5 8.55964 8.05964 8 8.75 8H13.5253M10.4747 16H15.25C15.9404 16 16.5 15.4404 16.5 14.75C16.5 14.0596 15.9404 13.5 15.25 13.5H10.4747M7.5 14.75C7.5 15.7165 8.2835 16.5 9.25 16.5C10.2165 16.5 11 15.7165 11 14.75C11 13.7835 10.2165 13 9.25 13C8.2835 13 7.5 13.7835 7.5 14.75ZM16.5 9.25C16.5 10.2165 15.7165 11 14.75 11C13.7835 11 13 10.2165 13 9.25C13 8.2835 13.7835 7.5 14.75 7.5C15.7165 7.5 16.5 8.2835 16.5 9.25Z"
										stroke="var(--primary-base-color)"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							}
						</figure>
						<p>{`Change Status`}</p>
					</div>
				) : (
					''
				)}
				<div
					style={{
						opacity: '1',
						cursor: 'pointer',
					}}
					onClick={() => {
						onView({
							show: true,
							content: chi,
						});
					}}
					className="more_item"
				>
					<figure>
						<FaRegEye />
					</figure>
					<p>View Request Details</p>
				</div>
				{getActionNamesByPolicyName(
					profile?.rolePolicies?.policies,
					'merchants'
				)?.includes('edit_merchant') && (
					<div
						style={{
							opacity: '1',
							cursor: 'pointer',
						}}
						onClick={() => {
							navigate('/dashboard-users?user_email=' + chi.email);
						}}
						className="more_item"
					>
						<figure>
							<FaRegEye />
						</figure>
						<p>View Merchant Details</p>
					</div>
				)}
			</div>
		);
	}
	if (
		!getActionNamesByPolicyName(
			profile?.rolePolicies?.policies,
			'bankbox requests'
		)?.includes('view_bankbox_requests')
	) {
		return (
			<DashboardLayout>
				<>
					<EmptyScreen
						loading={Object?.keys(profile)?.length < 1 ? true : false}
						title="Permission Denied"
						subTitle="You have no permission to access this page, Please contact admin or support."
					></EmptyScreen>
				</>
			</DashboardLayout>
		);
	}

	return (
		<>
			<DashboardLayout>
				<PageLayout
					pageSubtitle="All BankBox request made on the platform"
					pageTitle="Request"
					topRightContent={
						req?.length > 0 ? (
							<>
								{/* <RavenButton
									color="deep-green-light"
									label="Request a Device"
									onClick={() => {}}
								/> */}
							</>
						) : (
							<></>
						)
					}
				>
					{!noDataFound && (
						<div
							onClick={() => {
								setShowDrop({
									show: false,
									id: '',
								});
							}}
							className="global-search-pagination-filter-box"
						>
							<SmartFilter
								searchTitle="Search by email"
								defaultFilterBy="status"
								onSearchChange={debounce((e) => {
									// console.log(e);
									dispatch(
										bankboxAPI.getAllBankBoxRequest({
											per_page: 20,
											page: currentPage,
											search: e,
										})
									);
								}, 500)}
								// 		0 = pending, 1 = approved, 2 = dispatched, 3 = delivered
								// [3:17 PM] 4 = declined
								filters={[
									{
										label: 'Status',
										filterBy: 'status',
										options: [
											{
												label: 'Declined',
												value: '4',
											},
											{
												label: 'Approved',
												value: '1',
											},
											{
												label: 'Dispatched',
												value: '2',
											},
											{
												label: 'Delivered',
												value: '3',
											},
											{
												label: 'Pending',
												value: '0',
											},
											{
												label: 'All',
												value: ' ',
											},
										],
									},
								]}
								hideExport
								onExport={() => setShowExport(true)}
								page="bankbox_requests"
							/>
							<Pagination
								className="top-bar__pagination"
								currentPage={currentPage}
								itemsPerPage={all_bankbox_request?.perPage}
								totalItems={all_bankbox_request?.total}
								onPageChange={function (page: number): void {
									setCurrentPage(page);
								}}
							/>
						</div>
					)}

					<div
						onClick={() => {
							setShowDrop({
								show: false,
								id: '',
							});
						}}
						className="content__main"
					>
						<div className="main__wrap">
							{loading || searchLoading || req?.length < 1 ? (
								<EmptyScreen
									loading={loading || searchLoading}
									loadingText={
										searchLoading ? 'Searching...' : 'Loading Requests...'
									}
									title="No Requests Found"
									subTitle="You don't have any bankbox requests yet, place a new request and have them displayed here."
								/>
							) : (
								<>
									<div
										// onClick={() => {
										// 	console.log(req);
										// }}
										onClick={() => {
											setShowDrop({
												show: false,
												id: '',
											});
										}}
										className="transaction__table non-mobile"
									>
										<RavenTable
											action={false}
											className="table__main"
											headerList={[
												'EMAIL',
												'QUANTITY',
												// 'AMOUNT',
												'BUSINESS NAME',
												'MOBILE',
												'STATUS',
												'REQUEST DATE',
												'ACTION',
											]}
										>
											{req?.map((chi: any, idx: any) => {
												return (
													<React.Fragment key={idx}>
														<RavenTableRow
															onRowClick={() => {
																onView({
																	show: true,
																	content: chi,
																});
																// console.log(chi);
															}}
															className="table__main"
															five={
																loading ? (
																	<Skeleton
																		style={{ borderRadius: '10px' }}
																		width={80}
																		height={20}
																	/>
																) : (
																	formatStatus(chi.status)
																)
															}
															three={
																capitalizeAllLetter(
																	chi?.merchant_business_name
																) || '---'
															}
															six={
																loading ? (
																	<Skeleton width={100} height={20} />
																) : (
																	// formatDateTime(chi?.created_at)
																	<DateFragmentBox date={chi?.created_at} />
																)
															}
															two={
																loading ? (
																	<Skeleton width={100} height={20} />
																) : (
																	chi.quantity
																)
															}
															seven={
																<div
																	style={{ position: 'relative' }}
																	onClick={(e) => {
																		e.stopPropagation();
																		setShowDrop((prev) => {
																			return {
																				...prev,
																				id: idx,
																				show:
																					showDrop?.id === idx
																						? !showDrop.show
																						: true,
																			};
																		});
																	}}
																>
																	<DropMore
																		idx={idx}
																		className={isDropped(idx) && 'show-drop'}
																		chi={chi}
																	/>
																	{icons.three_dots}
																</div>
															}
															four={
																loading ? (
																	<Skeleton width={80} height={20} />
																) : (
																	<div className="type-wrap">
																		<span>
																			{(chi?.phone as string) || '---'}
																		</span>
																	</div>
																)
															}
															one={
																loading ? (
																	<Skeleton width={100} height={20} />
																) : (
																	chi.email
																)
															}
														/>
													</React.Fragment>
												);
											})}
										</RavenTable>
									</div>

									<div className="atlas-mobile-table-wrap">
										{req?.map((chi, idx: React.Key | null | undefined) => {
											// const { first_name, last_name, bank } = meta_data;

											// logger.log(meta_data);
											return (
												<MobileTableCard
													title={chi.email}
													text={formatDateTime(chi.created_at)}
													amount={
														symbol('ngn') +
														formatNumWithComma(chi.amount, 'ngn')
													}
													img={undefined}
													svg={undefined}
													avatar={''}
													amountColor={'var(--primary-base-color)'}
													type={''}
													showImg={true}
													loading={loading}
													onRowClick={() =>
														onView({
															show: true,
															content: chi,
														})
													}
													textTwo={''}
												/>
											);
										})}
									</div>
								</>
							)}
						</div>
					</div>
				</PageLayout>
			</DashboardLayout>

			<TransactionModal
				onClose={() =>
					onView({
						show: false,
						content: {
							type: '',
							bank: '',
							description: '',
							amount: '',
							date: '',
							session_id: '',
							account_number: '',
							email: '',
							created_at: function (created_at: any): string {
								throw new Error('Function not implemented.');
							},
							updated_at: function (updated_at: any): string {
								throw new Error('Function not implemented.');
							},
						},
						on: false,
					})
				}
				visible={view.show}
				direction={view?.content?.direction}
				title={'Request Details'}
				amount={view?.content?.amount as unknown as number}
				onClick={() => {}}
				meta={view?.content?.source}
				content={[
					{
						label: 'Quantity',
						value: `${view?.content?.quantity} ` || '---',
					},
					{
						label: 'Amount',
						value:
							symbol('ngn') +
								formatNumWithComma(view?.content?.amount, 'ngn') || '---',
					},
					{
						label: 'Email',
						value: view?.content?.email || '---',
					},
					{
						label: 'Business Name',
						value:
							capitalizeAllLetter(view?.content?.merchant_business_name) ||
							'---',
					},
					{
						label: 'Mobile',
						value: view?.content?.phone || '---',
					},
					{
						label: 'Delivery State',
						value: view?.content?.delivery_state || '---',
					},
					{
						label: 'Delivery Address',
						value: view?.content?.delivery_address || '--',
					},
					{
						label: 'Delivery Option ',
						value: formatTypeFunction(view?.content?.delivery_option) || '---',
						// copy: true,
					},
					{
						label: 'Status',
						value: formatStatus(view?.content?.status) || '---',
						// copy: true,
					},
					{
						label: 'Request Date',
						value: formatDateTime(
							view?.content?.created_at as unknown as string
						),
						// value: <DateFragmentBox date={view?.content?.created_at} />,
					},
					// {
					// 	label: 'Updated At',
					// 	value: formatDateTime(
					// 		view?.content?.updated_at as unknown as string
					// 	),
					// },
				]}
			/>
			<ExportModal
				onClose={() => setShowExport(false)}
				visible={showExport}
				page="bankbox_requests"
			/>
			<ChangeRequestStatusModal
				visible={showModal.status}
				onClose={() => {
					setShowModal((prev) => {
						return { ...prev, status: false };
					});
				}}
				onFinish={handleFinish}
				details={singleChild}
			/>
		</>
	);
};

export default BankboxRequest;
