import React, { createContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { colorToRgba } from '../utils/helper/Helper';

const ActionContext = createContext<any>({
	sideMenuDrop: {
		one: false,
		two: false,
		three: false,
		four: false,
	},
	toggleTheme: () => {},
	setSideMenuDrop: (param?: any) => {},
	isProfileCheck: false,
	setIsProfileCheck: (param?: any) => {},
	sessionExpire: false,
	setSessionExpire: (param?: any) => {},
	logoutModal: false,
	setLogoutModal: (param?: any) => {},
	themeColor: '',
	setThemeColor: (param?: any) => {},
});

export function ActionContextProvider(props?: any) {
	const location = useLocation();
	const [isLogoutVal, setIslogoutVal] = useState(false);
	const [isSessionExpireVal, setSessionExpireVal] = useState(false);
	const [sideMenuDropVal, setSideMenuDropVal] = useState({
		one: false,
		two: false,
		three: false,
		four: false,
	});

	function setSideMenuDropValFunc(param?: any) {
		if (param === 'one') {
			setSideMenuDropVal((prev) => {
				return {
					...prev,
					one: !sideMenuDropVal?.one,
					two: false,
					three: false,
					four: false,
				};
			});
		}

		if (param === 'two') {
			setSideMenuDropVal((prev) => {
				return {
					...prev,
					one: false,
					two: !sideMenuDropVal?.two,
					three: false,
					four: false,
				};
			});
		}
		if (param === 'three') {
			setSideMenuDropVal((prev) => {
				return {
					...prev,
					one: false,
					three: !sideMenuDropVal?.three,
					two: false,
					four: false,
				};
			});
		}

		if (param === 'four') {
			setSideMenuDropVal((prev) => {
				return {
					...prev,
					one: false,
					four: !sideMenuDropVal?.four,
					two: false,
					three: false,
				};
			});
		}
		if (param === 'close') {
			setSideMenuDropVal((prev) => {
				return { ...prev, one: false, two: false, three: false, four: false };
			});
		}
	}
	const [isProfileCheckVal, setIsProfileCheckVal] = useState(false);
	function setIsProfileCheckFunc(param?: any) {
		setIsProfileCheckVal(param);
	}

	function SessionExpirefunc(param: any) {
		setSessionExpireVal(param);
	}

	function logoutModalChange(param: any) {
		setIslogoutVal(param);
	}

	const [themeVal, setThemeVal] = useState(
		localStorage?.getItem('theme-color')
	);
	function setThemeColorFunc(param: any) {
		// console.log(param, '-----');

		setThemeVal(param);
		document.documentElement.style.setProperty(
			'--primary-base-color',
			`${param}` as string
		);
		document.documentElement.style.setProperty(
			'--primary-accent-color-one',
			`${colorToRgba(param, 0.1)}` as string
		);
		document.documentElement.style.setProperty(
			'--primary-accent-color-two',
			`${colorToRgba(param, 0.5)}` as string
		);
		document.documentElement.style.setProperty(
			'--primary-accent-color-three',
			`${colorToRgba(param, 0.8)}` as string
		);
		localStorage?.setItem('theme-color', param);
	}

	const context = {
		sideMenuDrop: sideMenuDropVal,
		setSideMenuDrop: setSideMenuDropValFunc,
		isProfileCheck: isProfileCheckVal,
		setIsProfileCheck: setIsProfileCheckFunc,
		sessionExpire: isSessionExpireVal,
		setSessionExpire: SessionExpirefunc,
		logoutModal: isLogoutVal,
		setLogoutModal: logoutModalChange,
		themeVal: themeVal,
		setThemeColor: setThemeColorFunc,
	};

	return (
		<ActionContext.Provider value={context}>
			{props.children}
		</ActionContext.Provider>
	);
}

export default ActionContext;
