export const ALL_ROUTES = {
	OVERVIEW: '/dashboard-overview',
	SINGLE_MERCHANT: '/dashboard-users-single',
	BANKBOX_USER: '/dashboard-users',
	SINGLE_MERCHANT_TRANSACTION: '/dashboard-users-single-transaction',
	SINGLE_MERCHANT_DEVICE_REQUEST: '/dashboard-users-single-device-requests',
	SINGLE_MERCHANT_SETTLEMENTS: '/dashboard-users-single-Settlements',
	SINGLE_MERCHANT_DISPUTE: '/dashboard-users-single-dispute',
	SINGLE_MERCHANT_ElECTRICITY: '/dashboard-users-single-Electricity',
	SINGLE_MERCHANT_BETTING: '/dashboard-users-single-Betting',
	COMPLIANCE_CARDLIST: '/dashboard-users-single-Compliance',
	BANKBOXUSER_PREVIEW: '/dashboard-user-single',
	ONBOARDING: '/dashboard-onboarding',
	BANKBOX_REQUEST: '/dashboard-requests',
	BANKBOX_TERMINALS: '/dashboard-terminals',
	SINGLE_MERCHANT_VIEW_BANKBOX: '/dashboard-terminals/view',
	BANKBOX_TRANSACTIONS: '/dashboard-transactions',
	SINGLE_MERCHANT_BANKBOX_TRANSACTIONS: '/dashboard-transaction-single',
	COMPLIANCE_BVN_VERIFICATION: '/dashboard-compliance-bvn-verification',
	COMPLIANCE_NIN_VERIFICATION: '/dashboard-compliance-nin-verification',
	COMPLIANCE_ADDRESS_VERIFICATION: '/dashboard-compliance-address-verification',
	COMPLIANCE_CAC_VERIFICATION: '/dashboard-compliance-cac-verification',
	// COMPLIANCE_LIVELINESS_CHECK: '/dashboard-compliance-liveliness-check',
	BANBOX_SETTINGS: '/dashboard-settings-general',
	TEAM_SETTINGS_INDEX: '/dashboard-settings-teams',
	SINGLE_USER_TERMINAL: '/dashboard-users-single-terminals',
	SINGLE_USER_LINK_TABLE: '/dashboard-bankbox/users-single-link-table',
	SINGLE_MERCHANT_PAYMENT_LINK:
		" '/dashboard-bankbox/users-single-payment-link'",
	SINGLE_MERCHANT_BILLS_AIRTIME: '/dashboard-single-merchant-bills-airtime',
	SINGLE_MERCHANT_BILLS_DATA: '/dashboard-single-merchant-bills-data',
	SINGLE_MERCHANT_BILLS_CABLE: '/dashboard-single-merchant-bills-cable',
	SINGLE_MERCHANT_BILLS_LINK_DETAILS:
		'/dashboard-bankbox/single_merchant_link_details',
};
