import { RavenButton, RavenModal } from '@ravenpay/raven-bank-ui';
import React, { ReactNode } from 'react';
import './styles/index.css';
import ReactDOM from 'react-dom';
import CopyText from '../copyCheck';
import { handleCopy } from '@/utils/helper/Helper';
import ReactDOMServer from 'react-dom/server';

type ModalProps = {
	title: string;
	body?: string;
	cancelText?: string;
	actionText?: string;
	className?: string;
	btnClassName?: string;
	visible: boolean;
	onCancel: () => void;
	onClick: () => void;
	btnColor?: string;
	hideCancel?: boolean;
	big?: boolean;
	extraBig?: boolean;
	normal?: boolean;  
	children?: ReactNode;
	loading?: boolean;  
	btnTextColor?: string;
	hideNormalButton?: boolean;
	preventOuterClose?: boolean;
	altCancelFunc?: () => void;
	subTitle?: string;
	disableButton?: boolean;
	inlineCopyContent?: any;
	style?: React.CSSProperties;
};

const getModalContainer = () => {
	let portal = document.getElementById('modal');
	if (!portal) {
		portal = document.createElement('div');
		portal.id = 'modal';
		document.body.appendChild(portal);
	}
	return portal;
};

const ActionModal = (props: ModalProps) => {
	const {
		style,
		title,
		body,
		cancelText,
		actionText,
		className,
		btnClassName,
		onCancel,
		visible = false,
		onClick,
		btnColor,
		hideCancel = false,
		big = false,
		extraBig = false,
		normal = false,
		subTitle,
		inlineCopyContent,
		children,
		loading,
		btnTextColor,
		hideNormalButton,
		altCancelFunc,
		preventOuterClose,
		disableButton,
	} = props;

	const portal = getModalContainer();
	const [inlineCopied, setInlineCopied] = React.useState(false);

	const modalClassName = `general-action-modal ${
		big ? 'action-big-modal' : ''
	} ${extraBig ? 'extra-big-modal' : ''} ${
		normal ? 'action-normal-modal' : ''
	} ${hideNormalButton ? 'close--button-space' : ''}`;

	return ReactDOM.createPortal(
		<RavenModal
			className={`${modalClassName} new-class-name`}
			visble={visible}
			onClose={onCancel}
			outerClose={preventOuterClose}
			onBtnClick={() => {}}
		>
			{Boolean(inlineCopyContent) && (
				<div className="inline-copy cursor-pointer">
					<CopyText
						className="copy--text"
						text={formatTableDataForCopy(inlineCopyContent)}
					/>
				</div>
			)}

			<div
				className={`bl-modal white-black-bg${className || ''}`}
				style={style}
			>
				<div className="bl-modal__content hide-scrollbar">
					<h6 className="bl-modal__title">{title}</h6>
					{subTitle && <p className="bl-modal__subtitle">{subTitle}</p>}
					{children ? (
						<div className="action-modal-child">{children}</div>
					) : (
						<p className="bl-modal__body">{body || 'The body goes here'}</p>
					)}
				</div>

				<div className="bl-modal__action-btn-contain">
					{!hideCancel && (
						<RavenButton
							className="cancel-btn"
							color="error-light"
							onClick={altCancelFunc ?? onCancel}
							label={cancelText || 'Cancel'}
						/>
					)}
					{!hideNormalButton && (
						<RavenButton
							className={`action-btn ${btnClassName || ''}`}
							style={{
								backgroundColor: btnColor,
								color: btnTextColor,
								width: hideCancel && !big ? '100%' : '',
							}}
							color="deep-green-light"
							onClick={onClick}
							loading={loading}
							label={actionText || 'Click'}
							disabled={disableButton}
						/>
					)}
				</div>
			</div>
		</RavenModal>,
		portal
	);
};

function extractText(content: any): string {
	if (React.isValidElement(content)) {
		return ReactDOMServer.renderToStaticMarkup(content)
			.replace(/<[^>]+>/g, '')
			.trim();
	}
	return content?.toString() || '--';
}

interface TableData {
	label: string;
	value: any;
}

function formatTableDataForCopy(data: TableData[]): string {
	const rows = data.map(({ label, value }) => [label, extractText(value)]);
	const columnWidths = [
		Math.max(...rows.map(([label]) => label.length)) + 2,
		Math.max(...rows.map(([, value]) => value.length)) + 2,
	];

	return rows
		.map(
			([label, value]) =>
				`${label.padEnd(columnWidths[0])}: ${value.padEnd(columnWidths[1])}`
		)
		.join('\n');
}

export default ActionModal;
