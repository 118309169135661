import React from 'react';
import { ALL_ROUTES } from '../../../../routes/dashboard/overview/AllPathNames';
import { useNavigate } from 'react-router-dom';

interface myComponentProps {
	activeTabIndex?: string;
	setActiveTabIndex?: (param?: any) => void;
}

const ComplianceTabLink = ({
	activeTabIndex,
	setActiveTabIndex,
}: myComponentProps) => {
	const navigate = useNavigate();

	const complianceRoutesList = [
		{
			name: 'BVN Verification',
			active: location?.pathname === ALL_ROUTES?.COMPLIANCE_BVN_VERIFICATION,
			path: ALL_ROUTES?.COMPLIANCE_BVN_VERIFICATION,
			onClick: () => {
				if (activeTabIndex !== ALL_ROUTES?.COMPLIANCE_BVN_VERIFICATION) {
					setActiveTabIndex &&
						setActiveTabIndex(ALL_ROUTES?.COMPLIANCE_BVN_VERIFICATION);
					navigate(ALL_ROUTES?.COMPLIANCE_BVN_VERIFICATION);
				}
			},
		},
		{
			name: 'NIN Verification',
			active: location?.pathname === ALL_ROUTES?.COMPLIANCE_NIN_VERIFICATION,
			path: ALL_ROUTES?.COMPLIANCE_NIN_VERIFICATION,
			// component: <ComplianceNinVerification />,
			onClick: () => {
				if (activeTabIndex !== ALL_ROUTES?.COMPLIANCE_NIN_VERIFICATION) {
					setActiveTabIndex &&
						setActiveTabIndex(ALL_ROUTES?.COMPLIANCE_NIN_VERIFICATION);
					navigate(ALL_ROUTES?.COMPLIANCE_NIN_VERIFICATION);
				}
			},
		},
		{
			name: 'Address Verification',
			active:
				location?.pathname === ALL_ROUTES?.COMPLIANCE_ADDRESS_VERIFICATION,
			path: ALL_ROUTES?.COMPLIANCE_ADDRESS_VERIFICATION,
			// component: <ComplianceAddressVerification />,
			onClick: () => {
				if (activeTabIndex !== ALL_ROUTES?.COMPLIANCE_ADDRESS_VERIFICATION) {
					setActiveTabIndex &&
						setActiveTabIndex(ALL_ROUTES?.COMPLIANCE_ADDRESS_VERIFICATION);
					navigate(ALL_ROUTES?.COMPLIANCE_ADDRESS_VERIFICATION);
				}
			},
		},
		{
			name: 'CAC Verification',
			active: location?.pathname === ALL_ROUTES?.COMPLIANCE_CAC_VERIFICATION,
			path: ALL_ROUTES?.COMPLIANCE_CAC_VERIFICATION,
			// component: <ComplianceCacVerification />,
			onClick: () => {
				if (activeTabIndex !== ALL_ROUTES?.COMPLIANCE_CAC_VERIFICATION) {
					setActiveTabIndex &&
						setActiveTabIndex(ALL_ROUTES?.COMPLIANCE_CAC_VERIFICATION);
					navigate(ALL_ROUTES?.COMPLIANCE_CAC_VERIFICATION);
				}
			},
		},
		// {
		// 	name: 'Liveliness Check',
		// 	active: location?.pathname === ALL_ROUTES?.COMPLIANCE_LIVELINESS_CHECK,
		// 	path: ALL_ROUTES?.COMPLIANCE_LIVELINESS_CHECK,
		// 	// component: <ComplianceLivelinessCheck />,
		// 	onClick: () => {
		// 		if (activeTabIndex !== ALL_ROUTES?.COMPLIANCE_LIVELINESS_CHECK) {
		// 			setActiveTabIndex &&
		// 				setActiveTabIndex(ALL_ROUTES?.COMPLIANCE_LIVELINESS_CHECK);
		// 			navigate(ALL_ROUTES?.COMPLIANCE_LIVELINESS_CHECK);
		// 		}
		// 	},
		// },
	];

	return (
		<>
			<div className="route-path-wrap-box border-theme-bottom">
				{complianceRoutesList.map((tab, idx) => (
					<div
						className={`item-row ${tab?.active && 'item-row-active'}`}
						key={idx}
						onClick={() => {
							if (!tab?.active) {
								tab?.onClick && tab?.onClick();
							}
						}}
					>
						<p className="name grey-white-color">{tab.name}</p>
					</div>
				))}
				{/* <div className="right-box-btn">{btnContent || ''}</div> */}
			</div>
		</>
	);
};

export default ComplianceTabLink;
