import React, { useEffect, useState } from 'react';
import SingleUserLayout from '../../SingleUserLayout';
import {
	RavenNumberFormat,
	RavenTable,
	RavenTableRow,
} from '@ravenpay/raven-bank-ui';
import {
	convertToLowercase,
	formatMetaData,
	trimLongString,
} from '../../../../../../../utils/helper/Helper';
import ImageFragment from '../../../../../../../components/common/ImageFragment';
import Copy from '../../../../../../../components/common/copyCheck';
import DateFragmentBox from '../../../../../../../components/common/DateFragmentBox';
import BadgeComponent from '../../../../../../../components/common/badge/BadgeComponent';
import Pagination from '../../../../../../../components/common/pagination';
import { debounce } from 'lodash';
import SmartFilter from '../../../../../../../components/common/smartFilter';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleBettingRecords } from '../../../../../../../redux/bills';
import RootState from '../../../../../../../redux/types';
import CopyFragment from '../../../../../../../components/common/CopyFragment';
import { bankboxAPI } from '../../../../../../../redux/bankbox';
import EmptyScreen from '../../../../../../../components/common/emptyScreen';

const Betting = [
	{
		name: 'John Doe',
		refrence: 'TRF-2023-145892657',
		email: 'john.doe@example.com',
		provider: 'ProviderName',
		amount: 1500,
		meter_number: '99587375789475783',
		created_at: '2023-01-15T08:30:00',
		status: '0',
	},
	{
		refrence: 'TRF-2023-145892657',
		name: 'Jane Smith',
		email: 'jane.smith@example.com',
		provider: 'AnotherProvider',
		amount: 2500,
		meter_number: '99587375789475783',
		created_at: '2023-02-20T10:00:00',
		status: '1',
	},
];

const SingleBetting = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const dispatch = useDispatch();

	useEffect(() => {
		fetchAllBetting();
	}, [currentPage]);

	const fetchAllBetting = async () => {
		const obj = {
			currentPage: currentPage,
			per_page: 2,
			// search: searchQuery,
		};
		dispatch(getSingleBettingRecords(obj) as any);
	};

	const { loading } = useSelector((state: RootState) => state.bankbox);

	const { bettingRecords, loadingFetch } = useSelector(
		(state: RootState) => state.bills
	);

	const bettingIcon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
			className="img"
		>
			<path
				stroke="black"
				style={{ stroke: 'black', strokeOpacity: 1 }}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M14 2.27V6.4c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437c.214.11.494.11 1.054.11h4.13M16 13H8m8 4H8m2-8H8m6-7H8.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4 4.28 4 5.12 4 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.28 22 7.12 22 8.8 22h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C20 19.72 20 18.88 20 17.2V8z"
			></path>
		</svg>
	);

	return (
		<>
			{/* dispute content start */}
			<div
				style={{ marginBottom: '1.5rem' }}
				className="global-search-pagination-filter-box"
			>
				{' '}
				<SmartFilter
					// hideExport
					searchTitle="Search By Email Address"
					defaultFilterBy="status"
					// filters={[
					// 	{
					// 		label: 'Status',
					// 		filterBy: 'status',
					// 		options: [
					// 			{
					// 				label: 'Pending',
					// 				value: 'pending',
					// 			},
					// 			{
					// 				label: 'Failed',
					// 				value: 'failed',
					// 			},
					// 			{
					// 				label: 'Successful',
					// 				value: 'succesful',
					// 			},
					// 		],
					// 	},
					// ]}
					onExport={() => {
						// 	setShowModal((prev) => {
						// 		return { ...prev, export: true };
						// 	});
					}}
					page="bankbox_transactions"
					onSearchChange={debounce((e) => {
						dispatch(
							bankboxAPI.getAllBankBoxRequest({
								per_page: 20,
								page: currentPage,
								search: e,
							})
						);
					}, 500)}
				/>
				<div className="pagination-wrap">
					{' '}
					<Pagination
						className="top-bar__pagination"
						currentPage={currentPage}
						itemsPerPage={bettingRecords?.pagination?.perPage}
						totalItems={bettingRecords?.pagination?.total}
						onPageChange={function (page: number): void {
							setCurrentPage(page);
						}}
					/>
				</div>
			</div>
			{/* dispute content end */}
			<div
				style={{ minHeight: '45rem' }}
				className="transaction__table non-mobile"
			>
				{bettingRecords?.data?.length < 1 ? (
					<EmptyScreen
						loading={loadingFetch}
						loadingText={
							loadingFetch ? 'Searching...' : 'Loading Betting Records...'
						}
						title="No Betting Records Found"
						subTitle="You don't have any record yet,your new bankbox transactions will be displayed here."
					/>
				) : (
					<div>
						{/* Table Content Start */}
						<div
							style={{ minHeight: '45rem' }}
							className="transaction__table non-mobile"
						>
							<RavenTable
								action={false}
								className="table__main"
								headerList={[
									'TRANSACTION ID',
									'PLATFORM',
									'AMOUNT',
									'USER ID',
									'DATE',
									'STATUS',
								]}
							>
								{bettingRecords?.data?.map((chi: any, idx: any) => (
									<RavenTableRow
										onRowClick={() => {
											// handleRowClick(chi);
											console.log(formatMetaData(chi?.meta_data));
										}}
										loading={loadingFetch}
										key={idx}
										one={trimLongString(chi?.account_id || 'N/A', 15)}
										two={
											<div className="provider_image_and_name">
												<ImageFragment
													className={`img-val-box img-box border-theme`}
													url={`https://personalbanking.getraventest.com/static/media/mobile/${convertToLowercase(
														chi?.platform
													)}.png`}
												>
													<figure className="img-box border-theme img-box-default">
														{bettingIcon}
													</figure>
												</ImageFragment>
												<p className="name">{chi?.platform}</p>
											</div>
										}
										three={RavenNumberFormat(chi?.amount || 0)}
										four={
											<CopyFragment
												text={chi?.account_id}
												trimnumber={20}
												copyText={chi?.account_id || 'N/A'}
											/>
										}
										five={<DateFragmentBox date={chi?.created_at} />}
										six={
											<div className="status_and_modal_holder">
												<BadgeComponent
													text={chi?.status === '1' ? 'Failed' : 'Success'}
													className={chi?.status === '1' ? 'failed' : 'success'}
												/>
											</div>
										}
									/>
								))}
							</RavenTable>
						</div>
						{/* Table Content End */}
					</div>
				)}
			</div>
		</>
	);
};

export default SingleBetting;
