import React, { MouseEventHandler, useEffect, useState } from 'react';
import { icons } from '../../../assets/icons';
import {
	RavenButton,
	RavenCheckBox,
	RavenInputField,
} from '@ravenpay/raven-bank-ui';
import './style/index.css';
import { logger } from '../../../services/logger';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
// import { exportCollections } from '../../../redux/export';
import { formatDate } from '../../../utils/helper/Helper';
// import { omniSearch } from '../../../redux/transaction';

import {
	airtimeFilter,
	bettingFilter,
	cableFilter,
	collectionFilter,
	dataFilter,
	electricityFilter,
	transactionFilter,
	transferFilter,
} from '../../../redux/filters';
import {
	getCollections,
	getCustomers,
	getTransactions,
	getTransfers,
	setWalletLoader,
} from '../../../redux/wallet';
import { act } from '@testing-library/react';
import useDebounce from '../../../hooks/UseDebounce';
import { searchBills } from '../../../redux/bills';
import { bankboxAPI } from '../../../redux/bankbox';
const SmartFilter = ({
	typeList,
	onFilter,
	value,
	simple,
	defaultFilterBy,
	filters,
	filtersTwo,
	searchTitle,
	page,
	hideExport,
	hideFilter,
	onExport,
	onSearchChange,
}: {
	typeList?: any[];
	page?: string;
	defaultFilterBy?: string;
	searchTitle?: string;
	simple?: boolean;
	hideExport?: boolean;
	hideFilter?: boolean;
	onSearchChange?: (param: string) => void;
	filters?: {
		label: string;
		filterBy?: string;
		options: {
			value: string;
			label:
				| string
				| number
				| boolean
				| React.ReactElement<any, string | React.JSXElementConstructor<any>>
				| React.ReactFragment
				| null
				| undefined;
		}[];
	}[];
	filtersTwo?: {
		label: string;
		filterBy?: string;
		options: {
			value: string;
			label:
				| string
				| number
				| boolean
				| React.ReactElement<any, string | React.JSXElementConstructor<any>>
				| React.ReactFragment
				| null
				| undefined;
		}[];
	}[];
	onFilter?: Function;
	onExport?: MouseEventHandler<HTMLParagraphElement> | undefined;
	value?: {
		type: { label: string; value: string }[];
		days: string;
	};
}) => {
	const [activeFilter, setActiveFilter] = useState<{
		type: { label: string; value: string }[];
		region?: string;
		days: string;
	}>({ type: [], days: '' });
	const [activeTab, setActiveTab] = useState('');
	const [showFilter, setShowFilter] = useState(false);
	const [q, setQ] = useState('');
	const [stateChange, setStatChange] = useState(false);
	const [loading, setLoading] = useState(false);
	const [dateRange, setDateRange] = useState<{
		startDate: string | undefined;
		endDate: string | undefined;
		filterValue: string | undefined;
		filterBy?: string | undefined;
	}>({
		startDate: '',
		endDate: '',
		filterValue: undefined,
		filterBy: defaultFilterBy || 'type',
	});

	const dispatch = useDispatch<AppDispatch>();
	const [statusFilter, setStatusFilter] = useState<any>('');
	const [statusFilterTwo, setStatusFilterTwo] = useState<any>('');
	function isFilter(s: string) {
		if (activeTab === 'date' && s === activeFilter.days) {
			return true;
		} else
			return (
				activeFilter.type &&
				activeFilter.type.some((item: { value: string }) => item.value === s)
			);
	}

	const [dateFilter, setDateFilter] = useState<{
		to: Date | any;
		from: Date | any;
	}>({
		from: '',
		to: formatDate(addDays(new Date(), 1)),
	});
	const getDatesInRange = (daysAgo: number) => {
		var dates = [] as any;
		var currentDate = new Date();
		for (var i = 0; i < daysAgo; i++) {
			var date = new Date(currentDate);
			date.setDate(currentDate.getDate() - i);
			dates.push(date.toISOString().slice(0, 10)); // Format date as "YYYY-MM-DD"
		}
		const obj = {
			start: dates[0],
			end: dates[dates?.length - 1],
		};

		return obj;
	};
	function setFilter(e: string) {
		setStatChange(!stateChange);
		if (activeTab === 'date') {
			setActiveFilter({
				...activeFilter,
				days: e,
			});
		} else {
			// Parse the value as an object
			const parsedValue = JSON.parse(e);

			// Check if the value is already in the type array
			const isValueInTypeArray = activeFilter.type.some(
				(item) => item.value === parsedValue.value
			);

			if (isValueInTypeArray) {
				// If the value is in the array, remove it
				setActiveFilter({
					...activeFilter,
					type: activeFilter.type.filter(
						(item) => item.value !== parsedValue.value
					),
				});
			} else {
				// If the value is not in the array, add it
				setActiveFilter({
					...activeFilter,
					type: [...activeFilter.type, parsedValue],
				});
			}
		}
	}
	const [dateVal, setDateVal] = useState<any>('All');
	const getStartAndEndDate = () => {
		let val;
		if (dateVal === 'Today') {
			const today = new Date();
			const year = today.getFullYear();
			const month = today.getMonth() + 1; // Months are zero-based
			const day = today.getDate();

			const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${
				day < 10 ? '0' + day : day
			}`;
			const obj = {
				startDate: formattedDate,
				stopDate: formattedDate,
				range: '',
			};
			val = obj;
		}
		if (
			dateVal === 'Last 30 Days' ||
			dateVal === 'Last 7 Days' ||
			dateVal === 'Last 14 Days'
		) {
			// getDatesInRange(7);
			const obj = {
				// startDate: getDatesInRange(
				// 	dateVal === 'Last 7 Days' ? 7 : dateVal === 'Last 14 Days' ? 14 : 30
				// )?.start,
				// stopDate: getDatesInRange(
				// 	dateVal === 'Last 7 Days' ? 7 : dateVal === 'Last 14 Days' ? 14 : 30
				// )?.end,
				startDate: '',
				stopDate: '',
				range:
					dateVal === 'Last 7 Days' ? 7 : dateVal === 'Last 14 Days' ? 14 : 30,
			};
			val = obj;
		}
		if (dateVal === 'Specific Day' || dateVal === 'Custom') {
			const obj = {
				stopDate: dateRange?.startDate?.replaceAll('/', '-'),
				startDate: dateRange?.endDate?.replaceAll('/', '-'),
				range: '',
			};
			val = obj;
		}
		return val;
	};

	//get the last 7th Day
	function getNthDaysAgo(d: number) {
		const today = new Date(); // Get today's date
		const nthDay = new Date(today); // Create a new date object

		nthDay.setDate(nthDay.getDate() - d); // Subtract nth days

		return nthDay;
	}

	function handleFilter(str?: string) {
		const seventhDayAgo = getNthDaysAgo(7);
		const last14Days = getNthDaysAgo(14);
		const last30Days = getNthDaysAgo(30);

		const formated30days = formatDate(last30Days);
		const formated14days = formatDate(last14Days);
		const formated7days = formatDate(seventhDayAgo);

		setDateRange({
			...activeFilter,
			...dateRange,
			startDate:
				str === 'Today'
					? formatDate(new Date())
					: str === 'Last 7 Days'
					? formated7days
					: str === 'Last 14 Days'
					? formated14days
					: str === 'Last 30 Days'
					? formated30days
					: dateFilter.from,
			endDate:
				str === 'Today'
					? formatDate(addDays(new Date(), 1))
					: str === 'Last 7 Days'
					? formatDate(addDays(new Date(), 1))
					: str === 'Last 14 Days'
					? formatDate(addDays(new Date(), 1))
					: str === 'Last 30 Days'
					? formatDate(addDays(new Date(), 1))
					: dateFilter.to,
			// filterValue: undefined,
		});
	}

	// fetch all based on conditions

	async function fetchAll() {
		setLoading(true);
		let resp: any;
		if (page === 'wallet') {
			resp = await dispatch(
				getTransactions({
					page: 1,
					perPage: 20,
				})
			);
		}
		if (page === 'transfers') {
			resp = await dispatch(
				getTransfers({
					page: 1,
					limit: 20,
				})
			);
		}
		if (page === 'customers') {
			resp = await dispatch(
				getCustomers({
					page: 1,
					perPage: 20,
				})
			);
		}
		if (page === 'bankbox_settlements') {
			resp = await dispatch(
				bankboxAPI.getAllSettlements({
					page: 1,
					per_page: 20,
				})
			);
		}
		if (page === 'collections') {
			resp = await dispatch(
				getCollections({
					page: 1,
					perPage: 20,
				})
			);
		}
		if (page === 'bankbox_users') {
			resp = await dispatch(
				bankboxAPI.getAllUsers({
					page: 1,
					per_page: 20,
				})
			);
		}
		if (page === 'bankbox_transactions') {
			resp = await dispatch(
				bankboxAPI.getAllTransactions({
					page: 1,
					per_page: 20,
				})
			);
		}
		if (page === 'bankbox_terminals') {
			resp = await dispatch(
				bankboxAPI.getAllBankBox({
					page: 1,
					per_page: 20,
				})
			);
		}
		if (page === 'bankbox_requests') {
			resp = await dispatch(
				bankboxAPI.getAllBankBoxRequest({
					page: 1,
					per_page: 20,
				})
			);
		}
		if (page === 'funding') {
			resp = await dispatch(
				getCollections({
					page: 1,
					perPage: 20,
				})
			);
		}

		if (page === 'transaction') {
			resp = dispatch(
				getTransactions({
					page: 1,
					perPage: 20,
				})
			);
		}

		if (resp?.payload?.status === 'success') {
			setLoading(false);
			setShowFilter(false);
		} else {
			setLoading(false);
		}
	}

	useEffect(() => {
		onFilter && onFilter(activeFilter);
	}, [activeFilter, stateChange]);

	function formatQuery(filters: any) {
		let p: string = '';
		activeFilter?.type.map((d: any, i: number) => {
			if (activeFilter.type.length !== i + 1) {
				p = p + d.value + ',';
			} else {
				p = p + d.value;
			}
		});

		return p;
	}

	// increment date function
	function addDays(date: Date | string, days: number) {
		// Function to add Days
		var result = new Date(date);
		result.setDate(result.getDate() + days);

		return result;
	}

	async function reqFilter() {
		let resp: any;
		setLoading(true);
		if (dateVal === 'All' && !statusFilter && !statusFilterTwo) {
			fetchAll();
			return;
		}

		if (
			activeFilter.days.length > 0 ||
			activeFilter.type.length > 0 ||
			statusFilter ||
			statusFilterTwo
		) {
			if (page === 'wallet') {
				resp = await dispatch(
					transactionFilter({
						...dateRange,
						filterValue: formatQuery(activeFilter?.type),
					})
				);
			}
			if (page === 'transaction') {
				resp = await dispatch(
					transactionFilter({
						...dateRange,
						filterValue: formatQuery(activeFilter?.type),
					})
				);
			}
			if (page === 'transfers') {
				resp = await dispatch(
					transferFilter({
						...dateRange,
						filterValue: formatQuery(activeFilter?.type),
					})
				);
			}
			if (page === 'collections') {
				resp = await dispatch(
					collectionFilter({
						...dateRange,
						filterValue: formatQuery(activeFilter?.type),
					})
				);
			}

			if (page === 'funding') {
				resp = await dispatch(
					collectionFilter({
						...dateRange,
						filterValue: formatQuery(activeFilter?.type),
					})
				);
			}
			if (page === 'bankbox_users') {
				resp = await dispatch(
					bankboxAPI.getAllUsers({
						...dateRange,
						start_date: getStartAndEndDate()?.startDate,
						stop_date: getStartAndEndDate()?.stopDate,
						region: formatQuery(activeFilter?.region),
						range: String(getStartAndEndDate()?.range),
					})
				);
			}
			if (page === 'disputes') {
				resp = await dispatch(
					bankboxAPI.getAllDisputes({
						...dateRange,
						start_date: getStartAndEndDate()?.stopDate,
						stop_date: getStartAndEndDate()?.startDate,
						region: formatQuery(activeFilter?.region),
						status: statusFilter?.value,
						range: getStartAndEndDate()?.range,
					})
				);
			}
			if (page === 'BVN') {
				resp = await dispatch(
					bankboxAPI.getAllBVN({
						...dateRange,
						start_date: getStartAndEndDate()?.stopDate,
						stop_date: getStartAndEndDate()?.startDate,
						region: formatQuery(activeFilter?.region),
						status: statusFilter?.value,
						range: String(getStartAndEndDate()?.range),
					})
				);
			}
			if (page === 'NIN') {
				resp = await dispatch(
					bankboxAPI.getAllNIN({
						...dateRange,
						start_date: getStartAndEndDate()?.stopDate,
						stop_date: getStartAndEndDate()?.startDate,
						region: formatQuery(activeFilter?.region),
						status: statusFilter?.value,
						range: String(getStartAndEndDate()?.range),
					})
				);
			}
			if (page === 'CAC') {
				resp = await dispatch(
					bankboxAPI.getAllCAC({
						...dateRange,
						start_date: getStartAndEndDate()?.stopDate,
						stop_date: getStartAndEndDate()?.startDate,
						region: formatQuery(activeFilter?.region),
						status: statusFilter?.value,
						range: String(getStartAndEndDate()?.range),
					})
				);
			}
			if (page === 'Address') {
				resp = await dispatch(
					bankboxAPI.getAllAddress({
						...dateRange,
						start_date: getStartAndEndDate()?.stopDate,
						stop_date: getStartAndEndDate()?.startDate,
						region: formatQuery(activeFilter?.region),
						status: statusFilter?.value,
						range: String(getStartAndEndDate()?.range),
					})
				);
			}
			if (page === 'bankbox_requests') {
				const obj = {
					// ...dateRange,
					start_date: getStartAndEndDate()?.stopDate,
					stop_date: getStartAndEndDate()?.startDate,
					region: formatQuery(activeFilter?.region),
					status: statusFilter?.value,
					range:
						String(getStartAndEndDate()?.range) !== 'undefined'
							? String(getStartAndEndDate()?.range)
							: '',
				};
				// console.log(obj);

				resp = await dispatch(bankboxAPI.getAllBankBoxRequest(obj));
			}
			if (page === 'bankbox_transactions') {
				// let val;
				const obj = {
					...dateRange,
					per_page: 20,
					stop_date: getStartAndEndDate()?.startDate || '',
					start_date: getStartAndEndDate()?.stopDate || '',
					region: formatQuery(activeFilter?.region) || '',
					status:
						statusFilter?.value === 'reset' ? '' : statusFilter?.value || '',
					type:
						statusFilterTwo?.value === 'reset' ? '' : statusFilterTwo?.value,
					range:
						String(getStartAndEndDate()?.range) !== 'undefined'
							? String(getStartAndEndDate()?.range)
							: '',
				};
				// console.log(obj);

				resp = await dispatch(bankboxAPI.getAllTransactions(obj as any));
			}

			if (page === 'bankbox_settlements') {
				// let val;
				const obj = {
					...dateRange,
					per_page: 20,
					stop_date: getStartAndEndDate()?.stopDate || '',
					start_date: getStartAndEndDate()?.startDate || '',
					// region: formatQuery(activeFilter?.region) || '',
					status:
						statusFilter?.value === 'reset' ? '' : statusFilter?.value || '',
					type:
						statusFilterTwo?.value === 'reset' ? '' : statusFilterTwo?.value,
					range:
						String(getStartAndEndDate()?.range) !== 'undefined'
							? String(getStartAndEndDate()?.range)
							: '',
				};
				// console.log(obj);

				resp = await dispatch(bankboxAPI.getAllSettlements(obj as any));
			}

			if (page === 'bankbox_terminals') {
				resp = await dispatch(
					bankboxAPI.getAllBankBox({
						...dateRange,
						stop_date: getStartAndEndDate()?.startDate,
						start_date: getStartAndEndDate()?.stopDate,
						region: formatQuery(activeFilter?.region),
						status: statusFilter?.value === 'reset' ? '' : statusFilter?.value,
						range:
							String(getStartAndEndDate()?.range) !== 'undefined'
								? String(getStartAndEndDate()?.range)
								: '',
					})
				);
			}

			if (page === 'betting') {
				resp = await dispatch(
					bettingFilter({
						...dateRange,
						filterValue: formatQuery(activeFilter?.type),
					})
				);
			}

			if (page === 'airtime') {
				resp = await dispatch(
					airtimeFilter({
						...dateRange,
						filterValue: formatQuery(activeFilter?.type),
					})
				);
			}

			if (page === 'data') {
				resp = await dispatch(
					dataFilter({
						...dateRange,
						filterValue: formatQuery(activeFilter?.type),
					})
				);
			}

			if (page === 'cable') {
				resp = await dispatch(
					cableFilter({
						...dateRange,
						filterValue: formatQuery(activeFilter?.type),
					})
				);
			}

			if (page === 'electricity') {
				resp = await dispatch(
					electricityFilter({
						...dateRange,
						filterValue: formatQuery(activeFilter?.type),
					})
				);
			}
		}

		if (resp?.payload?.status === 'success') {
			setLoading(false);
			setShowFilter(false);
		} else {
			setLoading(false);
		}

		logger.log(resp, 'response');
	}

	// handle search

	// const debouncedSearchTerm = useDebounce(q, 1000);
	// useEffect(() => {
	// 	let isMount = true;
	// 	if (isMount && debouncedSearchTerm?.length >= 2) {
	// 		if (
	// 			page === 'betting' ||
	// 			page === 'airtime' ||
	// 			page === 'data' ||
	// 			page === 'cable' ||
	// 			page === 'electricity'
	// 		) {
	// 			dispatch(searchBills({ type: page, q: q }));
	// 		} else {
	// 			dispatch(setWalletLoader(true));

	// 			const resp = dispatch(omniSearch(q)).then((d) => {
	// 				if (d?.payload?.status === 'success') {
	// 					dispatch(setWalletLoader(false));
	// 				}
	// 			});
	// 		}
	// 	} else {
	// 	}

	// 	if (isMount && debouncedSearchTerm?.length < 1) {
	// 		fetchAll();
	// 	}

	// 	return () => {
	// 		isMount = false;
	// 	};
	// }, [debouncedSearchTerm]);

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const query = searchParams.get('q');

		// Use the paramValue as needed
		setQ(query ? query : '');
	}, []);

	// const debouncedSearch = debounce(onSearchChange, 500)
	return (
		<div className="smartfilter" style={{ position: 'relative', zIndex: 100 }}>
			<div className="top-bar__search">
				<RavenInputField
					placeholder={searchTitle || 'Search refs, sessions...'}
					type="search"
					value={q}
					onChange={(e: {
						target: { value: React.SetStateAction<string> };
					}) => {
						setQ(e.target.value);
						onSearchChange && onSearchChange(e.target.value as string);
					}}
					color="deep-green-light"
				/>
			</div>

			<div className="top-bar__filter-export">
				{!hideFilter && (
					<div
						onClick={() => setShowFilter(!showFilter)}
						className="filter-export__filter-btn"
					>
						{!simple && <p>Filter</p>} <figure>{icons.filter}</figure>
					</div>
				)}

				{/* Section encompassing filter and it controls */}
				{showFilter && (
					<div
						className={`filter-export__drop-down filter-export__drop-down--open `}
					>
						<div className="filter-export__drop-down-wrap">
							<div className="dropdown__header">
								<p>Filter</p>
								{(statusFilter || activeFilter?.days) && (
									<p
										onClick={() => {
											setDateVal('');
											setStatusFilter('');
											setStatusFilter('');
											setShowFilter(false);
											setActiveFilter({
												days: '',
												type: [],
											});
											fetchAll();
										}}
									>
										Clear All
									</p>
								)}
							</div>
							<div
								onClick={() => {
									activeTab === 'date'
										? setActiveTab('empty')
										: setActiveTab('date');
								}}
								className="dropdown__date-title"
							>
								<p>Date</p>

								<div
									onClick={() => {
										activeTab === 'type'
											? setActiveTab('type')
											: activeTab === 'date'
											? setActiveTab('date')
											: setActiveTab('empty');
										setActiveFilter({
											...activeFilter,
											days: '',
										});
									}}
									className={`dropdown__tags ${activeFilter?.days && 'show'}`}
								>
									{' '}
									<p>{activeFilter?.days}</p>
									<figure>{icons.x_circle}</figure>
								</div>

								<div
									className={`tag-close-icon ${activeFilter?.days && 'hide'}`}
								>
									<figure>{icons.chevron_down}</figure>
								</div>
							</div>

							<div
								className={`drop-down__item ${
									activeTab === 'date' && 'active'
								}`}
							>
								<span
									onClick={() => {
										setFilter('All');
										handleFilter('All');
										setDateVal('All');
									}}
									className={`${isFilter('All') && 'active'}`}
								>
									{isFilter('All') ? (
										<figure>{icons.radio_check}</figure>
									) : (
										<figure>{icons.radio_unchecked}</figure>
									)}
									<p>All</p>
								</span>
								<span
									onClick={() => {
										setFilter('Today');
										handleFilter('Today');
										setDateVal('Today');
									}}
									className={`${isFilter('Today') && 'active'}`}
								>
									{isFilter('Today') ? (
										<figure>{icons.radio_check}</figure>
									) : (
										<figure>{icons.radio_unchecked}</figure>
									)}
									<p>Today</p>
								</span>
								<span
									onClick={() => {
										setFilter('Last 7 Days');
										handleFilter('Last 7 Days');
										setDateVal('Last 7 Days');
									}}
									className={`${isFilter('Last 7 Days') && 'active'} `}
								>
									{isFilter('Last 7 Days') ? (
										<figure>{icons.radio_check}</figure>
									) : (
										<figure>{icons.radio_unchecked}</figure>
									)}
									<p>Last 7 Days</p>
								</span>
								<span
									onClick={() => {
										setFilter('Last 30 Days');
										handleFilter('Last 30 Days');
										setDateVal('Last 30 Days');
									}}
									className={`${isFilter('Last 30 Days') && 'active'}`}
								>
									{isFilter('Last 30 Days') ? (
										<figure>{icons.radio_check}</figure>
									) : (
										<figure>{icons.radio_unchecked}</figure>
									)}
									<p>Last 30 Days</p>
								</span>
								<span
									onClick={() => {
										setFilter('Specific Day');
										handleFilter('Specific Day');
										setDateVal('Specific Day');
									}}
									className={`custom-date ${
										isFilter('Specific Day') && 'active '
									}`}
								>
									<div className="custom-date__select">
										{isFilter('Specific Day') ? (
											<figure>{icons.radio_check}</figure>
										) : (
											<figure>{icons.radio_unchecked}</figure>
										)}
										<p>Specific Date</p>
									</div>

									<div
										className={`custom-date__form-wrap ${
											isFilter('Specific Day') && 'show'
										}`}
									>
										<RavenInputField
											color="deep-green-light"
											label="date"
											onChange={(e: Date) => {
												setDateFilter({
													from: formatDate(new Date(e)),
													to: formatDate(addDays(new Date(), 1)),
												});
												setDateRange({
													startDate: formatDate(new Date(e)),
													endDate: formatDate(new Date(e)),
													filterValue: undefined,
												});
											}}
											placeholder="Select a date"
											type="date"
										/>
									</div>
								</span>
								<span
									onClick={() => {
										setFilter('Custom');
										handleFilter('Custom');
										setDateVal('Custom');
									}}
									className={`custom-date ${isFilter('Custom') && 'active '}`}
								>
									<div className="custom-date__select">
										{isFilter('Custom') ? (
											<figure>{icons.radio_check}</figure>
										) : (
											<figure>{icons.radio_unchecked}</figure>
										)}
										<p>Custom</p>
									</div>

									<div
										className={`custom-date__form-wrap ${
											isFilter('Custom') && 'show'
										}`}
									>
										<RavenInputField
											color="deep-green-light"
											label="Start Date"
											placeholder="Select a date"
											type="date"
											onChange={(e: Date) => {
												setDateFilter({
													...dateFilter,
													from: formatDate(new Date(e)),
												});
												setDateRange({
													...dateRange,
													startDate: formatDate(new Date(e)),
												});
											}}
											dateOptions={{ maxDate: new Date() }}
										/>
										<RavenInputField
											color="deep-green-light"
											label="End Date"
											placeholder="Select a date"
											onChange={(e: Date) => {
												setDateFilter({
													...dateFilter,
													to: formatDate(new Date(e)),
												}),
													setDateRange({
														...dateRange,
														endDate: formatDate(new Date(e)),
													});
											}}
											disabled={!dateRange?.startDate}
											type="date"
											dateOptions={{
												maxDate: new Date(),
												minDate: dateRange?.startDate,
											}}
										/>
									</div>
								</span>
							</div>

							{typeList && (
								<>
									<div
										onClick={() => {
											activeTab === 'type'
												? setActiveTab('empty')
												: setActiveTab('type');
										}}
										className="dropdown__date-title"
									>
										<p>Type</p>

										<div
											onClick={() => {
												activeTab === 'type'
													? setActiveTab('type')
													: activeTab === 'date'
													? setActiveTab('date')
													: setActiveTab('empty');
												setActiveFilter({
													...activeFilter,
													type: [],
												});
											}}
											className={`dropdown__tags ${
												activeFilter?.type.length > 0 && 'show'
											}`}
										>
											{activeFilter.type.length === 1 ? (
												<p>{activeFilter?.type[0]?.label}</p>
											) : (
												<p>{activeFilter?.type.length + ' Selected'}</p>
											)}

											<figure>{icons.x_circle}</figure>
										</div>

										<div
											className={`tag-close-icon ${
												activeFilter?.type.length > 0 && 'hide'
											}`}
										>
											<figure>{icons.chevron_down}</figure>
										</div>
									</div>

									{typeList &&
										typeList.map((chi, idx) => {
											return (
												<div
													className={`drop-down__item ${
														activeTab === 'type' && 'active'
													}`}
												>
													<span
														onClick={() => setFilter(JSON.stringify(chi))}
														className={`${isFilter(chi.value) && 'active'}`}
													>
														<RavenCheckBox
															onChange={(e) => {
																handleFilter(chi.label);
																setFilter(JSON.stringify(chi));
																// console.log(chi);
															}}
															checked={isFilter(chi.value)}
															color={'deep-green-light'}
															id={idx}
														/>

														<p>{chi.label}</p>
													</span>
												</div>
											);
										})}
								</>
							)}
							{filters &&
								filters.map((chi, idx) => {
									// logger.log(chi, 'fl');
									return (
										<React.Fragment key={idx}>
											<div
												onClick={() => {
													if (chi?.filterBy) {
														setDateRange({
															...dateRange,
															filterBy: chi.filterBy,
														});
													}
													activeTab === 'type'
														? setActiveTab('empty')
														: setActiveTab('type');
												}}
												className="dropdown__date-title"
											>
												<p>{chi?.label}</p>

												{statusFilter && (
													<div
														onClick={() => {
															activeTab === 'type'
																? setActiveTab('type')
																: activeTab === 'date'
																? setActiveTab('date')
																: setActiveTab('empty');
															setActiveFilter({
																...activeFilter,
																type: [],
															});
														}}
														className={`dropdown__tags ${
															statusFilter && 'show'
														}`}
													>
														{/* {activeFilter.type.length === 1 ? (
														<p>{activeFilter?.type[0]?.label}</p>
													) : (
														<p>{activeFilter?.type.length + ' Selected'}</p>
													)} */}
														<p>{statusFilter?.label}</p>

														<figure
															onClick={() => {
																setStatusFilter('');
															}}
														>
															{icons.x_circle}
														</figure>
													</div>
												)}

												<div
													className={`tag-close-icon ${statusFilter && 'hide'}`}
												>
													<figure>{icons.chevron_down}</figure>
												</div>
											</div>

											{chi?.options &&
												chi?.options?.map(
													(
														chi: {
															filterBy?: string;
															value: string;
															label:
																| string
																| number
																| boolean
																| React.ReactElement<
																		any,
																		string | React.JSXElementConstructor<any>
																  >
																| React.ReactFragment
																| null
																| undefined;
														},
														idx: number
													) => {
														return (
															<div
																className={`drop-down__item ${
																	activeTab === 'type' && 'active'
																}`}
															>
																<div
																	// onClick={() => {
																	// 	setFilter(JSON.stringify(chi));
																	// 	statusFilter?.label === chi?.label
																	// 		? setStatusFilter('')
																	// 		: setStatusFilter(chi);
																	// }}
																	className={`span ${
																		isFilter(chi.value) && 'active'
																	}`}
																>
																	<RavenCheckBox
																		onChange={(e) => {
																			handleFilter(chi.label as string);
																			setFilter(JSON.stringify(chi));
																			statusFilter?.label === chi?.label
																				? setStatusFilter('')
																				: setStatusFilter(chi);
																		}}
																		checked={statusFilter?.value === chi?.value}
																		color={'deep-green-light'}
																		id={idx}
																	/>

																	<label
																		style={{ cursor: 'pointer' }}
																		htmlFor={`input-check-${idx}`}
																	>
																		{chi.label}
																	</label>
																</div>
															</div>
														);
													}
												)}
										</React.Fragment>
									);
								})}

							{filtersTwo &&
								filtersTwo.map((chi, idx) => {
									// logger.log(chi, 'fl');
									return (
										<React.Fragment key={idx}>
											<div
												onClick={() => {
													if (chi?.filterBy) {
														setDateRange({
															...dateRange,
															filterBy: chi.filterBy,
														});
													}
													activeTab === 'typeTwo'
														? setActiveTab('empty')
														: setActiveTab('typeTwo');
												}}
												className="dropdown__date-title"
											>
												<p>{chi?.label}</p>

												{statusFilterTwo && (
													<div
														onClick={() => {
															activeTab === 'typeTwo'
																? setActiveTab('typeTwo')
																: activeTab === 'date'
																? setActiveTab('date')
																: setActiveTab('empty');
															setActiveFilter({
																...activeFilter,
																type: [],
															});
														}}
														className={`dropdown__tags ${
															statusFilterTwo && 'show'
														}`}
													>
														{/* {activeFilter.type.length === 1 ? (
														<p>{activeFilter?.type[0]?.label}</p>
													) : (
														<p>{activeFilter?.type.length + ' Selected'}</p>
													)} */}
														<p>{statusFilterTwo?.label}</p>

														<figure
															onClick={() => {
																setStatusFilterTwo('');
															}}
														>
															{icons.x_circle}
														</figure>
													</div>
												)}

												<div
													className={`tag-close-icon ${
														statusFilterTwo && 'hide'
													}`}
												>
													<figure>{icons.chevron_down}</figure>
												</div>
											</div>

											{chi?.options &&
												chi?.options?.map(
													(
														chi: {
															filterBy?: string;
															value: string;
															label:
																| string
																| number
																| boolean
																| React.ReactElement<
																		any,
																		string | React.JSXElementConstructor<any>
																  >
																| React.ReactFragment
																| null
																| undefined;
														},
														idx: number
													) => {
														return (
															<div
																className={`drop-down__item ${
																	activeTab === 'typeTwo' && 'active'
																}`}
															>
																<div
																	// onClick={() => {
																	// 	setFilter(JSON.stringify(chi));
																	// 	statusFilter?.label === chi?.label
																	// 		? setStatusFilter('')
																	// 		: setStatusFilter(chi);
																	// }}
																	className={`span ${
																		isFilter(chi.value) && 'active'
																	}`}
																>
																	<RavenCheckBox
																		onChange={(e) => {
																			// console.log(e);

																			handleFilter(chi.label as string);
																			setFilter(JSON.stringify(chi));
																			statusFilterTwo?.label === chi?.label
																				? setStatusFilterTwo('')
																				: setStatusFilterTwo(chi);
																		}}
																		checked={
																			statusFilterTwo?.value === chi?.value
																		}
																		color={'deep-green-light'}
																		id={`${idx}-${chi?.value}` as any}
																	/>

																	<label
																		style={{ cursor: 'pointer' }}
																		htmlFor={`input-check-${idx}-${chi?.value}`}
																	>
																		{chi.label}
																	</label>
																</div>
															</div>
														);
													}
												)}
										</React.Fragment>
									);
								})}
						</div>

						{/* apply filter button */}
						<div className="dropdown__apply-filter-btn">
							<RavenButton
								label="Apply Filter"
								color="deep-green-light"
								onClick={reqFilter}
								loading={loading}
								// disabled={!activeFilter?.days ? true : (filters?.length as any) > 0 && !statusFilter ? true : false }
							/>
						</div>
						{/* apply filter ends here */}
					</div>
				)}

				{/* Outer close wrap for filters */}
				<div
					onClick={() => setShowFilter(false)}
					className={`filter-export__drop-down-closer ${
						showFilter && 'filter-export__drop-down-closer--opened'
					}`}
				></div>
				{/* End of Section encompassing filter and it controls */}

				{!hideExport && (
					<div
						onClick={onExport}
						// onClick={() => onView(!showFilter)}
						className="filter-export__filter-btn"
					>
						{!simple && <p>Export</p>} <figure>{icons.export}</figure>
					</div>
				)}
			</div>
		</div>
	);
};

export default SmartFilter;
