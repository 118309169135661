import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../layout/dashboard/dashboardLayout';
import PageLayout from '../../../../layout/dashboard/tableLayout';
import {
	RavenButton,
	RavenInputField,
	RavenModal,
	RavenTable,
	RavenTableRow,
	RavenToggleSlide,
	RavenToolTip,
	toast,
} from '@ravenpay/raven-bank-ui';
import {
	capitalizeAllLetter,
	capitalizeFirstLetter,
	formatDateTime,
	getActionNamesByPolicyName,
	insertUrlParam,
	reactSelectStyle,
	removeUrlParam,
	returnInitial,
	trimLongString,
	validateStringForTerminalId,
} from '../../../../utils/helper/Helper';
import Pagination from '../../../../components/common/pagination';
import EmptyScreen from '../../../../components/common/emptyScreen';
import './styles/index.css';
import { generateRandomColor } from '../../../../utils/helper/color';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SmartFilter from '../../../../components/common/smartFilter';
import ExportModal from '../../../../components/common/exportModal';
import MobileTableCard from '../../../../components/common/table/mobile/MobileTable';
import RootState, {
	AssignBankBoxPayload,
	BankBoxUser,
} from '../../../../redux/types';
import { icons } from '../../../../assets/icons';
import BankBoxUserPreview from './component/UserPreview';
import CreateBankboxUser from '../components/modals/CreateBankboxUser';
import { bankboxAPI } from '../../../../redux/bankbox';
import { debounce, size } from 'lodash';
import { FaRegEye } from 'react-icons/fa';
import UpdateBankboxUserModal from '../components/modals/UpdateBankboxUserModal';
import UpdateMerchantFeeUserModal from '../components/modals/UpdateFeeMerchantModal';
import MyComponent from './TestScan';
import CameraAssignBox from './cameraAssign/CameraAssignBox';
import WebcamCapture from './cameraAssign/webcam';
import { useOnClickOutside } from '../../../../utils/helper/UseOnClickOutside';
import useWindowSize from '../../../../hooks/UseWindowSize';
import DateFragmentBox from '../../../../components/common/DateFragmentBox';
import { ALL_ROUTES } from '../../../../routes/dashboard/overview/AllPathNames';

const cameraIcon = (
	<svg
		className="img"
		width="18"
		height="19"
		viewBox="0 0 18 19"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_16594_47752)">
			<path
				d="M5.97578 16.0249V5.64153C5.97578 4.9518 5.97578 4.60694 6.05209 4.32399C6.25917 3.55616 6.86282 2.95642 7.63564 2.75068C7.92043 2.67487 8.26754 2.67487 8.96176 2.67487C9.65597 2.67487 10.0031 2.67487 10.2879 2.75068C11.0607 2.95642 11.6643 3.55616 11.8714 4.32399C11.9477 4.60694 11.9477 4.9518 11.9477 5.64153V10.4624M11.9477 13.429V16.0249M5.07999 16.0249H12.8435C14.0977 16.0249 14.7249 16.0249 15.2039 15.7824C15.6253 15.569 15.9679 15.2287 16.1826 14.81C16.4267 14.334 16.4267 13.711 16.4267 12.4649V9.20153C16.4267 7.95542 16.4267 7.33236 16.1826 6.8564C15.9679 6.43774 15.6253 6.09736 15.2039 5.88404C14.7249 5.64153 14.0977 5.64153 12.8435 5.64153H5.07999C3.82577 5.64153 3.19865 5.64153 2.7196 5.88404C2.29822 6.09736 1.95562 6.43774 1.74091 6.8564C1.49683 7.33236 1.49683 7.95542 1.49683 9.20153V12.4649C1.49683 13.711 1.49683 14.334 1.74091 14.81C1.95562 15.2287 2.29822 15.569 2.7196 15.7824C3.19865 16.0249 3.82577 16.0249 5.07999 16.0249ZM9.55895 13.429H13.2168C13.4258 13.429 13.5303 13.429 13.6102 13.3886C13.6804 13.3531 13.7375 13.2963 13.7733 13.2266C13.814 13.1472 13.814 13.0434 13.814 12.8357V11.0557C13.814 10.848 13.814 10.7442 13.7733 10.6648C13.7375 10.5951 13.6804 10.5383 13.6102 10.5028C13.5303 10.4624 13.4258 10.4624 13.2168 10.4624H9.55895C9.34991 10.4624 9.24539 10.4624 9.16555 10.5028C9.09532 10.5383 9.03822 10.5951 9.00244 10.6648C8.96176 10.7442 8.96176 10.848 8.96176 11.0557V12.8357C8.96176 13.0434 8.96176 13.1472 9.00244 13.2266C9.03822 13.2963 9.09532 13.3531 9.16555 13.3886C9.24539 13.429 9.34991 13.429 9.55895 13.429Z"
				stroke="black"
				strokeWidth="1.335"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_16594_47752">
				<rect
					width="17.9158"
					height="17.8"
					fill="white"
					transform="translate(0.00390625 0.44986)"
				/>
			</clipPath>
		</defs>
	</svg>
);

const BankBoxUsers = () => {
	const [showExport, setShowExport] = useState<boolean>(false);
	const [assignFormData, setAssignFormData] = useState<AssignBankBoxPayload>({
		// affiliate_app_id: '',
		poseidon_business_email: '',
		serial_number: '',
		label: '',
		poseidon_business_name: '',
		exist_nibbs: true,
		// term_id: '',
		// auto_gen: true,
		auto_generate: true,
		is_nibss: true,
		terminal_id: '',
		device_type: '',
	});
	const [uploadViaCamera, setUploadViaCamera] = useState(false);
	const [assignBankbox, setAssignBankbox] = useState<{
		on: boolean;
		chi: BankBoxUser;
	}>({
		on: false,
		chi: {
			id: 0,
			fname: '',
			lname: '',
			poseidon_email: '',
			bvn: '',
			nin: '',
			phone: '',
			affiliate_app: '',
			affiliate_app_id: '',
			poseidon_business_name: '',
			poseidon_business_address: '',
			poseidon_business_type: null,
			poseidon_business_description: '',
			poseidon_profile_reference: '',
			business_category: '',
			region: '',
			created_at: '',
			updated_at: '',
			lga: '',
		},
	});
	const [currentPage, setCurrentPage] = useState(1);
	const [activeFilter, setActiveFilter] = useState<string>('');
	const [viewUser, setViewUser] = useState<{ chi: BankBoxUser; on: boolean }>({
		on: false,
		chi: {
			id: 0,
			lga: '',
			fname: '',
			lname: '',
			poseidon_email: '',
			bvn: '',
			nin: '',
			phone: '',
			affiliate_app: '',
			affiliate_app_id: '',
			poseidon_business_name: '',
			poseidon_business_address: '',
			poseidon_business_type: null,
			poseidon_business_description: '',
			poseidon_profile_reference: '',
			business_category: '',
			region: '',
			created_at: '',
			updated_at: '',
		},
	});

	// console.log(assignFormData);
	useState<string>('business-account');
	const [dateFilter, setDateFilter] = useState<{
		to: Date | any;
		from: Date | any;
	}>({
		from: '',
		to: '',
	});

	const [createModal, setCreateModal] = useState<boolean>(false);

	const { all_states, all_lga } = useSelector(
		(state: RootState) => state.bankbox
	);
	const [editMerchantModal, setEditMerchantModal] = useState<boolean>(false);
	const [editMerchantFeeModal, setEditMerchantFeeModal] =
		useState<boolean>(false);

	// Generate 10 objects with fake data for the table rows

	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	// const {  } = useSelector((state: RootState) => state.bankbox);

	const { all_fees, users, loading, user } = useSelector(
		(state: RootState) => state.bankbox
	);
	function isFilter(value: string) {
		if (value === activeFilter) return true;
		else return false;
	}

	useEffect(() => {
		if (
			typeof dateFilter.from === 'object' ||
			typeof dateFilter.to === 'object'
		) {
			setDateFilter({
				to: '',
				from: '',
			});
		}
	}, [activeFilter]);

	useEffect(() => {
		if (typeof activeFilter !== undefined) {
			setActiveFilter('');
		}
	}, [dateFilter.to || dateFilter.from]);

	const [noDataFound, setNoDataFound] = useState<boolean>(true);
	useEffect(() => {
		getAllUserFirst();
	}, [currentPage, viewUser]);

	const getAllUserFirst = async () => {
		const data = await dispatch(
			bankboxAPI.getAllUsers({
				per_page: 20,
				page: currentPage,
			})
		);
		if (data?.payload?.status === 'success') {
			const checkLength = data?.payload?.data?.data?.data;
			if (checkLength?.length > 0) {
				setNoDataFound(false);
				// console.log("ooo");
			} else {
				setNoDataFound(true);
			}
		}
	};

	useEffect(() => {
		dispatch(bankboxAPI.getBankboxStates({}));
	}, []);

	React.useEffect(() => {
		getFirstFetch();
	}, []);
	const getFirstFetch = async () => {
		dispatch(
			bankboxAPI.getAllFeesWithoutPagination({
				per_page: 20,
				page: 1,
				// affiliate_app_id:
			})
		);
	};

	const cust = users?.data;

	const handleViewUser = (chi: BankBoxUser) => {
		// console.log(chi);

		localStorage?.setItem('merchant-email-user-preview', chi.poseidon_email);
		navigate(ALL_ROUTES?.SINGLE_MERCHANT);
		// navigate(
		// 	ALL_ROUTES?.BANKBOXUSER_PREVIEW
		// );
	};

	const searchParams = new URLSearchParams(window.location.search);
	const val = searchParams.get('user_email');

	let url = window.location.href;

	document.body.addEventListener(
		'click',
		() => {
			requestAnimationFrame(() => {
				if (url !== window.location.href) {
					// logger.log('url changed');
					url = window.location.href;
				}
			});
		},
		true
	);

	const [singleChild, setSingleChild] = useState<any>({});
	const [terminalDetails, setTerminalDetails] = useState<any>({});
	const [singleChildFee, setSingleChildFee] = useState<any>({});
	useEffect(() => {
		const d = async () => {
			if (val) {
				setViewUser({
					...viewUser,
					on: true,
				});
			} else {
				setViewUser({
					...viewUser,
					on: false,
				});
			}
		};

		d();
	}, [location, val, url]);

	useEffect(() => {
		setAssignFormData({
			...assignFormData,
			poseidon_business_email: assignBankbox?.chi?.poseidon_email,
			poseidon_business_name: assignBankbox?.chi?.poseidon_business_name,
		});
	}, [assignBankbox]);

	const [showTerminalError, setShowTerminalError] = useState(false);
	async function handleAssign() {
		if (
			!validateStringForTerminalId(assignFormData?.terminal_id) &&
			!autoAssign
		) {
			toast.error(
				'Terminal ID must be exactly 8 characters, all capitalized and alphanumeric.'
			);
			setShowTerminalError(true);
			return;
		}

		const obj = {
			...assignFormData,
			auto_generate: autoAssign,
			is_nibss: autoAssign ? false : assignFormData?.exist_nibbs,
			terminal_id: assignFormData?.terminal_id,
			state: details?.state?.value,
			city: details?.lga,
			// poseidon_business_name: user_merchant?.user?.poseidon_business_name,
			// poseidon_business_email: user_merchant?.user?.poseidon_email,
		};
		// console.log(obj);

		const resp = await dispatch(bankboxAPI.assignBankbox(obj));

		if (resp.payload?.status === 'success') {
			setAssignBankbox({
				...assignBankbox,
				on: false,
			});
			const email = searchParams.get('user_email');
			dispatch(bankboxAPI.getSingleUser({ email: email as string }));
			setDetails({ lga: '', state: '' });
			setAssignFormData({
				exist_nibbs: true,
				// affiliate_app_id: '',
				poseidon_business_email: '',
				serial_number: '',
				label: '',
				poseidon_business_name: '',
				auto_generate: false,
				is_nibss: true,
				terminal_id: '',
				device_type: '',
			});
		}
	}

	const cameraUploadIcon = (
		<svg
			className="img"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				className="dull-bright-filter"
				d="M6 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V6M6 19H5.8C4.11984 19 3.27976 19 2.63803 18.673C2.07354 18.3854 1.6146 17.9265 1.32698 17.362C1 16.7202 1 15.8802 1 14.2V14M19 6V5.8C19 4.11984 19 3.27976 18.673 2.63803C18.3854 2.07354 17.9265 1.6146 17.362 1.32698C16.7202 1 15.8802 1 14.2 1H14M19 14V14.2C19 15.8802 19 16.7202 18.673 17.362C18.3854 17.9265 17.9265 18.3854 17.362 18.673C16.7202 19 15.8802 19 14.2 19H14M1 10H1.01M5.5 10H5.51M14.5 10H14.51M10 10H10.01M19 10H19.01"
				stroke="black"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);

	const infoIcon = (
		<svg
			className="img"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_164_20412)">
				<path
					d="M7.99967 10.6668V8.00016M7.99967 5.3335H8.00634M14.6663 8.00016C14.6663 11.6821 11.6816 14.6668 7.99967 14.6668C4.31778 14.6668 1.33301 11.6821 1.33301 8.00016C1.33301 4.31826 4.31778 1.3335 7.99967 1.3335C11.6816 1.3335 14.6663 4.31826 14.6663 8.00016Z"
					stroke="black"
					strokeWidth="1.2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_164_20412">
					<rect width="16" height="16" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);

	const [autoAssign, setAutoAssign] = useState(true);
	const [selectedType, setSelectedType] = useState('Yes, It is');
	const selectTypeList = ['Yes, It is', 'No, it’s not'];

	const [showDrop, setShowDrop] = useState<{
		show: boolean;
		id: number | string;
	}>({
		show: false,
		id: '',
	});

	interface formComponent {
		state: any;
		lga: any;
	}

	const [details, setDetails] = useState<formComponent>({
		state: '',
		lga: '',
	});
	// const dispatch = useDispatch()
	const size = useWindowSize();
	const handleChange = (e: any) => {
		const { name, value } = e.target;
		const obj = { ...details, [name]: value };
		setDetails(obj);
	};

	const formatState = (param: any) => {
		if (param?.length > 0) {
			const newList = param?.map((chi: any) => {
				return { label: chi, value: chi };
			});
			return newList;
		}
	};

	// // Hook to detect clicks outside the component
	// useOnClickOutside(handleOutsideClick);

	function DropMore({ className, idx, chi, ref }: any) {
		return (
			<div key={idx} className={`more-wrap ${className}`}>
				{/* {chi?.status === 1 && ( */}
				<div
					style={{
						opacity: '1',
						cursor: 'pointer',
					}}
					onClick={() => {
						handleViewUser(singleChild);
						// setSingleChild(singleChild)
					}}
					className="more_item"
					ref={ref}
				>
					<figure>
						<FaRegEye />
					</figure>
					<p>View Merchant</p>
				</div>
				{/* )} */}

				{getActionNamesByPolicyName(
					profile?.rolePolicies?.policies,
					'merchants'
				)?.includes('edit_merchant') && (
					<div
						style={{
							opacity: '1',
							cursor: 'pointer',
						}}
						onClick={() => setEditMerchantModal(true)}
						className="more_item"
					>
						<figure>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								className="img"
							>
								<path
									d="M21 18.0002L19.9999 19.0943C19.4695 19.6744 18.7501 20.0002 18.0001 20.0002C17.2501 20.0002 16.5308 19.6744 16.0004 19.0943C15.4692 18.5154 14.75 18.1903 14.0002 18.1903C13.2504 18.1903 12.5311 18.5154 12 19.0943M3 20.0002H4.67454C5.16372 20.0002 5.40832 20.0002 5.63849 19.945C5.84256 19.896 6.03765 19.8152 6.2166 19.7055C6.41843 19.5818 6.59138 19.4089 6.93729 19.063L19.5 6.50023C20.3285 5.6718 20.3285 4.32865 19.5 3.50023C18.6716 2.6718 17.3285 2.6718 16.5 3.50023L3.93726 16.063C3.59136 16.4089 3.4184 16.5818 3.29472 16.7837C3.18506 16.9626 3.10425 17.1577 3.05526 17.3618C3 17.5919 3 17.8365 3 18.3257V20.0002Z"
									stroke="black"
									strokeWidth="1.2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</figure>
						<p>Edit Merchant</p>
					</div>
				)}
			</div>
		);
	}

	function isDropped(idx: number | string) {
		if (showDrop.id === idx && showDrop.show) return true;
		else return false;
	}

	useEffect(() => {
		if (Object?.keys(user)?.length > 0) {
			// console.log(user?.region);
			setDetails((prev) => {
				return {
					...prev,
					state: { label: user?.region, value: user?.region },
					lga: user?.lga,
				};
			});
		}
	}, [user]);

	const { profile } = useSelector((state: RootState) => state.settings);
	if (
		!getActionNamesByPolicyName(
			profile?.rolePolicies?.policies,
			'merchants'
		)?.includes('view_merchants')
	) {
		return (
			<DashboardLayout>
				<>
					<EmptyScreen
						loading={Object?.keys(profile)?.length < 1 ? true : false}
						title="Permission Denied"
						subTitle="You have no permission to access this page, Please contact admin or support."
					></EmptyScreen>
				</>
			</DashboardLayout>
		);
	}

	return (
		<>
			<DashboardLayout>
				<PageLayout
					pageSubtitle="Manage merchants making use of the BankBox"
					pageTitle="BankBox Merchants"
					topRightContent={
						!noDataFound &&
						getActionNamesByPolicyName(
							profile?.rolePolicies?.policies,
							'merchants'
						)?.includes('add_merchant') ? (
							<RavenButton
								onClick={() => setCreateModal(true)}
								color="deep-green-light"
								size="small"
							>
								<p>Create Merchant</p>
								<figure className="non-mobile">
									{icons.plus_circle_white}
								</figure>
								<figure className="non-desktop">{icons.plus}</figure>
							</RavenButton>
						) : (
							<></>
						)
					}
				>
					{!noDataFound && (
						<div
							onClick={() => {
								setShowDrop({
									show: false,
									id: '',
								});
							}}
							className="global-search-pagination-filter-box"
						>
							{!noDataFound && (
								<SmartFilter
									searchTitle="Search by name and email"
									page="bankbox_users"
									onExport={() => setShowExport(true)}
									onSearchChange={debounce((e) => {
										// console.log(e);
										dispatch(
											bankboxAPI.getAllUsers({
												per_page: 20,
												page: currentPage,
												search: e,
											})
										);
									}, 500)}
								/>
							)}

							{!noDataFound && (
								<Pagination
									className="top-bar__pagination"
									currentPage={currentPage ?? 0}
									itemsPerPage={users?.perPage ?? 0}
									totalItems={users?.total ?? 0}
									onPageChange={function (page: number): void {
										setCurrentPage(page);
									}}
								/>
							)}
						</div>
					)}

					<div
						onClick={() => {
							setShowDrop({
								show: false,
								id: '',
							});
						}}
						className="content__main"
					>
						<div className="main__wrap">
							{cust?.length < 1 ? (
								<EmptyScreen
									title="No Users Found"
									subTitle="We couldn't find any user on this bankbox account, your new users will appear here."
									loading={loading}
									button
									loadingText="Loading users..."
									onClick={() => setCreateModal(true)}
									buttonLabel={
										getActionNamesByPolicyName(
											profile?.rolePolicies?.policies,
											'merchants'
										)?.includes('add_merchant')
											? 'Create Merchant'
											: ''
									}
									btnIcon={false}
								/>
							) : (
								<>
									<div
										onClick={() => {
											setShowDrop({
												show: false,
												id: '',
											});
										}}
										className="transaction__table non-mobile"
									>
										<RavenTable
											action={false}
											className="table__main"
											headerList={[
												'BUSINESS NAME',
												'FULL NAME',
												'EMAIL',
												// 'WALLET BAL.',
												'PHONE NO',
												'DATE JOINED',
												'REGION',
												'',
											]}
										>
											{cust?.map((chi: any, idx: number) => {
												return (
													<React.Fragment key={idx}>
														<RavenTableRow
															loading={loading}
															className="table__main"
															// seven={<p>{icons.forward}</p>}
															seven={<p>{chi.region}</p>}
															onRowClick={() => {
																handleViewUser(chi);
																// console.log(chi);
															}}
															eight={
																<div
																	style={{ position: 'relative' }}
																	onClick={(e) => {
																		e.stopPropagation();
																		setShowDrop((prev) => {
																			return {
																				...prev,
																				id: idx,
																				show:
																					showDrop?.id === idx
																						? !showDrop.show
																						: true,
																			};
																		});
																		setSingleChild(chi);
																	}}
																	// ref={testingMerchantPreviewRef}
																>
																	<DropMore
																		idx={idx}
																		className={isDropped(idx) && 'show-drop'}
																		chi={chi}
																		// ref={testingMerchantPreviewRef}
																	/>
																	{icons.three_dots}
																</div>
															}
															six={<DateFragmentBox date={chi?.created_at} />}
															// four={`N56,000.00`}
															five={chi?.phone ? `+234 ${chi?.phone}` : '---'}
															one={
																<div className="account_name">
																	<span
																		style={{
																			backgroundColor:
																				generateRandomColor('#e9f5ff'),
																		}}
																	>
																		<p>
																			{returnInitial(
																				chi?.poseidon_business_name
																			)}
																		</p>
																	</span>
																	<p className="text">
																		{Number(size?.width) < 1500
																			? trimLongString(
																					capitalizeAllLetter(
																						chi?.poseidon_business_name
																					),
																					20
																			  )
																			: capitalizeAllLetter(
																					chi?.poseidon_business_name
																			  )}
																	</p>
																</div>
															}
															three={`${
																Number(size?.width) < 1500
																	? trimLongString(chi?.poseidon_email, 25)
																	: chi?.poseidon_email
															}`}
															two={trimLongString(
																`${capitalizeFirstLetter(chi?.fname) || ''} ${
																	chi?.lname || ''
																}`,
																25
															)}
														/>
													</React.Fragment>
												);
											})}
										</RavenTable>
									</div>

									<div className="atlas-mobile-table-wrap non-desktop">
										{cust?.map(
											(chi: any, idx: React.Key | null | undefined) => {
												return (
													<MobileTableCard
														title={chi?.fname + ' ' + chi?.lname}
														bottomRightText={formatDateTime(chi.created_at)}
														amount={chi?.phone}
														text={chi?.poseidon_email}
														img={undefined}
														svg={undefined}
														avatar={''}
														amountColor={'var(--primary-base-color)'}
														type={''}
														showImg={false}
														loading={loading}
														onRowClick={() => {}}
														textTwo={''}
													/>
												);
											}
										)}
									</div>
								</>
							)}
						</div>
					</div>
				</PageLayout>
				{/* {viewUser.on && (
					<BankBoxUserPreview
						// chi={viewUser.chi}
						// onAssign={(e) =>
						// 	setAssignBankbox({
						// 		on: true,
						// 		chi: e,
						// 	})
						// }
						// onEdit={(param: any) => {
						// 	setSingleChild(param);
						// 	setEditMerchantModal(true);
						// }}
						// checkFeeUpdate={editMerchantFeeModal}
						// setFeeUpdateObj={(param) => {
						// 	setSingleChildFee(param);
						// }}
						// onBack={() => {
						// 	removeUrlParam('user_email');
						// 	setViewUser({
						// 		...viewUser,
						// 		on: false,
						// 	});
						// }}
						// onChangeFee={() => {
						// 	setEditMerchantFeeModal(true);
						// }}
					/>
				)} */}
			</DashboardLayout>
			<ExportModal
				onClose={() => setShowExport(false)}
				page="bankbox_users"
				visible={showExport}
				onFinishExport={(param) => {
					// console.log(param);
					window.open(param, '_blank')?.focus();
				}}
			/>

			<RavenModal
				visble={createModal}
				onClose={() => setCreateModal(false)}
				className={'bankbox-create-modal bankbox-create-setup-modal'}
				onBtnClick={function (e?: any): void {
					throw new Error('Function not implemented.');
				}}
			>
				<CreateBankboxUser
					onComplete={() => {
						setCreateModal(false),
							toast.success('BankBox merchant created successfully');
					}}
				/>
			</RavenModal>

			<RavenModal
				visble={editMerchantModal}
				onClose={() => setEditMerchantModal(false)}
				className={'bankbox-create-modal bankbox-create-setup-modal'}
				onBtnClick={function (e?: any): void {
					throw new Error('Function not implemented.');
				}}
			>
				<UpdateBankboxUserModal
					onComplete={() => {
						setEditMerchantModal(false),
							toast.success('BankBox merchant updated successfully');
					}}
					detail={singleChild}
					visible={editMerchantModal}
				/>
			</RavenModal>
			<RavenModal
				visble={editMerchantFeeModal}
				onClose={() => setEditMerchantFeeModal(false)}
				className={'bankbox-create-modal bankbox-create-modal-update-fee'}
				onBtnClick={function (e?: any): void {
					throw new Error('Function not implemented.');
				}}
			>
				<UpdateMerchantFeeUserModal
					onComplete={() => {
						setEditMerchantFeeModal(false),
							toast.success('BankBox merchant fee updated successfully');
					}}
					detail={singleChildFee}
					visible={editMerchantFeeModal}
				/>
			</RavenModal>

			<RavenModal
				onClose={() => {
					setAssignBankbox({ ...assignBankbox, on: false });
					setUploadViaCamera(false);
					setDetails({ state: '', lga: '' });
				}}
				visble={assignBankbox.on}
				// visble={true}
				loading={loading}
				onBtnClick={handleAssign}
				btnColor="deep-green-light"
				btnLabel={!uploadViaCamera ? 'Assign Terminal' : ''}
				className={`session-expired-wrap-modal`}
				disabled={
					autoAssign &&
					(!assignFormData?.label ||
						!assignFormData?.serial_number ||
						!details?.lga ||
						!details?.state)
						? true
						: !autoAssign &&
						  (!assignFormData?.terminal_id ||
								!assignFormData?.label ||
								!details?.lga ||
								!details?.state)
						? true
						: false
				}
			>
				<div
					// onClick={() => {
					// 	console.log(user);
					// }}
					className="assign-bb-modal"
				>
					<div className="assign-bb-modal__title">
						<h6>Assign BankBox</h6>
						<p>
							{uploadViaCamera
								? `Ensure to capture the BankBox serial number`
								: `Allocate Bankbox to a user`}
						</p>
					</div>
					{/* <MyComponent />  */}
					{/* {!uploadViaCamera && (
						<div
							onClick={() => {
								setUploadViaCamera(true);
							}}
							className="camera-icon-box"
						>
							<div className="camera-icon">
								<figure className="img-box grey-bg">{cameraIcon}</figure>
							</div>
							<p>Use webcam to get bankbox serial no</p>
						</div>
					)} */}
					{uploadViaCamera ? (
						<>
							{/* <WebcamCapture /> */}
							<CameraAssignBox
								onCancel={() => {
									setUploadViaCamera(false);
								}}
								onFinish={(param: any) => {
									setUploadViaCamera(false);
									setTerminalDetails(param);
									setAssignFormData((prev) => {
										return { ...prev, serial_number: param?.actualValue };
									});
								}}
							/>
						</>
					) : (
						<>
							{' '}
							<div className="assign-bb-modal__form-body">
								{/* <RavenInputField
							type="select"
							color="deep-green-light"
							value={assignFormData?.fee_category}
							label="Fee Category"
							style={{ position: 'relative', zIndex: 10 }}
							placeholder="Select Fee Category"
							name="fee_category"
							selectOption={formatFeeCategory()}
							onChange={(e: any) => {
								setAssignFormData((prev) => {
									return { ...prev, fee_category: e };
								});
							}}
						/> */}
								<div className="serial-number-input-form-group">
									<RavenInputField
										type={`account-number`}
										maxLength={10}
										color="deep-green-light"
										value={assignFormData?.serial_number}
										label="BankBox Serial Number"
										onChange={(e: any) => {
											setAssignFormData({
												...assignFormData,
												serial_number: e.target.value,
											});
											if (terminalDetails) {
												setTerminalDetails({});
											}
										}}
										placeholder="Input last 10 digit of your BankBox serial number"
										name="serial"
										// labelSpanText={
										// 	terminalDetails?.fullValue ? (
										// 		((
										// 			<>
										// 				<div
										// 					onClick={() => {
										// 						setUploadViaCamera(true);
										// 					}}
										// 					className="camera-icon-box"
										// 				>
										// 					<div className="camera-icon">
										// 						<figure className="img-box grey-bg">
										// 							{cameraIcon}
										// 						</figure>
										// 					</div>
										// 					<p>Input via webcam</p>
										// 				</div>
										// 			</>
										// 		) as any)
										// 	) : (
										// 		<div
										// 			onClick={() => {
										// 				setUploadViaCamera(true);
										// 			}}
										// 			className="camera-icon-box"
										// 		>
										// 			<div className="camera-icon">
										// 				<figure className="img-box grey-bg">
										// 					{cameraIcon}
										// 				</figure>
										// 			</div>
										// 			<p>Input via webcam </p>
										// 		</div>
										// 	)
										// }
									/>
									<div
										onClick={() => {
											setUploadViaCamera(true);
										}}
										className="upload-toggle-box  white-black-bg border-theme"
									>
										<figure className="img-box tooltip-hover-wrap">
											{cameraUploadIcon}
											<RavenToolTip
												textColor="white-light"
												color={`deep-green-light`}
												position={`bottom-left`}
												text="Enter serial number via webcam "
											/>
										</figure>
									</div>
								</div>

								<RavenInputField
									type="text"
									value={assignFormData?.label}
									onChange={(e: any) => {
										setAssignFormData({
											...assignFormData,
											label: e.target.value,
										});
									}}
									color="deep-green-light"
									label="BankBox Label"
									placeholder="E.g Eatery one"
									name="serial"
								/>
								{/* auto generate box start */}
								{/* two input field box start */}
								<div
									style={{ position: 'relative', zIndex: '10' }}
									className="two-input-field-box"
								>
									<form autoComplete="off" style={{ width: '100%' }} action="">
										<RavenInputField
											menuPlacement={`top`}
											selectStyles={reactSelectStyle}
											color="deep-green-light"
											label="State * "
											type="select"
											placeholder="Select option"
											value={details?.state}
											onChange={(e: any) => {
												setDetails((prev) => {
													return { ...prev, state: e, lga: '' };
												});
												// if
												// dispatch(
												// 	bankboxAPI.getBankboxLgaByStates({ region: e.value })
												// );
											}}
											// disabled={user?.region ? true : false}
											selectOption={formatState(all_states)}
										/>
									</form>
									<form autoComplete="off" style={{ width: '100%' }} action="">
										{' '}
										<RavenInputField
											selectStyles={reactSelectStyle}
											menuPlacement={`top`}
											color="deep-green-light"
											label="City* "
											type="text"
											placeholder="Enter city"
											disabled={!details?.state}
											value={details?.lga}
											onChange={(e: any) => {
												setDetails((prev) => {
													return { ...prev, lga: e?.target?.value };
												});
											}}
											selectOption={formatState(all_lga)}
											// loadingSelect={true}
										/>
									</form>
								</div>
								{/* two input field box end */}
								<div
									style={{ position: 'relative', zIndex: '20' }}
									className="auto-generate-box-wrap"
								>
									<div className="togle-info-box">
										{' '}
										<RavenToggleSlide
											value={autoAssign}
											checked={autoAssign}
											onChange={() => {
												setAutoAssign(!autoAssign);
											}}
											color={`deep-green-light`}
										/>
									</div>

									<p className="text">Autogenerate Terminal ID</p>
									<div className="info-box">
										<figure className="img-box tooltip-hover-wrap">
											{infoIcon}
											<RavenToolTip
												textColor="white-light"
												color={`deep-green-light`}
												position={`top-left`}
												text="Select this option if you want us to handle the management of your Terminal ID and other background work included."
											/>
										</figure>
									</div>
								</div>
								{/* auto generate box end */}
								{/* auto assin content start */}
								{!autoAssign && (
									<div className="auto-assign-content-box">
										<RavenInputField
											type="text"
											value={assignFormData?.terminal_id}
											showError={showTerminalError}
											onChange={(e: any) => {
												if (e.target.value?.length < 9) {
													setShowTerminalError(false);
													setAssignFormData((prev) => {
														return { ...prev, terminal_id: e.target.value };
													});
												}
											}}
											color="deep-green-light"
											label="Terminal ID"
											placeholder="Enter Terminal ID"
											name="term_id"
											// maxSize={8}
										/>
										{/* select option start  */}
										<div
											className="select-option-box-wrap"
											style={{ marginTop: '2.4rem' }}
										>
											<label htmlFor="">
												Is this Terminal ID registered on NIBSS?
											</label>
											<div className={'export-modal__export-as--selector-wrap'}>
												{selectTypeList?.map((chi, idx) => {
													return (
														<div
															key={idx}
															onClick={() => {
																setSelectedType(chi);
																setAssignFormData((prev) => {
																	return {
																		...prev,
																		exist_nibbs:
																			chi === 'Yes, It is' ? true : false,
																	};
																});
															}}
															className={`selector-wrap__item ${
																selectedType === chi && 'selected'
															}`}
														>
															<figure>
																{selectedType === chi
																	? icons.radio_check
																	: icons.radio_unchecked}
															</figure>
															<p>{chi}</p>
														</div>
													);
												})}
											</div>
										</div>
										{/* select option end */}
									</div>
								)}
								{/* auto assin content end */}
							</div>
						</>
					)}
				</div>
			</RavenModal>
		</>
	);
};

export default BankBoxUsers;
