import React, { useEffect, useState } from 'react';
import SingleUserLayout from '../SingleUserLayout';
import Pagination from '../../../../../../components/common/pagination';
import { debounce } from 'lodash';
import SmartFilter from '../../../../../../components/common/smartFilter';
import BadgeComponent from '../../../../../../components/common/badge/BadgeComponent';
import DateFragmentBox from '../../../../../../components/common/DateFragmentBox';
import {
	capitalizeFirstLetter,
	capitalizeFirstWord,
	downloadCsvFile,
	formatDateHelper,
	formatMetaData,
	formatTypeFunction,
	getActionNamesByPolicyName,
	maskCardNumber,
	trimLongString,
} from '../../../../../../utils/helper/Helper';
import {
	RavenNumberFormat,
	RavenTable,
	RavenTableRow,
} from '@ravenpay/raven-bank-ui';
import TwoDetailView from '../../../../../../components/common/TwoDetailView';
import SettlementModal from './modal/settlementModal';
import TabContent from './modal/TabContent';
import ExportModal from '../../../../../../components/common/exportModal';
import { bankboxAPI } from '../../../../../../redux/bankbox';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../../../../../redux/types';
import EmptyScreen from '../../../../../../components/common/emptyScreen';
import DisputeDetailModal from '../../../disputes/DisputeDetailModal';

const Dispute = () => {
	const dispatch = useDispatch();
	const [showExport, setShowExport] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalData, setModalData] = useState<any | null>(null);
	const [noContent, setNoContent] = useState(true);
	const [currentPage, setCurrentPage] = useState(1);

	type showModalProp = {
		create: boolean;
		export: boolean;
		showdetails: boolean;
		view: boolean;
		status: boolean;
	};

	const [refreshTable, setRefreshTable] = useState(false);

	const { all_single_disputes, user_merchant, loading_diputes } = useSelector(
		(state: RootState) => state.bankbox
	);

	useEffect(() => {
		if (
			Object?.keys(user_merchant)?.length > 0 &&
			user_merchant?.user?.poseidon_email
		) {
			getSingleAllDispute();
		}
	}, [currentPage, user_merchant]);

	const getSingleAllDispute = async () => {
		dispatch(
			bankboxAPI.getSingleAllDisputes({
				per_page: 20,
				page: currentPage,
				search: user_merchant?.user?.poseidon_email,
			})
		);

		// console.log(data);

		// console.log(data);
	};

	// const handleFinish = () => {
	// 	setShowModal((prev) => {
	// 		return { ...prev, view: false, status: false };
	// 	});
	// 	setRefreshTable(!refreshTable);
	// };

	const handleFinish = () => {
		setShowModal((prev) => {
			return { ...prev, view: false, status: false };
		});
		setRefreshTable(!refreshTable);
	};

	const csvHeader = [
		{ label: 's/n', key: 'sn' },
		{ label: 'terminal ID', key: 'id' },
		{ label: 'direction', key: 'direction' },
		{ label: 'system_reference', key: 'system_reference' },
		{ label: 'business_email', key: 'business_email' },
		{ label: 'type', key: 'type' },
		{ label: 'amount', key: 'amount' },
		{ label: 'credited_amount', key: 'credited_amount' },
		{ label: 'merchant_settlement', key: 'merchant_settlement' },
		{ label: 'fee', key: 'fee' },
		{ label: 'platform_fee', key: 'platform_fee' },
		{ label: 'balance_before', key: 'balance_before' },
		{ label: 'balance_after', key: 'balance_after' },
		{ label: 'stamp_duty', key: 'stamp_duty' },
		{ label: 'terminal_label', key: 'terminal_label' },
		{ label: 'transaction_reference', key: 'tranx_reference' },
		{ label: 'transaction_details', key: 'transaction_details' },
		{ label: 'provider', key: 'provider' },
		{ label: 'date', key: 'created_at' },
		{ label: 'status', key: 'status' },
	];
	const formatExportData = (param: any) => {
		if (param?.length > 0) {
			const newData = param?.map((chi: any, idx: any) => {
				return {
					sn: idx + 1 < 10 ? `0${idx + 1}` : idx + 1,
					id: chi?.t_id || 'NIL',
					direction: chi?.direction
						? capitalizeFirstLetter(chi?.direction)
						: 'NIL',
					system_reference: chi?.reference || 'NIL',
					business_email:
						formatMetaData(chi?.pdon_meta)?.terminal?.poseidon_business_email ||
						chi?.business_email ||
						'NIL',
					type:
						chi?.type === 'pdon_card'
							? `card_payment`
							: formatTypeFunction(chi?.type) || 'NIL',
					amount: RavenNumberFormat(chi?.amount) || 'NIL',
					credited_amount: RavenNumberFormat(chi?.credited_amount),
					merchant_settlement: RavenNumberFormat(chi?.merchant_settlement),
					fee: RavenNumberFormat(chi?.fee) || 'NIL',
					platform_fee: RavenNumberFormat(chi?.platform_fee),
					balance_before: RavenNumberFormat(chi?.b_before) || 'NIL',
					balance_after: RavenNumberFormat(chi?.b_after) || 'NIL',
					stamp_duty: RavenNumberFormat(chi?.stamp_duty) || 'NIL',
					serial:
						chi?.serial ||
						formatMetaData(chi?.pdon_meta)?.terminal?.serial ||
						'NIL',
					terminal_label:
						formatMetaData(chi?.pdon_meta)?.terminal?.tid_label || 'NIL',
					tranx_reference:
						chi?.type === 'transfer_in'
							? formatMetaData(chi?.meta_data)?.session_id
							: chi?.rrn || 'NIL',
					transaction_details:
						chi?.type === 'transfer_in'
							? `Account Name: ${
									formatMetaData(chi?.meta_data)?.source?.first_name || ''
							  } ${
									formatMetaData(chi?.meta_data)?.source?.last_name || ''
							  }, Account Number: ${
									formatMetaData(chi?.meta_data)?.source?.account_number
							  } ,  Bank: ${formatMetaData(chi?.meta_data)?.source?.bank} `
							: chi?.type === 'pdon_card' && !chi?.meta_data
							? maskCardNumber(formatMetaData(chi?.pdon_meta)?.card?.card_pan)
							: `${chi?.stan}`,
					// provider:
					// chi?.provider  || "NIL",
					created_at: chi?.created_at
						? formatDateHelper(chi?.created_at)?.replace('—', '•')
						: 'NIL',
					status: String(chi?.status) === '00' ? `Success` : `Failed`,
				};
			});

			downloadCsvFile(csvHeader, newData, 'transaction_data.csv');
		}
	};

	const [singleChild, setSingleChild] = useState<any>({});
	const [showModal, setShowModal] = useState<showModalProp>({
		create: false,
		export: false,
		showdetails: false,
		view: false,
		status: false,
	});

	// Dummy data for all_fees

	const handleRowClick = (rowData: any) => {
		setModalData(rowData);
		setShowModal((prev) => ({
			...prev,
			showdetails: true, // Ensure this is set to true
		}));
	};

	return (
		<>
			{/* dispute content start */}
			<div
				style={{ marginBottom: '1.5rem' }}
				className="global-search-pagination-filter-box"
			>
				{' '}
				<SmartFilter
					// hideExport
					searchTitle="Search By Email Address"
					defaultFilterBy="status"
					onSearchChange={debounce((e) => {
						// console.log(e);
						dispatch(
							bankboxAPI.getSingleAllDisputes({
								per_page: 20,
								page: currentPage,
								// search: e,
								search: user_merchant?.user?.poseidon_email,
							})
						);
					}, 500)}
					filters={[
						{
							label: 'Status',
							filterBy: 'status',
							options: [
								{
									label: 'Pending',
									value: '0',
								},
								{
									label: 'Review',
									value: '3',
								},
								{
									label: 'Discarded',
									value: '2',
								},
								{
									label: 'Resolved',
									value: '4',
								},
							],
						},
					]}
					onExport={() => setShowExport(true)}
					page="disputes"
				/>
				<div className="pagination-wrap">
					{' '}
					<Pagination
						className="top-bar__pagination"
						currentPage={currentPage}
						itemsPerPage={all_single_disputes?.per_page ?? 0}
						totalItems={all_single_disputes?.total ?? 0}
						onPageChange={function (page: number): void {
							setCurrentPage(page);
						}}
					/>
				</div>
			</div>

			{/* dispute content end */}
			<div
				style={{ minHeight: '45rem' }}
				className="transaction__table non-mobile"
			>
				{!all_single_disputes ||
				!all_single_disputes?.data ||
				all_single_disputes?.data?.length < 1 ? (
					<EmptyScreen
						loading={loading_diputes}
						loadingText={
							loading_diputes ? 'Searching...' : 'Loading Dispute...'
						}
						title="No Dispute Found"
						subTitle="You don't have any record yet,your new bankbox transactions will be displayed here."
					/>
				) : (
					<RavenTable
						action={false}
						className="table__main"
						headerList={[
							'REFERENCE',
							'TYPE',
							'NOTE',
							'AMOUNT',
							'DATE LOGGED',
							'STATUS',
						]}
					>
						{all_single_disputes?.data?.map((chi: any, idx: number) => {
							const {
								reference,
								email,
								type,
								Channel,
								note,
								TID,
								BVN,
								RRN,
								Stan,
								SerialNo,
								Cashier,
								amount,
								Status,
							} = chi || {};
							return (
								<RavenTableRow
									onRowClick={() => {
										setSingleChild(chi);
										setShowModal((prev) => {
											return { ...prev, view: true };
										});
									}}
									loading={loading_diputes}
									// onRowClick={() => handleRowClick(chi)}
									key={idx}
									one={trimLongString(chi?.reference, 10) || '---'}
									two={type}
									three={trimLongString(note, 20) || '---'}
									four={RavenNumberFormat(amount || '----')}
									five={<DateFragmentBox date={chi?.created_at} />}
									six={
										<>
											{/* Render badges based on the Status */}
											<BadgeComponent
												className={Status === 1 ? 'success' : 'warning'}
												text={Status === 1 ? 'successful' : 'pending'}
											/>
										</>
									}
								/>
							);
						})}
					</RavenTable>
				)}
			</div>

			{/* Modal */}
			<DisputeDetailModal
				onCancel={() => {
					setShowModal((prev) => {
						return { ...prev, view: false };
					});
				}}
				visible={showModal?.view}
				detail={singleChild}
				onFinish={handleFinish}
				onStatus={() => {
					setShowModal((prev) => {
						return { ...prev, status: true, view: false };
					});
				}}
			/>

			{/* view detail modal end */}
			<ExportModal
				onClose={() => setShowExport(false)}
				visible={showExport}
				page="disputes"
				onFinishExport={(param) => {
					formatExportData(param);
					window.open(param, '_blank')?.focus();
				}}
			/>
		</>
	);
};

export default Dispute;
