import { RavenInputField, RavenModal, toast } from '@ravenpay/raven-bank-ui';
import React, { useState } from 'react';
import BadgeComponent from '../../../../../components/common/badge/BadgeComponent';
import CheckUrlFragment from '../../../../../components/common/CheckUrlCodeFragment';
import { useDarkMode } from '../../../../../hooks/useDarkMode';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../../../../redux/types';
import {
	capitalizeAllLetter,
	formatNumWithCommaNairaSymbolTwo,
	formatNumWithoutCommaNaira,
	trimLongString,
} from '../../../../../utils/helper/Helper';
import { bankboxAPI } from '../../../../../redux/bankbox';
import { StringLiteral } from 'typescript';

interface MyComponentProps {
	onClose?: () => void;
	onFinish?: () => void;
	visible?: boolean;
	el?: any;
}

const ProccessSettleMentAccountModal: React.FC<MyComponentProps> = ({
	onClose,
	visible,
	onFinish,
	el,
}) => {
	const amountIcon = (
		<svg
			className="img"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.01371 2.76406C5.52816 2.72301 6.01655 2.52071 6.40936 2.18597C7.326 1.40481 8.67419 1.40481 9.59084 2.18597C9.98364 2.52071 10.472 2.72301 10.9865 2.76406C12.187 2.85987 13.1403 3.81318 13.2361 5.01371C13.2772 5.52816 13.4795 6.01655 13.8142 6.40936C14.5954 7.326 14.5954 8.67419 13.8142 9.59084C13.4795 9.98364 13.2772 10.472 13.2361 10.9865C13.1403 12.187 12.187 13.1403 10.9865 13.2361C10.472 13.2772 9.98364 13.4795 9.59084 13.8142C8.67419 14.5954 7.326 14.5954 6.40936 13.8142C6.01655 13.4795 5.52816 13.2772 5.01371 13.2361C3.81318 13.1403 2.85987 12.187 2.76406 10.9865C2.72301 10.472 2.52071 9.98364 2.18597 9.59084C1.40481 8.67419 1.40481 7.326 2.18597 6.40936C2.52071 6.01655 2.72301 5.52816 2.76406 5.01371C2.85987 3.81318 3.81318 2.85987 5.01371 2.76406ZM10.9658 6.96578C11.2782 6.65336 11.2782 6.14683 10.9658 5.83441C10.6534 5.52199 10.1468 5.52199 9.83441 5.83441L7.2001 8.46873L6.16578 7.43441C5.85336 7.12199 5.34683 7.12199 5.03441 7.43441C4.72199 7.74683 4.72199 8.25336 5.03441 8.56578L6.63441 10.1658C6.94683 10.4782 7.45336 10.4782 7.76578 10.1658L10.9658 6.96578Z"
				fill="#EA872D"
			/>
		</svg>
	);
	const [theme, toggleTheme] = useDarkMode(localStorage.getItem('theme'));
	const dispatch = useDispatch();
	const {
		// user,
		user_merchant_settlement,
		loading_fee,
		user_merchant,
	} = useSelector((state: RootState) => state.bankbox);
	const bankIcon = (
		<svg
			className="img"
			width="36"
			height="36"
			viewBox="0 0 36 36"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M30.1895 14.9425C30.189 14.6126 30.1107 14.2874 29.961 13.9933C29.8114 13.6993 29.5945 13.4446 29.328 13.25L19.622 6.16453C19.2682 5.90658 18.8426 5.76569 18.4047 5.76157C17.9669 5.75744 17.5387 5.89029 17.18 6.14153L7.04452 13.25C6.76854 13.4435 6.54318 13.7006 6.38749 13.9995C6.2318 14.2985 6.15035 14.6305 6.15002 14.9675C6.14996 15.0782 6.1717 15.1878 6.21401 15.2901C6.25632 15.3924 6.31837 15.4853 6.39661 15.5636C6.47485 15.6419 6.56775 15.704 6.67 15.7464C6.77225 15.7887 6.88184 15.8105 6.99252 15.8105H29.322C29.436 15.8105 29.5488 15.7881 29.6541 15.7445C29.7594 15.7008 29.8551 15.6369 29.9356 15.5563C30.0162 15.4757 30.0801 15.38 30.1236 15.2747C30.1672 15.1694 30.1896 15.0565 30.1895 14.9425Z"
				fill="var(--primary-base-color)"
			/>
			<path
				d="M6.15002 27.1804V28.2804C6.15002 28.8374 6.37127 29.3715 6.7651 29.7653C7.15893 30.1592 7.69307 30.3804 8.25002 30.3804H28.0915C28.6485 30.3804 29.1826 30.1592 29.5764 29.7653C29.9703 29.3715 30.1915 28.8374 30.1915 28.2804V27.1804H6.15002Z"
				fill="var(--primary-base-color)"
			/>
			<path
				d="M18.6415 16.6289H17.6995C17.0017 16.6289 16.436 17.1946 16.436 17.8924V25.0569C16.436 25.7547 17.0017 26.3204 17.6995 26.3204H18.6415C19.3393 26.3204 19.905 25.7547 19.905 25.0569V17.8924C19.905 17.1946 19.3393 16.6289 18.6415 16.6289Z"
				fill="#0B8376"
			/>
			<path
				d="M25.5335 16.6289H24.5915C23.8937 16.6289 23.328 17.1946 23.328 17.8924V25.0569C23.328 25.7547 23.8937 26.3204 24.5915 26.3204H25.5335C26.2313 26.3204 26.797 25.7547 26.797 25.0569V17.8924C26.797 17.1946 26.2313 16.6289 25.5335 16.6289Z"
				fill="#0B8376"
			/>
			<path
				d="M11.7494 16.6289H10.8074C10.1096 16.6289 9.54395 17.1946 9.54395 17.8924V25.0569C9.54395 25.7547 10.1096 26.3204 10.8074 26.3204H11.7494C12.4473 26.3204 13.0129 25.7547 13.0129 25.0569V17.8924C13.0129 17.1946 12.4473 16.6289 11.7494 16.6289Z"
				fill="#0B8376"
			/>
		</svg>
	);

	interface formComponent {
		amount: string;
	}
	const [details, setDetails] = useState<formComponent>({
		amount: '',
	});
	const handleChange = (e: any) => {
		const { name, value } = e.target;
		const obj = { ...details, [name]: value };
		setDetails(obj);
	};

	const handleSubmit = async () => {
		if (
			Number(
				formatNumWithCommaNairaSymbolTwo(details?.amount, {
					convertToNumber: true,
				})
			) >
			Number(
				formatNumWithCommaNairaSymbolTwo(user_merchant?.user?.wallet_balance, {
					convertToNumber: true,
				})
			)
		) {
			toast.error(
				`Amount entered should not exceed ${formatNumWithCommaNairaSymbolTwo(
					user_merchant?.user?.wallet_balance
				)}`
			);
			return;
		}
		if (
			Number(
				formatNumWithCommaNairaSymbolTwo(details?.amount, {
					convertToNumber: true,
				})
			) === 0
		) {
			toast.error(
				`Amount entered should not be ${formatNumWithCommaNairaSymbolTwo(0)}`
			);
			return;
		}
		const obj = {
			amount: formatNumWithCommaNairaSymbolTwo(details?.amount, {
				convertToNumber: true,
			}),
			// settlement_account_id: String(user_merchant_settlement?.id),
			account_id: String(user_merchant_settlement?.id),
			wallet_reference: user_merchant?.user?.wallet_reference,

			merchantEmail: user_merchant?.user?.poseidon_email,
		};
		// console.log(obj);
		const data = await dispatch(
			bankboxAPI?.requestSettleMentAccountMerchant(obj)
		);
		// console.log(data);

		if (data?.payload?.status === 'success') {
			setDetails((prev) => {
				return { ...prev, amount: '' };
			});
			onFinish && onFinish();
			onClose && onClose();
		}
	};

	return (
		<RavenModal
			onClose={() => {
				onClose && onClose();
			}}
			visble={visible}
			// visble={true}
			loading={loading_fee}
			onBtnClick={handleSubmit}
			btnColor="deep-green-light"
			btnLabel={'Process Settlement'}
			className={`session-expired-wrap-modal`}
			disabled={!details?.amount}
		>
			<div
				// onClick={() => {
				// 	console.log(user_merchant);
				// 	console.log(user_merchant_settlement);
				// }}
				className="assign-bb-modal"
				style={{ paddingBottom: '5rem' }}
			>
				<div className="assign-bb-modal__title">
					<h6>{`Request Settlement `}</h6>
					<p>{`Enter amount to request settlement for ${trimLongString(
						capitalizeAllLetter(el?.poseidon_business_name),
						16
					)}.`}</p>
				</div>
				<div
					className="assign-bb-modal__form-body"
					style={{ marginBottom: '2rem' }}
				>
					<RavenInputField
						// maxLength={10}
						labelSpanText={
							(
								<>
									<BadgeComponent text=" " className="pending">
										{' '}
										<div className="amount-show-box">
											<div className="img-box">{amountIcon}</div>
											<p
												style={{
													color: theme === 'light' ? '#020202' : '#ffffff',
												}}
												className="text-amount- black-white-color"
											>
												{' '}
												BAL:{' '}
												<span>
													{' '}
													{formatNumWithCommaNairaSymbolTwo(
														user_merchant?.user?.wallet_balance
													)}
												</span>
											</p>
										</div>
									</BadgeComponent>
								</>
							) as any
						}
						color="deep-green-light"
						// value={}
						label="Enter Amount"
						onChange={handleChange}
						placeholder="Enter amount"
						name="amount"
						thousandFormat
						type={`number`}
						numberPrefix={`₦`}
					/>
					<div className="settle-ment-account-wrap ">
						<p className="label">Settlement Account</p>
						<div className="settle-ment-account-box grey-bg">
							<div className="icon-wrap-box">
								{' '}
								<CheckUrlFragment
									className="icon-wrap card-bg border-theme"
									url={`https://businessapi.getraventest.com/static/media/banks/${user_merchant_settlement?.bank_code}.png `}
								>
									<figure className="img-box">{bankIcon}</figure>
								</CheckUrlFragment>
							</div>
							<div className="name-account-num-box">
								<p className="name grey-white-color">
									{trimLongString(user_merchant_settlement?.account_name, 35)}
								</p>
								<p
									style={{ color: theme === 'light' ? '#020202' : '#ffffff' }}
									className={`acc-num ${`black-white-color`}`}
								>
									{user_merchant_settlement?.account_number}
								</p>
							</div>
						</div>
					</div>
				</div>
				{/* <BadgeComponent text="Ayeni kehinde" className="Pending" /> */}
			</div>
		</RavenModal>
	);
};

export default ProccessSettleMentAccountModal;
